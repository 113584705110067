import React, { useEffect } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import StageForm from "../StagePage/form";
import { stageActions } from '../../../actions';
import { useParams } from 'react-router-dom';
import { change } from "redux-form";

function NewStage(props) {
  const dispatch = useDispatch();
  let { workspace_id } = useParams();
  const { handleCloseNewForm, fromKanban, can_access_approval_request } = props;

  useEffect(() => {
    //dispatch(stageActions.newStage(workspace_id));
  }, []);

  const stage_error = useSelector(state => state.stage);

  const showResults = (values) => {
    dispatch(stageActions.createStage(values, workspace_id, handleCloseNewForm, fromKanban));
  }
  return (
    <StageForm onSubmit={showResults}
      request_approval={can_access_approval_request}
      goupId={workspace_id}
      newForm="newForm"
      stage_error={stage_error && stage_error}
      handleCloseForm={handleCloseNewForm}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.stage.loading
  };
};

export default connect(mapStateToProps, { stageActions })(NewStage);
