import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { organizationActions } from '../../../actions';
import { organizationValidation } from '../../../shared/form-validations';
import { textInput } from '../../../shared/form-elements'
import { reduxForm, Field, change } from "redux-form";
import { Modal, Button } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import CheckIcon from "../../../images/icons/white/check.svg";

function OrganizationForm(props) {
  const {
          handleSubmit,
          error,
          initialData,
          newForm,
          handleCloseForm,
          invalid
        } = props
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <Modal
      show={true}
      onHide={handleCloseForm}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          { newForm ? "Add Organization" : "Edit Organization" }

          {newForm}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {error && error.map((data, index) => (
            <div className="error_message" key={index}>{data}</div>
          ))}
          <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <Field name="name" type="text" component={textInput} placeholder="Organization Name" autoFocus/>
              </div>

              <div className="form-buttons justify-content-start">
                <Button variant="primary" type="submit" disabled={ invalid }>
                  <img src={CheckIcon} className="button-icon" />
                  { newForm ? 'Add' : 'Save' }
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

OrganizationForm =  reduxForm({
  form: 'organization_form',
  validate: organizationValidation,
  enableReinitialize: true
})(OrganizationForm);

const mapStateToProps = (state) => {
  const initialData = state.organization.editOrganization;
  return {
    loading: state.organization.loading,
    error: state.organization.error,
    initialValues: {
      name: initialData?.name
    }
  };
};

export default connect(mapStateToProps,{})(OrganizationForm);
