import React, { useEffect } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import WorkSpaceForm from "../WorkSpacePage/form";
import { change } from "redux-form";
import { work_spaceActions } from '../../../actions';
import { adminBaseURL, buildFormDataFromObject } from '../../../utils/commonUtils';
import { LoadSpinner } from '../../../components';

function WorkSpaceNew(props) {
  const dispatch = useDispatch();
  const { initialData, handleCloseNewForm } = props
  const work_space_error = useSelector(state => state.work_space);
  const workspaceBaseUrl = adminBaseURL("workspaces")

  const showResults = (values) => {
    let formData = new FormData();
    buildFormDataFromObject(formData, values, "workspace");

    dispatch(work_spaceActions.createWorkSpace(formData, handleCloseNewForm));
  }

  useEffect(() => {
    dispatch(change("work_spaceform", "name", ''));
    dispatch(change("work_spaceform", "duplicate_from_id", ''));
    dispatch(change("work_spaceform", "logo", ''));
  }, []);

  if (props.loading) {
    return <LoadSpinner />
  }

  return (
    <WorkSpaceForm onSubmit={showResults}
      initialData= {initialData}
      newForm="newForm"
      work_space_error={work_space_error && work_space_error}
      handleCloseForm= {handleCloseNewForm}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.work_space.loading
  };
};

export default connect(mapStateToProps, { work_spaceActions })(WorkSpaceNew);
