import { alertActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePOSTRequest, makeDELETERequest, makePUTRequest } from '../utils/Axios';
import { reset } from "redux-form";

export const projectActions = {
  createProject,
  getProject,
  editProject,
  destroyProject,
  updateProject
};

export function createProject(reqparams, closeModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PROJECT_LOADING });
      makePOSTRequest('/api/v1/projects', reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(reset('projectform'));
          closeModal();
          dispatch(projectActions.getProject(true));
          dispatch(alertActions.success(userConstants.PROJECT_REQUEST));
          dispatch({ type: userConstants.PROJECT_REQUEST, payload: response})
        }else {
          dispatch({ type: userConstants.PROJECT_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.PROJECT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateProject(reqparams, id, closeModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PROJECT_LOADING });
      makePUTRequest(`/api/v1/projects/${id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(reset('projectform'));
          closeModal();
          dispatch(projectActions.getProject(true));
          dispatch(alertActions.success(userConstants.UPDATE_PROJECT));
          dispatch({
            type: userConstants.UPDATE_PROJECT,
            payload: response
          });
        }else{
          dispatch({ type: userConstants.PROJECT_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.PROJECT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getProject(project_load) {
  return function (dispatch) {
    try{
      if(project_load != true){
        dispatch({ type: userConstants.PAGE_LOADING });
      }
      makeGETRequest('/api/v1/projects')
      .then(response => {
        dispatch({
          type: userConstants.GET_PROJECT,
          payload: response
        })
      })
    }catch(e){
      dispatch( {
        type: userConstants.PROJECT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editProject(id, openModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PROJECT_LOADING });
      makeGETRequest(`/api/v1/projects/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.EDIT_PROJECT,
            payload: response
          })

          openModal({ show: true, id: id });
        }
        else{
          history.push("/");
          dispatch({ type: userConstants.PROJECT_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })

    }catch(e){
      dispatch( {
        type: userConstants.PROJECT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyProject(id) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/projects/${id}`)
      .then(response => {
        dispatch({
          type: userConstants.DELETE_PROJECT,
          payload: response
        })
      })
      dispatch(alertActions.success(userConstants.DELETE_PROJECT));
    }catch(e){
      dispatch( {
        type: userConstants.PROJECT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
