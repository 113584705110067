import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { workspace_userActions } from '../../../actions';
import { history } from '../../../helpers';
import { Button, Table } from 'react-bootstrap';

function AcceptInvitation(props) {
  const { pendingInvitation } = props
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const { workspace_id } = props.match.params;

  useEffect(() => {
    dispatch(workspace_userActions.getpendingInvitation())
  }, []);

  const handleAccept = (workspace_id, id, status) => {
    dispatch(workspace_userActions.acceptInvitation(workspace_id, id, status));
  }

  const handleAll = (status) => {
   dispatch(workspace_userActions.acceptAllInvitation(status));
  }

  const accept_status = "Accept"
  const reject_status = "Reject"

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between mb-1">
        <h5>Workspaces Accept Invitation </h5>
        { pendingInvitation && pendingInvitation.length > 0 ?
          <div className="d-flex">
            <Button className="px-2" variant="link" onClick={() => handleAll(accept_status)}>Accept All</Button>
            <Button className="px-2" variant="link" onClick={() => handleAll(reject_status)}>Reject All</Button>
          </div>
          : null
        }
      </div>
      <Table className="table table-bordered" responsive>
        <thead>
          <tr>
            <th style={{width: '5%'}} className="text-center">S.No</th>
            <th>Workspace Name</th>
            <th style={{width: '10%'}} className="text-center"></th>
            <th style={{width: '10%'}} className="text-center"></th>
          </tr>
        </thead>
        <tbody>
         {  pendingInvitation && pendingInvitation.length > 0
            ?
              pendingInvitation.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{data.workspace_name}</td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <Button className="px-2 primary" onClick={() => handleAccept(data.workspace_id, data.id, accept_status)}>Accept</Button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <Button className="px-2 btn btn-secondary" onClick={() => handleAccept(data.workspace_id, data.id, reject_status)}>Reject</Button>
                    </div>
                  </td>
                </tr>
              ))
            :
             <tr>
                <td colSpan="4">
                  <div className="d-flex justify-content-center py-4">
                    No data yet
                  </div>
                </td>
             </tr>
          }
        </tbody>
      </Table>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.workspaceUser.loading,
    pendingInvitation: state.workspaceUser.pendingInvitation
  };
};

export default connect(mapStateToProps, { workspace_userActions })(AcceptInvitation);
