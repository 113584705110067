import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {connect,  useDispatch, useSelector } from 'react-redux';
import { multi_userActions, workspace_userActions, organizationActions } from '../../../actions';
import { Button, Table } from 'react-bootstrap';
import { Popup } from '../../../components';
import { popUpConstants } from '../../../constants';
import { useParams } from "react-router-dom";

function UsersList(props) {
  const dispatch = useDispatch();
  const { organization_id } = useParams();
  const {
    usersList,
    ignore_limit,
    remaining_users,
    current_organization
  } = props;

  useEffect(() => {
    dispatch(multi_userActions.getAllUsrs());
  }, []);

  useEffect(() => {
    if(current_organization && !current_organization.personal){
      dispatch(organizationActions.getAllowedUserDetails(current_organization.id));
    }
  }, [current_organization]);

  /*
    Popup Modal
  */
  const [popup, setPopUp] = useState({
    show: false,
    id: null,
  });


  const handleClose = () => {
    setPopUp({show: false, id: null});
  }

  const handleShow = (id) => setPopUp({show: true, id});

  const handleDelete = () => {
    dispatch(multi_userActions.destroyUser(popup.id));
    handleClose();
  }

  const resentInviteUser = (id) => {
    dispatch(workspace_userActions.resentNotification(id));
 }

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <div  className="container-fluid">
      <div className="col-sm-12">
        <ol className="breadcrumb float-sm-right">
          <li className="breadcrumb-item">Organization</li>
          <li className="breadcrumb-item active">Users</li>
        </ol>
      </div>
      <div className="col-lg-12">
        <h2 className="mb-2">Users</h2>

        <div className="mb-1">
          <Link to={`/organizations/${organization_id}/admin/users/new`}>
              <Button variant="primary" size="sm">Add User</Button>
          </Link>
        </div>
        {
          current_organization && !current_organization.personal && !ignore_limit &&
          <div className="mb-2">
            {` Seats Available: ${remaining_users || 0} of ${current_organization.allowed_user_count}`}
          </div>
        }
        <Table className="table table-bordered table-light" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Roles</th>
              <th>Added</th>
              <th>Last Login</th>
              <th style={{width: '15%'}} className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {usersList && usersList.length > 0
              ?
                usersList.map((ulist, index) => (
                  <tr key={index}>
                    <td>{ulist.full_name}</td>
                    <td>{ulist.email}</td>
                    <td>
                      {
                        current_organization?.user_id === ulist.user_id ?
                          <div>
                            Owner
                          </div>
                        :
                          ulist.organization_role &&  ulist.organization_role.map((urole, index) => {
                            return(
                              <div key={index}>
                                {urole ? `${urole.role_type} - ${urole.name}` : null}
                              </div>
                            )
                          })
                      }

                      {ulist.workspace_role &&  ulist.workspace_role.map((urole, index) => {
                        return(
                          <div key={index}>
                            {urole ? `${urole.workspace_name} - ${urole.role_name}` : null}
                          </div>
                        )
                      })}
                    </td>
                    <td>{ulist.added_at}</td>
                    <td>{ulist.last_login_time}</td>
                    <td>
                      <div className="table-actions">
                         <Link to={`/organizations/${organization_id}/admin/users/${ulist.id}/edit`}  className="px-2 btn btn-secondary btn-sm">Edit</Link>
                         {
                           current_organization?.user_id !== ulist.user_id &&
                             <Button
                              onClick={() => handleShow(ulist.id)}
                              className="px-2"
                              size="sm"
                              variant="danger"
                            >
                              Delete
                            </Button>
                         }
                      </div>
                      {
                        ulist.status === "Invited" &&
                        <Button variant="primary" className="my-2 mt-3" size="sm" onClick={() => resentInviteUser(ulist.id)}>Resend Invitation</Button>
                      }
                    </td>
                  </tr>
                ))
              :
              <tr>
                <td colSpan="6">
                  <div className="d-flex justify-content-center mt-5">There are no users yet.</div>
                </td>
              </tr>
            }
          </tbody>
        </Table>
      </div>

      {popup.show &&
        <Popup
          display_text={ popUpConstants.INVITED_USER_DELETE }
          delete_btn_text={'Yes'}
          close_btn_text={'Cancel'}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: (state.multiUser.loading && state.workspaceUser.loading),
    current_organization: state.organization.current_organization,
    ignore_limit: state.organization.ignore_limit,
    remaining_users: state.organization.remaining_users,
    usersList: state.multiUser.allUsers
  };
};

export default connect(mapStateToProps, { multi_userActions })(UsersList);
