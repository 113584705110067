import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { validation } from '../../../shared/form-validations';
import { textInput } from '../../../shared/form-elements'
import { reduxForm, Field, change } from "redux-form";
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton'


function ProjectForm(props) {
  const { handleSubmit, project_error, newForm, handleCloseForm} = props
  const dispatch = useDispatch();

  useEffect(() => {
    if(newForm === "newForm") {
      dispatch(change("projectform", "name", ''))
    }
  }, []);

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <Modal
      show={true}
      onHide={handleCloseForm}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          { newForm == "newForm" ? "Add Label" : "Edit Label" }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {project_error.error && project_error.error.map((data, index) => (
            <div className="error_message" key={index}>{data}</div>
          ))}
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <Field name="name" type="text" component={textInput} label="Label Name" autoFocus/>
            </div>
            <div className="mb-2">
              <label className="d-block">
                Color Hex
              </label>
              <b
                className="d-inline-block"
                style={{width: '5%'}}
              >
                #
              </b>
              <Field name="color_hex" className="d-inline-block" style={{width: '95%'}} type="text" component={textInput}/>
            </div>
            <div className="form-buttons">
              <Button onClick={handleCloseForm} variant="default" style={{marginRight: 20}}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">Submit</Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

ProjectForm =  reduxForm({
  form: 'projectform',
  validate: validation,
  enableReinitialize: true
})(ProjectForm);

const mapStateToProps = (state) => {
  return {
    loading: state.project.loading,
    error: state.project.error,
    editProject: state.project.editProject ,
    initialValues: {
      name: state.project.editProject && state.project.editProject.data.name,
      color_hex: state.project.editProject && state.project.editProject.data.color_hex,
    }
  };
};

export default connect(mapStateToProps, { })(ProjectForm);
