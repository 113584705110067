import { useState, useRef } from 'react';
import { AlertTimeIcon } from '../';
import { daysBetweenDates, reminderTagClassName } from '../../utils';
import moment from 'moment';

function card_text(day_index, completed) {
  if(completed) return 'Completed'

  switch(day_index) {
    case 7:
    case 6:
    case 5:
    case 4:
    case 3:
      return `Due in ${day_index} days`;
    case 2:
      return 'Near Due';
    case 1:
      return 'Due Tomorrow';
    case 0:
      return 'Due Today'
    default:
      return 'Past Due'
  }
}

function tagSizeClass(size) {
  if(size === 'lg'){
    return 'tag-large'
  }

  return ''
}

function CardTag(props) {
  const {
    completed,
    reminder_in_day,
    due_date,
    useVisibility,
    className,
    size
  } = props;

  const dateTimeToday = new Date();
  const dateToday = new Date();
  const dueDate = new Date(due_date);
  const past_due = due_date ? dateTimeToday > new Date(due_date) :  false;

  dueDate?.setHours(0,0,0,0);
  dateToday?.setHours(0,0,0,0);

  const diff_in_day = (past_due || due_date === undefined) ? 0 : daysBetweenDates(dateToday, dueDate);

  const isHidden = () => {
      if(completed){
        return false;
      }
      else if((completed === false || completed === undefined ) && (reminder_in_day === undefined || due_date === undefined || due_date === null || reminder_in_day === null || reminder_in_day === 0 )){
        return true;
      } else if(diff_in_day > reminder_in_day) {
        return true;
      } else {
        return false;
      }
  }

  const style = () => {
    if(useVisibility === undefined) return {};

    return { visibility: isHidden() ? 'hidden' : 'visible'}
  }

  if(isHidden() && useVisibility === undefined) return null;

  return (
    <div className={`tag ${className} ${tagSizeClass(size)} ${reminderTagClassName(past_due ? -1 : diff_in_day, completed)}`}
         style={style()}>
         <div className="d-flex align-items-center h-100">
           <AlertTimeIcon />
           <span>{card_text(past_due ? -1 : diff_in_day, completed)}</span>
         </div>
    </div>
  );
}

export { CardTag };
