function InfoIcon(props) {
  const { className, height, width } = props

  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "24"}
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.75 17C13.0333 17 13.2708 16.9042 13.4625 16.7125C13.6542 16.5208 13.75 16.2833 13.75 16V12C13.75 11.7167 13.6542 11.4792 13.4625 11.2875C13.2708 11.0958 13.0333 11 12.75 11C12.4667 11 12.2292 11.0958 12.0375 11.2875C11.8458 11.4792 11.75 11.7167 11.75 12V16C11.75 16.2833 11.8458 16.5208 12.0375 16.7125C12.2292 16.9042 12.4667 17 12.75 17ZM12.75 9C13.0333 9 13.2708 8.90417 13.4625 8.7125C13.6542 8.52083 13.75 8.28333 13.75 8C13.75 7.71667 13.6542 7.47917 13.4625 7.2875C13.2708 7.09583 13.0333 7 12.75 7C12.4667 7 12.2292 7.09583 12.0375 7.2875C11.8458 7.47917 11.75 7.71667 11.75 8C11.75 8.28333 11.8458 8.52083 12.0375 8.7125C12.2292 8.90417 12.4667 9 12.75 9ZM12.75 22C11.3667 22 10.0667 21.7375 8.85 21.2125C7.63333 20.6875 6.575 19.975 5.675 19.075C4.775 18.175 4.0625 17.1167 3.5375 15.9C3.0125 14.6833 2.75 13.3833 2.75 12C2.75 10.6167 3.0125 9.31667 3.5375 8.1C4.0625 6.88333 4.775 5.825 5.675 4.925C6.575 4.025 7.63333 3.3125 8.85 2.7875C10.0667 2.2625 11.3667 2 12.75 2C14.1333 2 15.4333 2.2625 16.65 2.7875C17.8667 3.3125 18.925 4.025 19.825 4.925C20.725 5.825 21.4375 6.88333 21.9625 8.1C22.4875 9.31667 22.75 10.6167 22.75 12C22.75 13.3833 22.4875 14.6833 21.9625 15.9C21.4375 17.1167 20.725 18.175 19.825 19.075C18.925 19.975 17.8667 20.6875 16.65 21.2125C15.4333 21.7375 14.1333 22 12.75 22Z"/>
    </svg>
  );
}

export { InfoIcon };
