import { userConstants } from '../constants';
const initialState = { loading: false, error: '' };

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case userConstants.COMMON_INITIAL_DATA_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.DASHBOARD_INITIAL_DATA:
      return {
        ...state,
        dashboardData: action.payload.data.data,
        loading: false,
      }

    case userConstants.COMMON_INITIAL_DATA: {
      return {
        ...state,
        getCommonData: action.payload.data,
        isRoleType: action.payload.data.is_content_contributor,
        can_access_live_chat: action.payload.data.can_access_live_chat,
        can_view_live_chat: action.payload.data.can_view_live_chat,
        can_hide_live_chat: action.payload.data.can_hide_live_chat,
        loading: false,
      }
    }

    case userConstants.WORKSPACES_DATA: {
      return {
        ...state,
        workspacesData: action.payload,
        loading: false,
      }
    }

    case userConstants.CURRENT_USER_DATA: {
      return {
        ...state,
        currentUser: action.payload,
        loading: false
      }
    }

    case userConstants.CURRENT_USER_PERMISSION: {
      return {
        ...state,
        organization_roles: action.payload.organization_roles,
        workspace_roles: action.payload.workspace_roles,
        loading: false
      }
    }

    case userConstants.PENDING_NOTIFICATION_COUNT: {
      return {
        ...state,
        notificationCount: action.payload,
        loading: false,
      }
    }

    default:
      return state
  }
}
