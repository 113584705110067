import React, { useEffect, useState } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import { assigned_userActions } from '../../../actions';
import { Button, Table, Row, Col, Modal, CloseButton } from 'react-bootstrap';
import { Popup, BasicSpinner, LoadSpinner } from '../../../components';
import { FieldArray, reduxForm } from 'redux-form';
import AssignedUserForm from '../AssignedUserPage/form';
import { useMediaQuery } from 'react-responsive';
import { mediaQueryConstants } from '../../../constants';

function AssignedUserNew(props) {
  const dispatch = useDispatch();
  const {
    workspace_id,
    status,
    closeDropDownPopup,
    loading,
    workspace,
    content_group,
    participant_user_options,
    error,
    assigned_users,
    assigned_users_object,
    content_group_id,
    fromList,
    content_popup_notification,
    dropdownRef,
    isActive,
    showMobilePopup
  } = props;
  const userUnCheckValue = useSelector(state => state.assigned_user.unchecked_value);
  const [uncheckValue, setUnCheckValue] = useState([]);
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` });

  useEffect(() => {
    if (userUnCheckValue){
      let new_array = uncheckValue.filter((u) => u.user_id !== userUnCheckValue.user_id)

      setUnCheckValue([...new_array, userUnCheckValue])
    }
  }, [userUnCheckValue]);

  const closePopup = (status) => {
    closeDropDownPopup()
  }

  const showResults = (values) => {
    const checkValue = values.assigned_users.map(data => {return {user_id: data, _destroy: false}});
    const all_user_ids = checkValue.map(data => { return data.user_id });
    const updated_user = uncheckValue.filter((u) => !all_user_ids.includes(u.user_id));
    const result = [...updated_user, ...checkValue]

    dispatch(assigned_userActions.createAssignedUser({assigned_users: result}, workspace_id, content_group_id, fromList, closePopup, status, content_popup_notification));
  }

  return (
    <>
    {
      isLargeScreen &&
        <Modal
          show={showMobilePopup}
          onHide={closeDropDownPopup}
          keyboard={true}
          centered
          className="assigned-users-popup__container assigned-users-popup__modal"
        >
          <Modal.Header closeButton>
            <Modal.Title
              className="font-weight-bold"
            >
              Participants
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AssignedUserForm
              onSubmit={showResults}
              workspace_id={workspace_id}
              status={status}
              content_group_id={content_group_id}
              participant_user_options={participant_user_options}
              assigned_users={assigned_users}
              assigned_user_error = {error}
              assigned_users_object={assigned_users_object}
              closeDropDownPopup ={closeDropDownPopup}
            />
          </Modal.Body>
        </Modal>

    }

    <nav
      ref={dropdownRef}
      className={`assigned_user_list ${isLargeScreen && 'd-none'}  ${isActive ? "active" : "inactive"}`}
      id="new_assigned_user_list"
    >
      <div className="assigned-users-popup__container">
        <button type="button" className="btn-close" aria-label="Close" onClick={() => closeDropDownPopup()}/>

        <h4 className="mb-2 text-left font-weight-bold assigned-users-popup__title">Participants</h4>

        <AssignedUserForm
          onSubmit={showResults}
          workspace_id={workspace_id}
          status={status}
          content_group_id={content_group_id}
          participant_user_options={participant_user_options}
          assigned_users={assigned_users}
          assigned_user_error = {error}
          assigned_users_object={assigned_users_object}
          closeDropDownPopup ={closeDropDownPopup}
        />
      </div>

    </nav>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.assigned_user.assigned_user_loading,
    workspace: state.assigned_user.workspace,
    content_group: state.assigned_user.content_group,
    participant_user_options: state.assigned_user.participant_user_options,
    assigned_users: state.assigned_user.assigned_users,
    assigned_users_object: state.assigned_user.assigned_users_object,
    error: state.assigned_user.error
  };
};

export default connect(mapStateToProps, { assigned_userActions })(AssignedUserNew);
