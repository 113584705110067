function SearchIcon(props) {
  const { className, height, width, color } = props

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 14 14.1"
      height={height ? height : "24"}
      width={width ? width : "24"}
      fill={color ? color : "#727580"}
      className={className}
    >
      <path class="st0" d="M1.51,8.76c-1.01-1.01-1.51-2.22-1.51-3.63S.5,2.52,1.51,1.51,3.73,0,5.14,0s2.62.5,3.63,1.51,1.51,2.22,1.51,3.63c0,.7-.13,1.15-.3,1.61-.2.5-.4.91-.71,1.41l4.43,4.43c.2.2.3.4.3.6s0,.4-.3.6c-.2.2-.4.3-.6.3s-.4,0-.6-.3l-4.43-4.43c-.4.3-.91.5-1.41.71-1.15.25-3.27.2-5.14-1.31ZM5.14,8.56c.91,0,1.71-.3,2.42-1.01s1.01-1.51,1.01-2.42-.3-1.71-1.01-2.42-1.51-1.01-2.42-1.01-1.71.3-2.42,1.01-1.01,1.51-1.01,2.42.3,1.71,1.01,2.42,1.51,1.01,2.42,1.01Z"/>
    </svg>
  );
}

export { SearchIcon };
