import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {connect,  useDispatch, useSelector } from 'react-redux';
import { stageActions } from '../../../actions';
import { Button, Table } from 'react-bootstrap';
import { Popup, LoadSpinner } from '../../../components';
import StagePageNew  from '../StagePage/new';
import StagePageEdit  from '../StagePage/edit';

function StageList(props) {

  const dispatch = useDispatch();
  const { stages } = props
  let { workspace_id } = useParams();
  const workspace = useSelector(state => state.stage.currentWorkspace);

  useEffect(() => {
    dispatch(stageActions.getStage(workspace_id));
  }, []);

  const deleteStage = (id) => {
    console.log(id)
    dispatch(stageActions.destroyStage(workspace_id, id));
  }

  /*
    Popup Modal
  */
  const [popup, setPopUp] = useState({
    show: false,
    id: null,
  });;

  const handleClose = () => {
    setPopUp({show: false, id: null});
  }

  const handleShow = (id) => setPopUp({show: true, id});

  const handleDelete = () => {
    deleteStage(popup.id);
    handleClose();
  }

  /*
    Popup New Modal
  */

  const [popupNewForm, setPopUpNewForm] = useState({
    show: false
  });;

  const handleCloseNewForm = () => {
    setPopUpNewForm({ show: false });
  }

  /*
    Popup Edit Form Modal

  */

  const [popupEditForm, setPopUpEditForm] = useState({
    show: false,
    id: null
  });;

  const handleCloseEditForm = () => {
    setPopUpEditForm({ show: false, id: null });
  }

  const handleShowEditForm = (id) => {
    dispatch(stageActions.editStage(workspace_id, id, setPopUpEditForm));
  }

  //if (props.loading) {
  //  return <LoadSpinner />
  //}

  return (
    <div  className="container-fluid">
      {(props.loading || props.stage_loading) && <LoadSpinner />}
      <div className="col-lg-12">
        <h2 className="mb-3">Board Stages</h2>

        <div className="mb-3">
          <Button variant="primary" size="sm" onClick={() => setPopUpNewForm({ show: true })}>Add Board Stage</Button>
        </div>
        <Table className="table table-bordered table-light" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Visible to Client</th>
              <th>Track Client Approval</th>
              <th style={{width: '15%'}} className="text-center"></th>
            </tr>
          </thead>
          <tbody>
           {  stages && stages.length > 0
              ?
                stages.map((data, index) => (
                  <tr key={index}>
                    <td>{data.name}</td>
                    <td>{data.visible_to_client ? "Yes" : "No"}</td>
                    <td>{data.require_approval_from_client ? "Yes" : "No"}</td>
                    <td>
                      <div className="table-actions">
                        <Button onClick={() => handleShowEditForm(data.id) } className="px-2" size="sm" variant="secondary">Edit</Button>
                        <Button onClick={() => handleShow(data.id) } className="px-2" size="sm" variant="danger">Delete</Button>
                      </div>
                    </td>
                  </tr>
                ))
              :
               <tr>
                  <td colSpan="4">
                    <div className="d-flex justify-content-center py-4">
                      No Queue
                    </div>
                  </td>
               </tr>
            }
          </tbody>
        </Table>
      </div>

      {popupEditForm.show &&
          <StagePageEdit
            handleCloseEditForm={handleCloseEditForm}
            stage_id={popupEditForm.id}
          />
      }

      {
        popupNewForm.show &&
        <StagePageNew
          handleCloseNewForm={handleCloseNewForm}
        />
      }

      {popup.show &&
        <Popup
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.stage.loading,
    currentWorkspace: state.stage.currentWorkspace,
    stages: state.stage.stages
  };
};

export default connect(mapStateToProps, { stageActions })(StageList);
