import React from "react";

export const textArea = ({
  input,
  type,
  placeholder,
  label,
  id,
  meta: { touched, error },
  ...rest
}) => {
  return (
    <div>
      { label ?  <label className="mb-1" style={{ fontSize: "14px" }}> {label}</label> : null}
      <textarea
        {...input}
        type={type}
        placeholder={placeholder}
        id={id}
        className="form-control lg"
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export default textArea;
