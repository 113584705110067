import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function user_profile(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.USERPROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        error:action.payload
      };
    }

    case userConstants.EDIT_USERPROFILE:{
      return {
        ...state,
        editUserProfile: action.payload.data,
        loading: false
      }
    }

    case userConstants.DELETE_USERPROFILE:{
      return {
        ...state,
        loading: false
      }
    }

    case userConstants.GET_ACTIVITY: {
      return {
        ...state,
        activities: action.payload.data.activities,
        activity_workspace: action.payload.data.workspace,
        activity_loading: false
      }
    }

    case userConstants.ACTIVITY_LOADING: {
      return {
        ...state,
        activity_loading: true
      }
    }

    case userConstants.ACTIVITY_FAILURE: {
      return {
        ...state,
        activity_loading: false
      }
    }

    case userConstants.USER_SUMMARY: {
      return {
        ...state,
        currentUser: action.payload.data.invited_user,
        noCurrentUser: action.payload.data.invited_user === null,
        availability:action.payload.data.availability,
        user_summary_loading: false
      };
    }

    case userConstants.USER_SUMMARY_LOADING:{
      return {
        ...state,
        user_summary_loading: true
      };
    }

    case userConstants.USER_SUMMARY_RESET: {
      return {
        ...state,
        currentUser: null,
        noCurrentUser: false,
        availability: false,
        user_summary_loading: true
      };
    }

    case userConstants.USER_PROFILE: {
      return {
        ...state,
        currentUserProfile: action.payload.data.invited_user,
        profileAvailability:action.payload.data.availability,
        user_profile_loading: false
      };
    }

  default:
    return state
  }
}
