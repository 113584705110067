import React, { useEffect } from 'react';
import {connect,  useDispatch, useSelector  } from 'react-redux';
import { FieldArray, reduxForm, reset } from 'redux-form';
import { inviteUser } from '../CollaboratorPage/shared';
import { workspace_userActions, multi_userActions } from '../../../actions';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { workspaceUserValidaton } from '../../../shared/form-validations';
import SendSubmissionIcon from "../../../images/icons/white/submit.svg";

function SingleInviteForm(props) {
  const { handleSubmit, initialData, initialize, invalid } = props
  const {
    workspaceId,
    getCommonData,
    usersList,
    resentInviteUser,
  } = props
  const dispatch = useDispatch();
  const workspace = useSelector(state => state.workspaceUser.currentWorkSpace);
  const workspace_user_error = useSelector(state => state.workspaceUser);

  const setInitialCommonData = getCommonData
  const inviteUserList = resentInviteUser

  useEffect(() => {
    const loading = false;

    dispatch(workspace_userActions.getWorkspaceData(workspaceId));
    dispatch(multi_userActions.getAllUsrs(workspaceId, loading));
    initialize({workspace_users: [{ workspace_id: workspaceId}], invited_users: ['']});
  }, []);

  const onSubmit = (values) => {
    let successCallback = () => {
      dispatch(reset('userform'));
    }

    dispatch(workspace_userActions.createWorkspaceUser(workspaceId, values, successCallback));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between" style={{ columnGap: "1rem" }}>
        <FieldArray
          name='invited_users'
          component={inviteUser}
          change={props.change}
          roles={setInitialCommonData && setInitialCommonData}
        />

        <Button variant="primary" type="submit" className="d-flex align-items-center" disabled={ invalid } style={{ minWidth: "100px" }}>
          <img src={SendSubmissionIcon} height="14" className="button-icon" />
          Invite
        </Button>
      </div>
    </form>
  );
}

SingleInviteForm =  reduxForm({
  form: 'userform',
  validate: workspaceUserValidaton
})(SingleInviteForm);

const mapStateToProps = (state) => {
  return {
    loading: state.workspaceUser.loading,
    initialData: state.workspaceUser.workspaceInitialData,
    currentWorkSpace: state.work_space.currentWorkSpace,
    getCommonData: state.dashboard.getCommonData,
    usersList: state.multiUser.allUsers,
    remaining_users: state.organization.remaining_users,
    ignore_limit: state.organization.ignore_limit,
    error: state.workspaceUser.error,
    current_organization: state.organization.current_organization
  };
};

export default connect(mapStateToProps,{ workspace_userActions })(SingleInviteForm);
