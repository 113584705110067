import { alertActions, contentActions, work_spaceActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makeFormDataPOSTRequest, makeDELETERequest, makeFormDataPUTRequest } from '../utils/Axios';
import { adminBaseURL } from '../utils/commonUtils';
import { reset } from "redux-form";

export const assigned_userActions = {
  getAssignedUser,
  newAssignedUser,
  createAssignedUser,
  destroyAssignedUser,
  editAssignedUser,
  updateAssignedUser,
  uncheckdValue
};

export function getAssignedUser(workspace_id, content_group_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/assigned_users`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_ASSIGNED_USER,
            payload: response
          })
        }else {
          history.push('/');
          dispatch({ type: userConstants.ASSIGNED_USER_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ASSIGNED_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function newAssignedUser(workspace_id, content_group_id, activeDropDownPopup, status) {
  return function (dispatch) {
    try{
      // dispatch({ type: userConstants.ASSIGNED_USER_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/assigned_users/new`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.NEW_ASSIGNED_USER,
            payload: response
          })
          activeDropDownPopup(content_group_id, status)
        }else {
          history.push('/');
          dispatch({ type: userConstants.ASSIGNED_USER_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ASSIGNED_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editAssignedUser(workspace_id, content_group_id, activeDropDownPopup) {
  return function (dispatch) {
    try{
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/assigned_users/new`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.NEW_ASSIGNED_USER,
            payload: response
          })
          activeDropDownPopup(content_group_id)
        }else {
          dispatch({ type: userConstants.ASSIGNED_USER_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ASSIGNED_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function uncheckdValue(user_id) {
  return dispatch => {
    dispatch({
      type: userConstants.UNCHECKED_VALUE,
      payload: user_id
    })
  }
}

export function updateAssignedUser(user_id, workspace_id, content_group_id, activeDropDownPopup) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/update_assigned_user`, user_id )
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.ASSIGNED_USER_REQUEST, payload: response});
          dispatch(work_spaceActions.showWorkSpace(workspace_id, true));
          dispatch(alertActions.success(response.data.message));

        }else {
          dispatch({ type: userConstants.ASSIGNED_USER_FAILURE, payload: response.data.error});
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ASSIGNED_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function createAssignedUser(reqparams, workspace_id, content_group_id, fromList, closeDropDownPopup, status, content_popup_notification) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      // dispatch({ type: userConstants.PAGE_LOADING });
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/assigned_users`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.ASSIGNED_USER_REQUEST, payload: response});
          closeDropDownPopup();
          dispatch(reset('assignedUserForm'));
          if(!fromList){
            dispatch(contentActions.getContent(workspace_id, content_group_id, ""));
            const content_id = "first_record"
            dispatch(contentActions.showContent(workspace_id, content_group_id, content_id, ""));
          }

          dispatch(work_spaceActions.showWorkSpace(workspace_id, true, content_popup_notification));

          dispatch({
            type: userConstants.UPDATE_CONTENTGROUP,
            payload: response
          })
          dispatch({ type: userConstants.UNCHECKED_VALUE, payload: ''})
          dispatch(alertActions.success(userConstants.ASSIGNED_USER_REQUEST));

        }else {
          dispatch({ type: userConstants.ASSIGNED_USER_FAILURE, payload: response.data.error});
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ASSIGNED_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyAssignedUser(workspace_id, content_group_id, id) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/assigned_users/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.DELETE_ASSIGNED_USER,
            payload: response
          })
          dispatch(alertActions.success(userConstants.DELETE_ASSIGNED_USER));

        }else {
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ASSIGNED_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
