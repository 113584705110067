import React, { useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Modal } from 'react-bootstrap';

const FileUpload = ({
  input: { name, value: omitValue, label, onChange, onBlur, ...inputProps },
  meta: omitMeta,touched, error,
  ...props
}) => {
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null)
  const [crop, setCrop] = useState({ aspect: 16 / 16 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [disabled, setDisabled] = useState(null);
  const selectImage = (file) => {
    setFileName(file.name)
    setSrc(URL.createObjectURL(file));
    onChange(file)
  };

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    if (canvas.width > 0 ) {
      const base64Image = canvas.toDataURL('image/jpeg');
      setOutput(base64Image);
      const reader = new FileReader()
      canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          dataURLtoFile(reader.result, filename)
        }
      })
    }else {
      console.log('please select your position')
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
    onChange(croppedImage)
  }

  return (
    <div className="file-upload-div">
      <input
        type="file"
        name={name}
        accept="image/*"
        accept='.jpg, .png, .jpeg'
        className="form-control"
        onChange={(e) => {
          selectImage(e.target.files[0]);
        }}
      />
     
      {src && (
        <div className="crop-load-div">
          <ReactCrop 
            src={src} 
            onImageLoaded={setImage}
            crop={crop} 
            onChange={setCrop} 
            circularCrop={true}
          />
           <Button variant="primary" onClick={cropImageNow} style={{display: 'table'}}>Crop Image</Button>
        </div>
      )}
      {output && <img src={output} className="crop-img"/>}
    </div>
  )
}

export default FileUpload