function AlertTimeIcon(props) {
  const { className, color, height, width } = props
  const default_width = width ? width : "14"
  const default_height = height ? height : "14"

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 14 12.91"
      className={className}
      width={ default_width }
      height={ default_height }
      fill={color ? color : "none" }
    >
      <path class="st0" d="M7.64,7.01v-2.65c0-.18-.06-.33-.19-.46-.12-.13-.28-.19-.46-.19s-.34.06-.46.19c-.13.12-.19.28-.19.46v2.92c0,.1.02.19.06.27.04.09.09.16.16.23l1.66,1.14c.13.13.29.2.46.19.18,0,.33-.08.46-.21.13-.13.2-.29.2-.46s-.07-.33-.2-.46c0,0-1.51-.96-1.51-.96ZM6.99,12.91c-.82,0-1.58-.16-2.3-.47-.72-.31-1.34-.73-1.87-1.26-.53-.53-.95-1.16-1.26-1.87-.31-.72-.47-1.48-.47-2.3s.16-1.58.47-2.3c.31-.72.73-1.34,1.26-1.87.53-.53,1.16-.95,1.87-1.26s1.48-.47,2.3-.47,1.58.16,2.3.47,1.34.73,1.87,1.26.95,1.16,1.26,1.87.47,1.48.47,2.3-.16,1.58-.47,2.3-.73,1.34-1.26,1.87c-.53.53-1.16.95-1.87,1.26-.72.31-1.48.47-2.3.47ZM.2,3.23C.06,3.1,0,2.94,0,2.77c0-.17.07-.33.2-.46L2.31.2c.13-.13.29-.2.46-.2s.33.07.46.2c.13.13.2.29.2.46s-.07.33-.2.46L1.11,3.25c-.13.13-.29.2-.45.19-.17,0-.32-.08-.45-.21ZM13.8,3.23c-.13.13-.29.2-.46.2s-.33-.07-.46-.2l-2.11-2.11c-.13-.13-.2-.29-.2-.46,0-.17.07-.33.2-.46.13-.13.29-.2.46-.2.17,0,.33.07.46.2l2.11,2.12c.13.13.2.28.2.45s-.07.32-.2.45ZM6.99,11.49c1.25,0,2.31-.43,3.18-1.3.87-.87,1.31-1.93,1.31-3.17,0-1.25-.43-2.31-1.3-3.18-.87-.87-1.93-1.31-3.17-1.31-1.25,0-2.31.43-3.18,1.3-.87.87-1.31,1.93-1.31,3.17s.43,2.31,1.3,3.18c.87.87,1.93,1.31,3.17,1.31Z"/>
    </svg>

  );
}

export { AlertTimeIcon };
