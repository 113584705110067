import { alertActions } from './';
import { userConstants, notificationConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePUTRequest, makeFormDataPUTRequest, makeDELETERequest, makeFormDataPOSTRequest } from '../utils/Axios';
import { reset, setState } from "redux-form";
import { adminBaseURL } from '../utils/commonUtils';
import { dashboard_userActions, content_groupActions } from './';

export const work_spaceActions = {
  getList,
  createWorkSpace,
  getWorkSpace,
  editWorkSpace,
  destroyWorkSpace,
  updateWorkSpace,
  showWorkSpace,
  workspaceInitial,
  updateSortContentGroupWorkSpace,
  setWorkspaceView,
  inlineEditWorkspace,
  getActivities,
  refreshShowWorkSpace,
  removeWorkspace
};

export function getList() {
  return dispatch => {
    try {
      dispatch({ type: userConstants.WORKSPACE_DROPDOWN_LOADING });
      makeGETRequest('/api/v1/workspaces/list')
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.WORKSPACE_LIST_DATA, payload: response })
        }
      })
    } catch {

    }
  }
}

export function createWorkSpace(reqparams, closeModal) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.WORKSPACE_LOADING });
      makeFormDataPOSTRequest(`/api/v1/workspaces`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          closeModal();
          const { workspace } = response.data
          dispatch({ type: userConstants.WORKSPACE_REQUEST, payload: response})
          history.push(`/organizations/${workspace.organization_id}${workspaceBaseUrl}/${workspace.id}/list`);
          dispatch(reset('work_spaceform'));
          dispatch(alertActions.success(userConstants.WORKSPACE_REQUEST));
          dispatch(dashboard_userActions.CommonInitialData())
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateWorkSpace(reqparams, id, successCallback) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.WORKSPACE_LOADING });
      makeFormDataPUTRequest(`/api/v1/workspaces/${id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          successCallback();
          dispatch({
            type: userConstants.UPDATE_WORKSPACE,
            payload: response
          });
          //dispatch(getWorkSpace(true));
          dispatch(showWorkSpace(id));
          dispatch(alertActions.success(userConstants.UPDATE_WORKSPACE));
        }else{
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateSortContentGroupWorkSpace(reqparams, id, content_drag_id, source_stage_id, destination_stage_id, type) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.WORKSPACE_LOADING });
      makeFormDataPUTRequest(`/api/v1/workspaces/${id}/sort?content_drag_id=${content_drag_id}&source_stage_id=${source_stage_id}&destination_stage_id=${destination_stage_id}&type=${type}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(work_spaceActions.showWorkSpace(id, true));
        }else{
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
      if (content_drag_id){
        dispatch(content_groupActions.replaceContentImageLazeLoading(id, content_drag_id));
      }
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function getWorkSpace(workspace_load) {
  return function (dispatch) {
    try{
      if(workspace_load != true){
        dispatch({ type: userConstants.PAGE_LOADING });
      }
      makeGETRequest(`/api/v1/workspaces`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_WORKSPACE,
            payload: response
          })
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function showWorkSpace(id, load, content_popup_notification) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  return function (dispatch) {
    try{
      if (!load){
        dispatch({ type: userConstants.PAGE_LOADING });
      }
      makeGETRequest(`/api/v1/workspaces/${id}`, null)
      .then(response => {
        if(response.data.status === "ok"){
          if (content_popup_notification) {
            dispatch({ type: notificationConstants.WORKSPACE_SHOW_FROM_NOTIFICATION, payload: response})
          } else {
            dispatch({ type: userConstants.WORKSPACE_SHOW, payload: response})
            dispatch({ type: userConstants.SET_CURRENT_ORGANIZATION, payload: response})
          }

          dispatch({type: userConstants.MESSAGE_COUNT, payload: response.data.message_count})
        }else {
          history.push('/');
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function refreshShowWorkSpace(id, successCallback) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  return function (dispatch) {
    try{
      makeGETRequest(`/api/v1/workspaces/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.WORKSPACE_SHOW, payload: response})

          dispatch({type: userConstants.MESSAGE_COUNT, payload: response.data.message_count})

          successCallback();
        }else {
          history.push(workspaceBaseUrl);
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editWorkSpace(id, openModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.WORKSPACE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.EDIT_WORKSPACE,
            payload: response
          })
          openModal({ show: true, id: id });
        }else {
          history.push('/');
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
          dispatch(dashboard_userActions.CommonInitialData());
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyWorkSpace(id) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${id}`)
      .then(response => {
        if(response.data.status === "ok") {
          dispatch({
            type: userConstants.DELETE_WORKSPACE,
            payload: response
          });
          history.push('/');
          dispatch(alertActions.success(userConstants.DELETE_WORKSPACE));
          dispatch(dashboard_userActions.CommonInitialData());
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function workspaceInitial(openModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.WORKSPACE_LOADING });
      makeGETRequest(`/api/v1/workspace_initial`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.WORKSPACE_INITIAL_DATA,
            payload: response
          });
          openModal({ show: true });
        }else {
          history.push('/');
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function setWorkspaceView(workspace_id, view, noReload) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const org_id = localStorage.getItem('org_id');

  return function(dispatch) {
    dispatch({ type: userConstants.WORKSPACE_VIEW_CHANGE, payload: { workspaceView: view } });
    if (noReload === undefined || noReload === false || noReload === null)  {
      history.push({
        pathname: "/organizations/"+ org_id + "" + workspaceBaseUrl + "/" + workspace_id + "/" + view,
        search: window.location.search
      })
    }
  };
}

export function removeWorkspace() {
  return function(dispatch) {
    dispatch({ type: userConstants.WORKSPACE_REMOVE });
  };
}

export function inlineEditWorkspace(handleOnBlur, reqparams, workspace_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.WORKSPACE_LOADING });
      makePUTRequest(`/api/v1/workspaces/${workspace_id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_WORKSPACE,
            payload: response
          });
          //dispatch(getWorkSpace(true));
          //dispatch(showWorkSpace(workspace_id));
          dispatch(alertActions.success(userConstants.UPDATE_WORKSPACE));
          handleOnBlur()
        }else{
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error})
          handleOnBlur()
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getActivities( user_id, page, perPage, callback ) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.ACTIVITY_LOADING });
      makeGETRequest(`/api/v1/user_profiles/${user_id}/activities?page=${page}&per_page=${perPage}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_ACTIVITY,
            payload: response
          })

          callback(response.data)
        }else {
          dispatch({type: userConstants.ACTIVITY_FAILURE, payload: response.error});
          dispatch(alertActions.error(response.data.message));
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ACTIVITY_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
