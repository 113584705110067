function ThumbsUpIcon(props) {
  const { className, color, height, width } = props

  return (
    <svg
      id={"Layer_1"}
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      viewBox={"0 0 14 14"}
      height={ height ? height : "14" }
      width={ width ? width : "14" }
      fill={color}
      className={className}
    >
      <path class="st0" d="M5.29,10.41h2.89c.16,0,.31-.04.45-.12.14-.08.24-.2.31-.34l1.4-2.77c.02-.06.04-.12.06-.19.02-.07.03-.13.03-.2v-.21c0-.24-.08-.45-.25-.61-.16-.16-.37-.25-.61-.25h-2.14l.35-1.75c.03-.12.02-.25-.01-.36-.04-.12-.1-.22-.2-.32l-.57-.57-2.39,3.2c-.06.08-.1.17-.13.25-.03.08-.04.17-.04.27v3.13c0,.23.08.44.25.6.17.17.37.25.61.25ZM7,14c-.97,0-1.87-.18-2.72-.55-.85-.36-1.59-.87-2.23-1.5-.64-.64-1.14-1.38-1.5-2.23-.36-.85-.55-1.76-.55-2.73s.18-1.88.55-2.72c.36-.84.87-1.59,1.5-2.22.64-.64,1.38-1.14,2.23-1.5.85-.36,1.76-.55,2.73-.55s1.88.18,2.72.55c.84.36,1.59.87,2.22,1.5.64.64,1.14,1.38,1.5,2.23s.55,1.75.55,2.72-.18,1.87-.55,2.72c-.36.85-.87,1.59-1.5,2.23-.64.64-1.38,1.14-2.23,1.5-.85.36-1.75.55-2.72.55Z"/>
    </svg>
  );
}

export { ThumbsUpIcon };
