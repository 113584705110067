import React from "react";
import { Field } from "redux-form";
import { checkBox } from '../../../../shared/form-elements'


class renderRoles extends React.Component {
  render() {
     const { roles_details } = this.props
    return (
      <div>
        <Field name="user_roles" component={checkBox} options={roles_details} /> 
      </div>
    );
  }
}

export default renderRoles;