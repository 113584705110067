function KanbanNavIcon(props) {
  const { className, height, width } = props

  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "24"}
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.25 21C4.7 21 4.22917 20.8042 3.8375 20.4125C3.44583 20.0208 3.25 19.55 3.25 19V5C3.25 4.45 3.44583 3.97917 3.8375 3.5875C4.22917 3.19583 4.7 3 5.25 3H19.25C19.8 3 20.2708 3.19583 20.6625 3.5875C21.0542 3.97917 21.25 4.45 21.25 5V19C21.25 19.55 21.0542 20.0208 20.6625 20.4125C20.2708 20.8042 19.8 21 19.25 21H5.25ZM8.25 7C7.96667 7 7.72917 7.09583 7.5375 7.2875C7.34583 7.47917 7.25 7.71667 7.25 8V16C7.25 16.2833 7.34583 16.5208 7.5375 16.7125C7.72917 16.9042 7.96667 17 8.25 17C8.53333 17 8.77083 16.9042 8.9625 16.7125C9.15417 16.5208 9.25 16.2833 9.25 16V8C9.25 7.71667 9.15417 7.47917 8.9625 7.2875C8.77083 7.09583 8.53333 7 8.25 7ZM16.25 7C15.9667 7 15.7292 7.09583 15.5375 7.2875C15.3458 7.47917 15.25 7.71667 15.25 8V14C15.25 14.2833 15.3458 14.5208 15.5375 14.7125C15.7292 14.9042 15.9667 15 16.25 15C16.5333 15 16.7708 14.9042 16.9625 14.7125C17.1542 14.5208 17.25 14.2833 17.25 14V8C17.25 7.71667 17.1542 7.47917 16.9625 7.2875C16.7708 7.09583 16.5333 7 16.25 7ZM12.25 7C11.9667 7 11.7292 7.09583 11.5375 7.2875C11.3458 7.47917 11.25 7.71667 11.25 8V11C11.25 11.2833 11.3458 11.5208 11.5375 11.7125C11.7292 11.9042 11.9667 12 12.25 12C12.5333 12 12.7708 11.9042 12.9625 11.7125C13.1542 11.5208 13.25 11.2833 13.25 11V8C13.25 7.71667 13.1542 7.47917 12.9625 7.2875C12.7708 7.09583 12.5333 7 12.25 7Z"/>
    </svg>

  );
}

export { KanbanNavIcon };
