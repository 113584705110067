import React, { useEffect, useState, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navbar, Nav, Button } from 'react-bootstrap';
import {
  ListNavIcon,
  KanbanNavIcon,
  CalendarNavIcon,
  NotificationNavIcon
} from '../../../../components';
import { work_spaceActions } from "../../../../actions";
import { TopbarContext } from '../../../../contexts/TopbarContext';
import { WorkspaceBarContext } from '../../../../contexts/WorkspaceBarContext';

function WorkspaceNavigation(props) {
  const {
    workspace_view,
    currentWorkspace,
    unread_notificationCount
  } = props
  const dispatch = useDispatch();
  const {
    showNotificationsDropdown,
    setShowNotificationsDropdown
  } = useContext(TopbarContext);
  const {
    workspaceNavMobileRef
  } = useContext(WorkspaceBarContext);

  const isActive = (tab) => {
    return (workspace_view === tab) && !showNotificationsDropdown
  }

  const handleClickTab = (view) => {
    setShowNotificationsDropdown(false);

    let noReload = false
    dispatch(work_spaceActions.setWorkspaceView(currentWorkspace?.id, view, noReload));
  }

  if(currentWorkspace === undefined || currentWorkspace === null){
    return null;
  }

  return (
    <Navbar
      className="mobile_navigation p-0"
      sticky="bottom"
      ref={workspaceNavMobileRef}
    >
      <div
        className="mobile_navigation-button_group w-100 h-100 d-flex align-item-center"
      >
        <Button
          size={"sm"}
          variant={"transparent"}
          onClick={() => handleClickTab("list")}
          className={`text-truncate ${isActive("list") ? "active" : ""}`}
        >
          <ListNavIcon
            className="m-auto d-block"
            height={"20"}
            width={"20"}
          />
          List
        </Button>
        <Button
          size={"sm"}
          variant={"transparent"}
          onClick={() => handleClickTab("board")}
          className={`text-truncate ${isActive("board") ? "active" : ""}`}
        >
          <KanbanNavIcon
            className="m-auto d-block"
            height={"20"}
            width={"20"}
          />
          Board
        </Button>
        <Button
          size={"sm"}
          variant={"transparent"}
          onClick={() => handleClickTab("calendar")}
          className={`text-truncate ${isActive("calendar") ? "active" : ""}`}
        >
          <CalendarNavIcon
            className="m-auto d-block"
            height={"20"}
            width={"20"}
          />
          Calendar
        </Button>
        <Button
          size={"sm"}
          variant={"transparent"}
          onClick={() => setShowNotificationsDropdown(true)}
          className={`text-truncate ${showNotificationsDropdown ? "active" : ""}`}
        >
          <div
            className="position-relative m-auto d-block notification_icon-container"
          >
            <NotificationNavIcon
              className=""
              height={"20"}
              width={"20"}
            />
            {unread_notificationCount && unread_notificationCount !== 0 ? <span className="unread_badge" /  > : null}
          </div>


          Notifications
        </Button>
      </div>
    </Navbar>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.organization.loading,
    workspace_view: state.work_space.workspaceView,
    currentWorkspace: state.work_space.currentWorkSpace,
    unread_notificationCount: state.notification.unread_notificationCount,
  };
};

export default connect(mapStateToProps, { })(WorkspaceNavigation);
