import React from 'react'

const FileUpload = ({
  input: { name, value: omitValue, label, onChange, onBlur, ...inputProps },
  meta: omitMeta,touched, error,
  ...props
}) => {
  const [file, setFile] = React.useState(null)
  const handleChange = (e) => {
    onChange(e.target.files[0])
    setFile(e.target.files[0])
  }
  
  return (
    <div>
      <input
        onChange={handleChange}
        onBlur={handleChange}
        name={name}
        accept='.jpg, .png, .jpeg'
        type="file"
        {...props.input}
        {...props}
        className="form-control"
      />
      {file &&<div className={omitValue ? "edit_profile py-2" : "py-2"}>
        <img
          src={file ? URL.createObjectURL(file) : omitValue && omitValue}
          alt={file ? file.name : null}
          className={ file ? 'upload_preview' : null}
        />
      </div>}
    </div>
  )
}

export default FileUpload