function EyeIcon(props) {
  const { className, color, height, width } = props

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 14 9.69"
      width={width ? width : "18"}
      height={height ? height : "18"}
      fill={color}
      className={className}
    >
      <path class="st0" d="M7,7.6c.77,0,1.42-.27,1.95-.8s.8-1.19.8-1.95-.27-1.42-.8-1.95-1.19-.8-1.95-.8-1.42.27-1.95.8-.8,1.19-.8,1.95.27,1.42.8,1.95,1.19.8,1.95.8ZM7,6.33c-.41,0-.77-.14-1.06-.43-.29-.29-.44-.64-.44-1.06s.14-.77.43-1.06c.29-.29.64-.44,1.06-.44s.77.14,1.06.43c.29.29.44.64.44,1.06s-.14.77-.43,1.06c-.29.29-.64.44-1.06.44ZM7,9.69c-1.44,0-2.76-.38-3.97-1.15-1.21-.76-2.16-1.78-2.87-3.04-.06-.1-.11-.21-.13-.32-.02-.11-.04-.22-.04-.34s.01-.23.04-.34c.02-.11.07-.22.13-.32C.87,2.93,1.83,1.91,3.03,1.15c1.21-.76,2.53-1.15,3.97-1.15s2.76.38,3.97,1.15c1.21.76,2.16,1.78,2.87,3.04.06.1.11.21.13.32.02.11.04.22.04.34s-.01.23-.04.34c-.02.11-.07.22-.13.32-.7,1.26-1.66,2.27-2.87,3.04-1.21.76-2.53,1.15-3.97,1.15ZM7,8.38c1.22,0,2.35-.32,3.39-.95s1.83-1.5,2.39-2.59c-.56-1.09-1.35-1.95-2.39-2.59s-2.16-.95-3.39-.95-2.35.32-3.39.95c-1.04.64-1.83,1.5-2.39,2.59.56,1.09,1.35,1.95,2.39,2.59,1.04.64,2.16.95,3.39.95Z"/>
    </svg>

  );
}

export { EyeIcon };
