import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { textInput } from '../../../shared/form-elements'
import { reduxForm, Field, reset, change, submit } from "redux-form";
import { validation } from "../../../shared/form-validations";
import textAreaAutoSize from "../../../shared/form-elements/textAreaAutoSize";
import Close from '../../../images/close.png'
import PaperPlane from '../../../images/paper-plane.png'
import FileUpload from '../../../images/fileupload.png'
import { Button, Modal } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch, useSelector } from 'react-redux';

function ChatComposer(props) {
  const hiddenFileInput = React.useRef(null);
  const handleClick = event => { hiddenFileInput.current.click()}
  const can_access_live_chat = useSelector(state => state.work_space.can_access_live_chat);
  const [inputValue, setInputValue] = useState("");
  const [inputFile, setInputFile] = useState();
  const [error, setError] = useState();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 991px)' });

  const handleInput = (event) => {
    if (inputValue != "") {
      props.submitted({content: inputValue, message_type: 'text'})

      setInputValue("")
      setError("")
    } else {
      setError("Please write a message")
    }

    if (!!event) {
      event.preventDefault();
    }
  }

  const handleKeyUp = (event) => {
    if (event.key == 'Escape') {
      props.closePopup();
    } else if (event.key == 'Enter') {
      event.preventDefault()
      event.stopPropagation()

      handleInput(null);

      event.target.value = '';
    } else {
      setInputValue(event.target.value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault()
      event.stopPropagation()

      event.target.form.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
    } else {
      setInputValue(event.target.value)
    }
  };

  const setUploadFile = e => {
    props.submitted({file: e.target.files[0], message_type: 'file'});
  }

  return (
    <div className={error ? "form-errors" : null}>
      {can_access_live_chat && (
        <div className="chat-composer">
          <form onSubmit={(event) => handleInput(event)}>
            <div onClick={handleClick} className="cursor-pointer">
              <img src={FileUpload} alt="paper-plane"/>
              <input type="file" onChange={setUploadFile} ref={hiddenFileInput} style={{display: 'none'}}/>
            </div>
            <Field
              name="message"
              type="text"
              value={ inputValue }
              input={{ style: { minHeight: '38px', height: '38px', maxHeight: '78px' }}}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              autoFocus="autoFocus"
              component={ textAreaAutoSize }
            />

           { isMobileScreen &&
              <div onClick={(event) => handleInput(null)} className="cursor-pointer">
                <img src={PaperPlane} alt="paper-plane"/>
              </div>
            }
          </form>
        </div>
      )}
    </div>
  );
}

ChatComposer = reduxForm({
  form: "workspaceChatForm",
  validate: validation,
  enableReinitialize: true
})(ChatComposer);

export default ChatComposer
