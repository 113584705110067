import React, { useState, useContext } from 'react';
import { daysBetweenDates, daysBeforeToday } from '../';

function card_text(day_index) {
  switch(day_index) {
    case 7:
    case 6:
    case 5:
    case 4:
    case 3:
    case 2:
      return ` is <b>due in ${day_index} days</b>.`;
    case 1:
      return ` is <b>due in ${day_index} day</b>.`;
    case 0:
      return ' is <b>due today</b>.'
    default:
      return ' is past due.'
  }
}

export function renderDueNotificationSummary(notification) {
  const diff_in_day = daysBeforeToday(notification?.due_date);

  return notification?.summary + card_text(diff_in_day)
}
