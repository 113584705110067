import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { dashboard_userActions } from '../../../actions/dashboard.actions.js';
import { Table } from 'react-bootstrap';

function UserPermissionsList(props) {
  const { organization_roles, workspace_roles } = props
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboard_userActions.getCurrentUserPermisson())
  }, []);

  return (
    <div className="container layout__container-wrapper">
      <div className="col-sm-12 pt-3">
        <ol className="breadcrumb float-sm-right">
          <li className="breadcrumb-item"><Link to="/permission">Permissions</Link></li>
        </ol>
      </div>

      <h2 className="h4 mb-3">Organization Role</h2>
      <Table className="table permission-table table-bordered table-light" responsive>
        <thead>
          <th>Role name</th>
          <th>Description</th>
        </thead>
        {<tbody>
         { organization_roles && organization_roles.length > 0
            ?
              organization_roles
              .map((data, index) => (
                <tr key={data.id}>
                  <td> {data.name}</td>
                  <td>{data.description}</td>
                </tr>
              ))
            :
             <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-center py-4">
                    There are no permission
                  </div>
                </td>
             </tr>
          }
        </tbody>}
      </Table>

      <h2 className="h4 mb-3">Workspace Roles</h2>
      <Table className="table permission-table table-bordered table-light" responsive>
        <thead>
          <th>Role name</th>
          <th>Description</th>
        </thead>
        <tbody>
         { workspace_roles && workspace_roles.length > 0
            ?
              workspace_roles
              .map((data, index) => (
                <tr key={data.id}>
                  <td> {data.role_id}</td>
                  <td>{data.description}</td>
                </tr>
              ))
            :
             <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-center py-4">
                    There are no permission
                  </div>
                </td>
             </tr>
          }
        </tbody>
      </Table>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.workspaceUser.loading,
    organization_roles: state.dashboard.organization_roles,
    workspace_roles: state.dashboard.workspace_roles
  };
};

export default connect(mapStateToProps, { dashboard_userActions })(UserPermissionsList);
