import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'react-bootstrap';
import { assigned_userActions } from '../../../actions';
import { useParams } from 'react-router-dom';
import { Popup, LoadSpinner } from '../../../components';
import { adminBaseURL } from '../../../utils/commonUtils';

function AssignedUserList(props) {
  let { workspace_id, content_group_id } = useParams();
  const {loading, content_group, workspace, assigned_users, can_assign_user} = props;
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces")

  useEffect(() => {
    dispatch(assigned_userActions.getAssignedUser(workspace_id, content_group_id));
  }, []);


  /*
    Delete Modal
  */
  const [popup, setPopUp] = useState({
    show: false,
    id: null,
  });

  const handleShow = (id) => setPopUp({ show: true, id });

  const handleClose = () => {
    setPopUp({show: false, id: null});
  }

  const handleDelete = () => {
    dispatch(assigned_userActions.destroyAssignedUser(workspace_id, content_group_id, popup.id));
    handleClose();
  }

  if(loading){
    return <LoadSpinner />
  }

  return (
    <div className="container-fluid">

      <h2 className="mb-3">Assigned Users</h2>

      <div>
        <Table className="table table-bordered" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              {can_assign_user && <th></th>}
            </tr>
          </thead>
          <tbody>

         { assigned_users && assigned_users.length > 0
            ?
             assigned_users.map((data, index) => (
               <tr key={index}>
                 <td>{data.full_name}</td>
                 <td>{data.email}</td>
                { can_assign_user &&
                 <td>
                   <Button variant="danger" onClick={() => handleShow(data.id)} size="sm">Delete</Button>
                 </td>
                }
               </tr>
              ))
            :
            <tr>
              <td colSpan="6"><div className="text-center py-3"> There are no assigned users.</div></td>
            </tr>
          }
          </tbody>
        </Table>
      </div>

      {popup.show &&
        <Popup
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      }

    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.assigned_user.loading,
    assigned_users: state.assigned_user.assigned_users,
    content_group: state.assigned_user.content_group,
    workspace: state.assigned_user.workspace,
    can_assign_user: state.assigned_user.can_assign_user
  };
};

export default connect(mapStateToProps,{ assigned_userActions })(AssignedUserList);
