import { Row, Col, Image } from 'react-bootstrap';
import React, { useState, useEffect, useContext } from 'react';
import { commentAttachmentActions } from '../actions';
import { useDispatch } from 'react-redux';
import { ChatContext } from '../contexts/ChatContext'

function ImageAttachment(props) {
  const dispatch = useDispatch();
  const {
    filename,
    image_url,
    thumbnail,
    workspace_id,
    content_group_id,
    comment_id,
    attachment_id,
    setSelected,
    own,
    viewable,
    destroyable,
    CustomHandleDelete,
    dynamic_icon,
    className
  } = props

  const [thumbnail_cached, setThumbnailCached] = useState(false);
  const [cached_thumbnail, setCachedThumbnail] = useState();

  useEffect(() => {
    if (thumbnail_cached) {
      return null;
    }

    if (thumbnail) {
      setCachedThumbnail(thumbnail)
      setThumbnailCached(true);
    }
  }, [thumbnail]);

  const { activeTab } = useContext(ChatContext);

  const handleDelete = (e) => {
    if (CustomHandleDelete) {
      CustomHandleDelete();
    } else {
      e.stopPropagation();
      setSelected(false);

      if (own && destroyable){
        dispatch(
          commentAttachmentActions.destroyCommentAttachment(
            workspace_id,
            content_group_id,
            comment_id,
            attachment_id,
            activeTab
          )
        );
      }
    }
  }

  const handleMouseOver = () => {
    if (setSelected) setSelected(true);

  };

  const handleMouseOut = () => {
    if (setSelected) setSelected(false);
  };

  const viewImage = () => {
    if (viewable) {
      if (image_url) window.open(image_url, '_blank');
    }
  };

  return (
    <div className={`attachment-container mr-2 image ${dynamic_icon ? 'dynamic' : null} ${className}`}>
      <div
        className="attachment-thumbnail text-center"
        onClick={() => viewImage()}
        onMouseOver={handleMouseOver}
        onMouseOut ={handleMouseOut}
      >
        <Image src={cached_thumbnail} loading='lazy' fluid/>
        {
          own && destroyable &&
          <div className="close-button" onClick={(e) => handleDelete(e)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        }
      </div>
    </div>
  );
}

export { ImageAttachment };
