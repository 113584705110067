import React, { useEffect, useState, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navbar, Button } from "react-bootstrap";
import {
  InfoIcon,
  ContentIcon,
  ApprovalCommentIcon,
  TeamCommentIcon
} from '../../../../components';

function MobileContenGroupNav(props) {
  const {
    can_access_show_content_details,
    can_access_team_comment,
    can_access_show_team_comment,
    activeTab,
    setActiveTab,
    tab,
    setTab
  } = props
  const dispatch = useDispatch();

  const show_team_tab = can_access_team_comment || can_access_show_team_comment;

  const onClickComment = (comment_type) =>{
    setTab('comments')
    setActiveTab(comment_type)
  }

  return (
    <Navbar
      className="mobile_navigation fixed-bottom p-0"
      sticky="bottom"
    >
      <div
        className="mobile_navigation-button_group w-100 h-100 d-flex align-item-center"
      >
        {
          can_access_show_content_details &&
          <Button
            size={"sm"}
            variant={"transparent"}
            className={ tab === 'info' ? 'active' : '' }
            onClick={() => setTab('info')}
          >
            <InfoIcon
              className="m-auto d-block"
              height={"20"}
              width={"20"}
            />
            Info
          </Button>
        }
        <Button
          size={"sm"}
          variant={"transparent"}
          className={ tab === 'contents' ? 'active' : '' }
          onClick={() => setTab('contents')}
        >
          <ContentIcon
            className="m-auto d-block"
            height={"20"}
            width={"20"}
          />
          Content
        </Button>

        {
          show_team_tab &&
          <Button
            size={"sm"}
            variant={"transparent"}
            className={ tab === 'comments' && activeTab === 'internal_notes' ? 'active' : '' }
            onClick={() => onClickComment('internal_notes')}
          >
            <TeamCommentIcon
              className="m-auto d-block"
              height={"20"}
              width={"20"}
            />
            Team
          </Button>
        }
        <Button
          size={"sm"}
          variant={"transparent"}
          className={ tab === 'comments' && activeTab === 'discussion' ? 'active' : '' }
          onClick={() => onClickComment('discussion')}
        >
          <ApprovalCommentIcon
            className="m-auto d-block"
            height={"20"}
            width={"20"}
          />
          Approval
        </Button>
      </div>
    </Navbar>
  );
}

const mapStateToProps = (state) => {
  return {
    can_access_show_content_details: state.content.can_access_show_content_details,
    can_access_team_comment: state.content.can_access_team_comment,
    can_access_show_team_comment: state.content.can_access_show_team_comment,
    can_add: state.content.can_add,
  };
};

export default connect(mapStateToProps, { })(MobileContenGroupNav);
