import { userConstants } from '../constants';
const initialState = { loading: false, error: '' };

export function assigned_user(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.ASSIGNED_USER_LOADING: {
      return {
        ...state,
        assigned_user_loading: true
      };
    }

    case userConstants.UNCHECKED_VALUE: {
      return {
        ...state,
        unchecked_value: action.payload
      }
    }

    case userConstants.ASSIGNED_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        assigned_user_loading: false,
        error:action.payload
      };
    }

    case userConstants.NEW_ASSIGNED_USER: {
      return {
        ...state,
        participant_user_options: action.payload.data.participant_user_options,
        workspace: action.payload.data.workspace,
        content_group: action.payload.data.content_group,
        assigned_users: action.payload.data.assigned_users,
        assigned_users_object: action.payload.data.assigned_users_object,
        loading: false,
        assigned_user_loading: false,
        error: []
      }
    }

    case userConstants.ASSIGNED_USER_REQUEST: {
      return {
        ...state,
        loading: false,
        assigned_user_loading: false
      }
    }

    case userConstants.DELETE_ASSIGNED_USER:{
      let id = action.payload.data.data.id
      return {
        ...state,
        assigned_users: state.assigned_users.filter(item => item.id !== id),
        loading: false,
        assigned_user_loading: false
      }
    }

    case userConstants.GET_ASSIGNED_USER:
      return {
        ...state,
        assigned_users: action.payload.data.assigned_users,
        workspace: action.payload.data.workspace,
        content_group: action.payload.data.content_group,
        can_assign_user: action.payload.data.can_assign_user,
        loading: false,
        assigned_user_loading: false
      }
    default:
      return state
  }
}
