function NotificationNavIcon(props) {
  const { className, height, width } = props

  return (
    <svg
      width={ width ? width : "25"}
      height={ height ? height : "24"}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 19C5.21667 19 4.97917 18.9042 4.7875 18.7125C4.59583 18.5208 4.5 18.2833 4.5 18C4.5 17.7167 4.59583 17.4792 4.7875 17.2875C4.97917 17.0958 5.21667 17 5.5 17H6.5V10C6.5 8.61667 6.91667 7.3875 7.75 6.3125C8.58333 5.2375 9.66667 4.53333 11 4.2V3.5C11 3.08333 11.1458 2.72917 11.4375 2.4375C11.7292 2.14583 12.0833 2 12.5 2C12.9167 2 13.2708 2.14583 13.5625 2.4375C13.8542 2.72917 14 3.08333 14 3.5V4.2C15.3333 4.53333 16.4167 5.2375 17.25 6.3125C18.0833 7.3875 18.5 8.61667 18.5 10V17H19.5C19.7833 17 20.0208 17.0958 20.2125 17.2875C20.4042 17.4792 20.5 17.7167 20.5 18C20.5 18.2833 20.4042 18.5208 20.2125 18.7125C20.0208 18.9042 19.7833 19 19.5 19H5.5ZM12.5 22C11.95 22 11.4792 21.8042 11.0875 21.4125C10.6958 21.0208 10.5 20.55 10.5 20H14.5C14.5 20.55 14.3042 21.0208 13.9125 21.4125C13.5208 21.8042 13.05 22 12.5 22Z"/>
    </svg>

  );
}

export { NotificationNavIcon };
