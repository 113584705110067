import { alertConstants, sessionConstants } from '../constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  if (!message || message.length == 0) {
    return { type: sessionConstants.UNKNOWN_ACTION_TYPE };
  } else {
    return { type: alertConstants.ERROR, message };
  }
}

function clear() {
    return { type: alertConstants.CLEAR };
}
