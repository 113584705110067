import { userConstants } from '../constants';

const initialState = { loading: false };

export function multiUser(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.GET_WORKSPACE_DATA: {
      return {
        ...state,
        getWorkspace: action.payload.data,
        loading: false
      }
    }

    case userConstants.WORKSPACE_USER_FAILURE: {
      return{
        ...state,
        loading: false
      }
    }

    case userConstants.WORKSPACE_USER_NEW: {
      return{
        ...state,
        loading: false
      }
    }

    case userConstants.WORKSPACE_USERS_LIST: {
      return{
        ...state,
        allUsers: action.payload.data.users,
        loading: false
      }
    }

    case userConstants.WORKSPACE_USERS_EDIT: {
      return {
        ...state,
        editUserList: action.payload.data,
        loading: false
      }
    }

    case userConstants.WORKSPACE_USERS_UPDATE: {
      return {
        ...state,
        loading: false
      }
    }

    case userConstants.WORKSPACE_USER_DESTROY:{
      let id = action.payload.data.data.id
      return {
        ...state,
        allUsers: state.allUsers.filter(item => item.id !== id),
        loading: false
      }
    }

  default:
    return state
  }
}
