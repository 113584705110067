import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function comment(state = initialState, action) {
  switch (action.type) {
    case userConstants.COMMENT_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.COMMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error:action.payload
      };
    }

    case userConstants.GET_COMMENT: {
      return {
        ...state,
        loading: false,
        comments: action.payload.data.data,
        can_access_comment: action.payload.data.can_access_comment,
        has_next_page: action.payload.data.has_next_page,
        addressComment: null,
        created_comment: null,
        isFocus: false,
      };
    }

    case userConstants.CHAT_SCROLL_UPDATE: {
      return {
        ...state,
        scroll_update_comments: action.payload.data,
        loading: false,
      }
    }

    case userConstants.CHAT_SCROLL_INITIAL_LOAD: {
      return {
        ...state,
        scroll_initial_load: action.payload.data,
        loading: false,
      }
    }

    case userConstants.COMMENT_REQUEST: {
      return {
        ...state,
        loading: false
      };
    }

    case userConstants.CREATED_COMMENT: {
      return {
        ...state,
        created_comment: action.payload.data.comment,
        loading: false
      }
    }


    case userConstants.NEW_COMMENT: {
      let comment = action.payload
      const getMessage = [...state.comments, comment]
      return {
        ...state,
        comments: getMessage,
        comment: null,
        isFocus: true
      }
    }

    case userConstants.DELETE_COMMENT:{
      let id = action.payload.data.data.id
      return {
        ...state,
        comments: state.comments.filter(item => item.id !== id),
        loading: false
      }
    }
  default:
    return state
  }
}
