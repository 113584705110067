import { userConstants } from '../constants';

const initialState = { loading: false };

export function workspaceUser(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.WORKSPACE_USER_INITIAL_DATA: {
      return {
        ...state,
        workspaceInitialData: action.payload.data,
        currentWorkSpace: action.payload.data.workspace,
        error: [],
        loading: false
      }
    }

    case userConstants.WORKSPACE_USER_NEW: {
      return {
        ...state,
        loading: false
      }
    }

    case userConstants.WORKSPACE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case userConstants.RESENT_INVITE_USER: {
      return {
        ...state,
        loading: false,
        resentInviteUser: action.payload.data.data
      }
    }

    case userConstants.RESENT_NOTIFICATIONS: {
      return {
        ...state,
        loading: false
      }
    }

    case userConstants.LIST_COLLABORATORS: {
      return {
        ...state,
        loading: false,
        can_invite_collaborator: action.payload.data.can_invite_collaborator,
        listWorkspaceUser: action.payload.data.workspace_users,
        listInviteUsers: action.payload.data.invite_users,
        can_access_user_add_invite: action.payload.data.can_access_user_add_invite
      }
    }

    case userConstants.INVITE_USER_DESTROY_SUCESS: {
      let id = action.payload.data.data.id
      return {
        ...state,
        loading: false,
        listInviteUsers: state.listInviteUsers.filter(item => item.id !== id),
      }
    }

    case userConstants.WORKSPACE_USER_DESTROY_SUCCESS: {
      let id = action.payload.data.user_id
      return {
        ...state,
        loading: false,
        listWorkspaceUser: state.listWorkspaceUser.filter(item => item.id != id),
      }
    }

    case userConstants.PENDING_NOTIFICATION: {
      return {
        ...state,
        pendingInvitation: action.payload.data.data,
        loading: false
      }
    }

    case userConstants.ACCEPT_INVITATION: {
      let id = action.payload.data.data.id
      return {
        ...state,
        pendingInvitation: (state.pendingInvitation || []).filter(item => item.id !== id),
        loading: false
      }
    }

    case userConstants.All_ACCEPT_INVITATION: {
      return {
        ...state,
        pendingInvitation: action.payload.data.data,
        loading: false
      }
    }

  default:
    return state
  }
}
