import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { user_profileActions } from '../../../actions';
import { profileValidation } from '../../../shared/form-validations';
import { textInput, CheckboxGroup, profileFileUpload, PasswordInput } from '../../../shared/form-elements'
import { reduxForm, Field, reset, change } from "redux-form";
import { Link } from 'react-router-dom';
import { buildFormDataFromObject } from '../../../utils/commonUtils';
import UserPasswordForm from './userPasswordForm';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { Popup, PopupAlert } from '../../../components';

function UserProfileForm(props) {
  const {
    handleSubmit,
    editUserProfile,
    handleCloseUserProfileForm,
    showForm
  } = props;
  const user_error = useSelector(state => state.user_profile);
  const can_delete = useSelector(state => state.user_profile.editUserProfile?.data?.can_delete);
  const dispatch = useDispatch();
  const [show_delete_popup, setDeletePopup] = useState(false);
  const [show_info_popup, setInfoPopup] = useState(false);

  const logo = useSelector(state => {
   if(state.user_profile.editUserProfile) {
       return state.user_profile.editUserProfile.data.logo
   }
   return null;
  });

  useEffect(() => {
    if (showForm) {
      dispatch(user_profileActions.editUserProfile());
    }
  }, [showForm]);

  const onSubmit = (values) => {
    let formData = new FormData();
    buildFormDataFromObject(formData, values, "registration");
    dispatch(user_profileActions.updateUserProfile(formData, handleCloseUserProfileForm));
  }

  const onDeleteUser = () => {
    const callback = () => {
      setDeletePopup(false);
      handleCloseUserProfileForm();
    }

    dispatch(user_profileActions.destroyUserProfile(callback))
  }

  const handleOpenDelete = useCallback(() => {
    if(can_delete){
      setDeletePopup(true)
    } else {
      setInfoPopup(true)
    }
  }, [can_delete]);

  return (
    <>
      <Modal
        show={showForm}
        onHide={handleCloseUserProfileForm}
        className="profile_modal"
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {user_error.error && user_error.error.map((data, index) => (
              <div className="error_message" key={index}>{data}</div>
            ))}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 col-md-5 col-lg-4 mb-4">
                  <h4>Display Photo</h4>

                  <div className='row'>
                    { logo && logo?.thumbnail?.url &&
                      <div className="col-4 col-md-12 text-center">
                        <img
                          width='100%'
                          className={logo && logo.thumbnail.url ? "img-fluid mb-2 border-radius" : ""}
                          src={logo?.thumbnail?.url}
                          style={{ width: '100%', aspectRatio: '1/1' }}
                        />
                      </div>
                    }

                    <div className="col-8 col-md-12">
                      <Field
                        name="logo"
                        component={profileFileUpload}
                        type="file"
                        change={props.change}
                        label="Upload Logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-8">
                  <h4>Info</h4>
                  <div className="mb-2 validation_div">
                    <Field name="first_name" placeholder="First Name" type="text" component={textInput} />
                  </div>

                  <div className="mb-1 validation_div">
                    <Field name="last_name" type="text" component={textInput} placeholder="Last Name"/>
                  </div>

                  <div className="mb-4">
                    <div className="form-check form-switch">
                      <Field
                        name="send_email_notification"
                        type="checkbox"
                        component="input"
                        className="form-check-input mt-3"
                      />
                      <label className="form-check-label">Receive email notifications</label>
                    </div>
                  </div>

                  <h4>Change Password</h4>

                  <div className="mb-2 validation_div">
                    <Field name="current_password" type="password" component={PasswordInput} placeholder="Current Password"/>
                  </div>
                  <div className="mb-2 validation_div">
                    <Field name="password" type="password" component={PasswordInput} placeholder="New Password"/>
                  </div>

                  <div className="mb-2 validation_div">
                    <Field name="confirmation_password" type="password" component={PasswordInput} placeholder="Confirm New Password"/>
                  </div>
                  <div className="form-buttons d-flex justify-content-between">
                    <Button
                      variant="danger"
                      onClick={handleOpenDelete}
                    >
                      Delete Account
                    </Button>
                    <Button variant="primary" type="submit">Save</Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {
        show_delete_popup && can_delete &&
        <Popup
          display_text="You cannot log in this account in 24 hours. This action will permanently delete all data associated with this account after 15 days."
          handleDelete={() => onDeleteUser()}
          handleClose={() => setDeletePopup(false)}
          delete_btn_text={'Delete Account'}
          close_btn_text={'Cancel'}
        />
      }

      <PopupAlert
        show={show_info_popup}
        handleSubmit={() => setInfoPopup(false)}
        handleClose={() => setInfoPopup(false)}
        display_text={'Oops! Leave all shared workspaces to proceed.'}
      />
    </>
  );
}

UserProfileForm =  reduxForm({
  form: 'user_profileform',
  validate: profileValidation,
  enableReinitialize: true
})(UserProfileForm);

const mapStateToProps = (state) => {
  const initialData = state.user_profile.editUserProfile;
  return {
    loading: state.user_profile.loading,
    error: state.user_profile.error,
    editUserProfile: state.user_profile.editUserProfile ,
    initialValues: {
      first_name: state.user_profile.editUserProfile && state.user_profile.editUserProfile.data.first_name,
      last_name: state.user_profile.editUserProfile && state.user_profile.editUserProfile.data.last_name,
      logo: initialData && initialData.data.logo,
      send_email_notification: state.user_profile.editUserProfile && state.user_profile.editUserProfile.data.send_email_notification
    }
  };
};

export default connect(mapStateToProps, { user_profileActions })(UserProfileForm);
