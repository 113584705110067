export const filterArray = (filter_text, array, attribute = null, exact = false) => {
  if (array === undefined || array === null) return [];
  if (array.length === 0) return [];
  if (filter_text === null || filter_text === '' || filter_text === undefined) return array

  const matchExact = (r, string) => {
     var match = string.match(r);
     return match && string === match[0];
  }

  if (typeof array[0] === 'object' && array[0] !== null){
    if (attribute === null) return [];

    var filteredArray = array.filter((str)=>{
      if (exact){
        return matchExact(str[attribute].toLowerCase(), filter_text.toLowerCase())
      } else {
        return str[attribute].toLowerCase().indexOf(filter_text.toLowerCase()) >= 0;
      }
    });

    return filteredArray;
  } else {
    var filteredArray = array.filter((str)=>{
      if (exact){
        return matchExact(str.toLowerCase(), filter_text.toLowerCase())
      } else {
        return str.toLowerCase().indexOf(filter_text.toLowerCase()) >= 0;
      }
    });

    return filteredArray;
  }
};
