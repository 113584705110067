import React, { useEffect } from 'react';
import { connect } from 'react-redux';

function AlertText(props) {
  const { alert} = props;

  return (
    <>
      {
        alert &&
        <div className={`alert alert-text ${alert.type}`}>
          { alert.message }
        </div>
      }
    </>
  );
}



const mapStateToProps = (state) => {
  return { alert: state.alert };
};

export default connect(mapStateToProps)(AlertText);
