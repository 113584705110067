import React, { useEffect } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import TemplateStageForm from "../TemplateStagePage/form";
import { template_stageActions } from '../../../actions';

function NewTemplateStage(props) {
  const dispatch = useDispatch();;
  const { handleCloseNewForm, template_stage_loading } = props;

  useEffect(() => {
  }, []);

  const template_stage_error = useSelector(state => state.template_stage);

  const showResults = (values) => {
    if(template_stage_loading) return;

    dispatch(template_stageActions.createTemplateStage(values, handleCloseNewForm));
  }

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <TemplateStageForm onSubmit={showResults}
      newForm="newForm"
      template_stage_error={template_stage_error && template_stage_error}
      handleCloseForm={handleCloseNewForm}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.template_stage.loading,
    template_stage_loading: state.template_stage.template_stage_loading
  };
};

export default connect(mapStateToProps, { template_stageActions })(NewTemplateStage);
