const fieldArrayValidation = (values, props) => {
  const errors = {};
  // if(!values.user_roles){
  //   errors.user_roles = 'required'
  // }

  const {
    usersList,
    remaining_users,
    editForm,
    current_organization,
    ignore_limit
  } = props;

  if (!values.invited_users || !values.invited_users.length) {
    errors.users = { _error: 'At least one member must be entered' };
  } else {
    const usersArrayErrors = [];
    values.invited_users.forEach((invited_user, userIndex) => {
      const usersErrors = {};
      if (!invited_user || !invited_user.first_name) {
        usersErrors.first_name = 'Required';
        usersArrayErrors[userIndex] = usersErrors;
      }

      if ((!invited_user || !invited_user.last_name) && invited_user.email) {
        usersErrors.last_name = 'Required';
        usersArrayErrors[userIndex] = usersErrors;
      }

      if (!invited_user || !invited_user.email) {
        usersErrors.email = 'required';
        usersArrayErrors[userIndex] = usersErrors;
      } else if (
        invited_user.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(invited_user.email)
      ) {
        usersErrors.email = 'required validate email';
        usersArrayErrors[userIndex] = usersErrors;
      }

      if (
        editForm === undefined &&
        current_organization &&
        !current_organization.personal &&
        invited_user &&
        invited_user.email &&
        usersList &&
        !ignore_limit
      ){
        const isInvited = usersList?.filter((e) => e.email === invited_user.email).length > 0

        if (!isInvited && (userIndex + 1 > remaining_users)){
          usersErrors.email = 'no seats available for new users';
          usersArrayErrors[userIndex] = usersErrors;
        }
      }

    });
    if (usersArrayErrors.length) {
      errors.invited_users = usersArrayErrors;
    }
  }

  if (!values.invited_workspace_users || !values.invited_workspace_users.length) {
    errors.users = { _error: 'At least one member must be entered' };
  } else {
    const i_w_usersArrayErrors = [];
    values.invited_workspace_users.forEach((i_w_user, userIndex) => {
      const i_w_usersErrors = {};
      if ((!i_w_user || !i_w_user.workspace_role_id) && i_w_user.workspace_id) {
        i_w_usersErrors.workspace_role_id = 'Required';
        i_w_usersArrayErrors[userIndex] = i_w_usersErrors;
      }

      if ((!i_w_user || !i_w_user.workspace_id) && i_w_user.workspace_role_id) {
        i_w_usersErrors.workspace_id = 'Required';
        i_w_usersArrayErrors[userIndex] = i_w_usersErrors;
      }
    });

    if (i_w_usersArrayErrors.length) {
      errors.invited_workspace_users = i_w_usersArrayErrors;
    }
  }


  // if (!values.workspace_users || !values.workspace_users.length) {
  //   errors.workspace_users = { _error: 'At least one member must be entered' };
  // } else {
  //   const workspace_usersArrayErrors = [];
  //   values.workspace_users.forEach((w_user, w_userIndex) => {
  //     const workspace_usersErrors = {};
  //     if (!w_user || !w_user.workspace_id) {
  //       workspace_usersErrors.workspace_id = 'Required';
  //       workspace_usersArrayErrors[w_userIndex] =  workspace_usersErrors;
  //     }
  //     if (!w_user || !w_user.role_id) {
  //       workspace_usersErrors.role_id = 'Required';
  //       workspace_usersArrayErrors[w_userIndex] =  workspace_usersErrors;
  //     }
  //   });
  //   if ( workspace_usersArrayErrors.length) {
  //     errors.workspace_users =  workspace_usersArrayErrors;
  //   }
  // }

  console.log(errors)

  return errors;
};

export default fieldArrayValidation;
