import React, { useEffect } from "react";
import "./contentForm.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { contentActions } from '../../../actions';
import { validation } from "../../../shared/form-validations";
import ContentForm from "../ContentPage/form";

function Content(props) {
  const { workspace_id, editContent, handleClose, content_group_id, hasContents, can_access_content_create, content_popup_notification} = props
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  return (
    <div>
      {can_access_content_create && (
        <ContentForm
          workspace_id={workspace_id}
          newForm = "newForm"
          handleClose={handleClose}
          content_group_id={content_group_id}
          hasContents={hasContents}
          content_popup_notification={content_popup_notification}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.content.loading
  };
};

export default connect(mapStateToProps, { contentActions })(Content);
