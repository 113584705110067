import { useEffect, useContext } from 'react';
import { NavDropdown, Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { organizationActions } from '../../../actions';
import { TopbarContext } from '../../../contexts/TopbarContext';

function OrganizationDropdown(props) {
  const {
          current_organization,
          organizations,
          loading,
          hidden
        } = props
  const dispatch = useDispatch();

  const { is_home_page } = useContext(TopbarContext);

  useEffect(() => {
    dispatch(organizationActions.getOrganizations());
  }, []);

  const getCurrentOrganizationUrl = (organization) => {

    if(organization?.workspaces?.length > 0){
      let first_workspace = organization?.workspaces[0]

      return `/organizations/${organization?.id}/workspaces/${first_workspace?.id}/list`
    } else {
      return `/organizations/${organization?.id}`
    }
  }

  return (
    <NavDropdown
      title={
        <span>
          <span className="dropdown-toggle__text mb-0">
            { current_organization ? current_organization.personal && current_organization.owner ? "My Workspace" : current_organization.name  : 'Select Organization' }
          </span>
        </span>
      }
      id="organization-dropdown"
      className={ is_home_page || hidden ? 'd-none' : '' }
    >
      {
          organizations?.map((data, index) => (
            ( data?.id !== current_organization?.id) &&
              <NavDropdown.Item
                key={index}
                className="text-truncate"
                href={getCurrentOrganizationUrl(data)}
              >
                {data.personal && data.owner ? "My Workspace" : data.name}
              </NavDropdown.Item>
          ))
      }
      { loading &&
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      }
    </NavDropdown>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.organization.loading,
    organizations: state.organization.organizations,
    current_organization: state.organization.current_organization
  };
};

export default connect(mapStateToProps, { })(OrganizationDropdown);
