import React, { useEffect } from "react";
import { validation } from "../../../shared/form-validations";
import { FieldArray, reduxForm } from 'redux-form';
import addUsers from '../AssignedUserPage/shared/addUsers';
import { connect } from 'react-redux';
import CheckIcon from "../../../images/icons/white/check.svg";
import { useMediaQuery } from 'react-responsive';
import { mediaQueryConstants } from '../../../constants';

function AssignedUserForm(props) {
  const { handleSubmit , status, content_group_id, workspace_id, assigned_users, initialize, participant_user_options, assigned_user_error, assigned_users_object } = props
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` });

  useEffect(() => {
    if(assigned_users_object && assigned_users_object.length > 0){
      props.initialize({
        assigned_users: assigned_users_object
      });
    }else {
      props.initialize({assigned_users: []});
    }
  }, [assigned_users_object, isLargeScreen]);

  return (
    <form className="dropdown--with-check" onSubmit={handleSubmit}>

      <div
        className="modal-list"
      >
        <FieldArray
          name='assigned_users'
          component={addUsers}
          users={participant_user_options}
          change={props.change}
        />
      </div>
      <div className={`${isLargeScreen ? 'd-grid gap-2' : 'd-flex justify-content-end mx-2' } submit_button`}>
        <button
          className="btn btn-primary"
          type="submit"
        >
          <img src={CheckIcon} className="button-icon" />
          Save
        </button>
      </div>
    </form>
  );
}

AssignedUserForm = reduxForm({
  form: "assignedUserForm"
})(AssignedUserForm);

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps, {  })(AssignedUserForm);
