import React from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { notificationConstants } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';

function ActionCable(props) {
  const dispatch = useDispatch();
  const user_id = useSelector(state => state.dashboard.currentUser);
  const handleReceivedLists=(res)=> {
    dispatch({ type: notificationConstants.GET_ACTION_CABLE_NOTIFICAIONS, payload: res.data })
  }

  return (
    <ActionCableConsumer
      key={user_id && user_id.id }
      channel={{ channel: "NotificationChannel", user_id: user_id && user_id.id }}
      onConnected={() => "Connected to server."}
      onReceived={handleReceivedLists} 
    />
  );
}

export default ActionCable



