import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {useDetectOutsideClick} from "../../../../utils/useDetectOutsideClick";
import { contentLabelActions, content_groupActions } from '../../../../actions';
import ContentLabelPopup from "../../ContentLabelPopup/list";
import { ContentLabel, CircleAddIcon } from '../../../../components';
import { useMediaQuery } from 'react-responsive';
import { mediaQueryConstants } from '../../../../constants';

function LabelField(props) {
  const {
    can_access_edit_label,
    workspace_id,
    content_group_id,
    labels,
    custom_close_button_bg_color
  } = props;
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const [isActiveLabel, setIsActiveLabel] = useDetectOutsideClick(dropdownRef, false);
  const [isActiveMobileLabel, setIsActiveMobileLabel] = useState(false);
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` });

  useEffect(() => {
    handleClose();
  }, [isLargeScreen]);

  const handleOpenLabelsPopup = (id) => {
    setIsActiveLabel(true)
    setIsActiveMobileLabel(true)
    dispatch(contentLabelActions.getContentLabels(workspace_id))
  }

  document.onkeydown = function (evt) {
   if (evt.keyCode == 27) {
      setIsActiveLabel(false)
      setIsActiveMobileLabel(false)
    }
  };

  const handleClose = () => {
    setIsActiveLabel(false)
    setIsActiveMobileLabel(false)
  }

  const handleDelete = (label_id, e) => {
    e.stopPropagation();

    let requestParams = { label_id: label_id, assigned: false }
    let callback = () => {}
    let content_popup_notification = undefined;

    dispatch(content_groupActions.assignContentLabelToContentGroup(workspace_id, content_group_id, requestParams, content_popup_notification, callback))
  }

  return (
    <div
      className="detail" style={{ position: "relative" }}
      ref={dropdownRef}
    >
      <div className="my-2 text-center">
        { labels?.length > 0 ?
            labels.map((label, index) => (
              <ContentLabel
                id={label.id}
                name={label.name}
                color_hex={label.color_hex}
                editable={can_access_edit_label}
                key={index}
                handleDelete={handleDelete}
                onClick={() => can_access_edit_label ? handleOpenLabelsPopup(content_group_id) : null }
                custom_close_button_bg_color={custom_close_button_bg_color}
              />
            ))
          :
            can_access_edit_label ?
              <div
                className="cursor-pointer"
                onClick={() => can_access_edit_label ? handleOpenLabelsPopup(content_group_id) : null }
              >
                <CircleAddIcon />
              </div>

            :
              <span>-</span>
        }
      </div>
      <nav
        className={`content-label-popup vertical-scroll_thin ${isActiveLabel ? "active" : "inactive"}`}
      >
        <ContentLabelPopup
          labels={ labels }
          workspaceId={ workspace_id }
          contentGroupId={ content_group_id }
          handleClose={ handleClose }
          can_access_edit_label={can_access_edit_label}
          show={isActiveMobileLabel}
        />
      </nav>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.content_group.loading
  };
};

export default connect(mapStateToProps, {})(LabelField);
