import { alertActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makeFormDataPOSTRequest, makePOSTRequest, makeFormDataPUTRequest } from '../utils/Axios';
import { reset, setState } from "redux-form";
import { adminBaseURL } from '../utils/commonUtils';

export const chatActions = { 
  getChatMessages,
  senderMessage,
  messageRead,
  createConversation,
  getCurrentMessageCount
};


export function createConversation(workspace_id) {
  return dispatch => {
    try{
      makePOSTRequest(`/api/v1/messages`, {workspace_id: workspace_id})
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.CREATE_CONVERSATION, payload: response});
          dispatch({type: userConstants.MESSAGE_COUNT, payload: response.data.message_count})
        }else {
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.AUTHENTICATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function senderMessage(reqparams, conversation_id, workspace_id) {
  return dispatch => {
    try{
      makeFormDataPOSTRequest(`/api/v1/new_message/${conversation_id}/workspaces/${workspace_id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.SENDER_MESSAGE, payload:response});
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({type: userConstants.SENDER_ERROR, payload:response});
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.AUTHENTICATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function getChatMessages(conversation_id) {
  return function (dispatch) {
    try{
      makeGETRequest(`/api/v1/get_messages/${conversation_id}`)
      .then(response => {
        dispatch({
          type: userConstants.GET_CHAT_MESSAGES,
          payload: response
        })
      })
    }catch(e){
      dispatch( {
        type: userConstants.AUTHENTICATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getCurrentMessageCount(workspace_id) {
  return function (dispatch) {
    try{
      makeGETRequest(`/api/v1/message_count/${workspace_id}`)
      .then(response => {
        dispatch({
          type: userConstants.GET_CURRENT_MESSAGE_COUNT,
          payload: response
        })
      })
    }catch(e){
      dispatch( {
        type: userConstants.AUTHENTICATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function messageRead(id) {
  return dispatch => {
    try{
      makePOSTRequest(`/api/v1/message_read/${id}`)
    }catch(e){
      dispatch( {
        type: userConstants.AUTHENTICATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}