import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton'

function Popup(props) {
  const {
    otherHandleClose,
    handleClose,
    handleDelete,
    display_text,
    close_btn_text,
    delete_btn_text
  } = props;
  // const [show, setShow] = useState(true);

  // const handleClose = () => {
  //   otherHandleClose();
  //   setShow(false);
  // }
  // const handleShow = () => setShow(true);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      keyboard={true}
    >
      <Modal.Body className="custom_body">
         <div onClick={handleClose} className="close_modal"><CloseButton title="Close" /></div>
        <div className="text-center">
          <div className="mb-4">{display_text ?  display_text : " You sure you wanna delete?"}</div>
          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="default"
              size="sm"
              onClick={handleClose}
              className="px-3"
            >
              { close_btn_text ? close_btn_text : 'Close' } 
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={handleDelete}
              className="mx-3 px-3"
            >
              {delete_btn_text ? delete_btn_text : 'Yes'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { Popup };
