import { useState } from 'react';
import { useSelector } from "react-redux";

function ProjectLabel(props) {
  const { name, color_hex, inline_edit_active, projects} = props
  let text_color = "white"

  if(color_hex){
    var rgb = color_hex.replace('#', '');

    const r = parseInt(rgb.substr(0, 2), 16);
    const g = parseInt(rgb.substr(2, 2), 16);
    const b = parseInt(rgb.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    if (brightness > 155) {
      text_color = "black"
    }
  } else {
    var rgb = "000000";
  }

  const handleOnBlurInput = () => {}
  
  const handleSelect = (e) => {
    props.handleInlineEdit(handleOnBlurInput, { project_id: e.target.value})
  }

  return (
    <div>
      {inline_edit_active === "active" ?
        <div className="d-flex align-items-center select_inline_div">
          <div className="full_width">
            <select 
              className="form-control" 
              style={{backgroundColor: "#"+rgb, color: text_color, WebkitAppearance: 'none'}}
              onBlur={handleOnBlurInput}
              onChange={(e) => handleSelect(e)}
            >
              {projects && projects.map(opt => (
                <option key={opt.id} value={opt.id} selected={opt.name == name}>
                 {opt.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      : 
      <span
        className="project-label"
        style={{
          backgroundColor: "#"+rgb,
          color: text_color
        }}>
        {name}
      </span>
      }
    </div>
  );
}

export { ProjectLabel };
