import { Row, Col, Image } from 'react-bootstrap';
import React, { useState, useContext } from 'react';
import { adminBaseURL } from '../utils/commonUtils';
import { commentAttachmentActions } from '../actions';
import { useDispatch } from 'react-redux';
import { ChatContext } from '../contexts/ChatContext'

function FileAttachment(props) {
  const dispatch = useDispatch();
  const {
    filename,
    workspace_id,
    content_group_id,
    comment_id,
    attachment_id,
    setSelected,
    own,
    downloadable,
    destroyable,
    CustomHandleDelete
  } = props

  const { activeTab } = useContext(ChatContext)
  const workspaceBaseUrl = adminBaseURL("workspaces");

  const downloadAttachment = () => {
    if(downloadable){
      dispatch(
        commentAttachmentActions.downloadCommentAttachment(
          workspace_id,
          content_group_id,
          comment_id,
          attachment_id,
          filename
        )
      );
    }
  }

  const handleMouseOver = () => {
    if(setSelected) setSelected(true);
  };

  const handleMouseOut = () => {
    if(setSelected) setSelected(false);
  };

  const handleDelete = (e) => {
    if(CustomHandleDelete){
      CustomHandleDelete()
    } else {
      e.stopPropagation();
      setSelected(false);

      dispatch(
        commentAttachmentActions.destroyCommentAttachment(
          workspace_id,
          content_group_id,
          comment_id,
          attachment_id,
          activeTab
        )
      );
    }
  }

  return (
    <div className={`attachment-container mb-2 file`}>
      <div
        className="filename-container"
        onClick={() => downloadAttachment()}
        onMouseOver={handleMouseOver}
        onMouseOut ={handleMouseOut}
      >
        <div className="filename-text">
          <i className="fa fa-file" aria-hidden="true"></i>
          { filename }
        </div>

        {
          own && destroyable &&
          <div className="close-button" onClick={(e) => handleDelete(e)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        }
      </div>
    </div>
  );
}

export { FileAttachment };
