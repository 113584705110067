import { userConstants, notificationConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function work_space(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.WORKSPACE_LOADING: {
      return {
        ...state,
        workspace_loading: true
      };
    }

    case userConstants.WORKSPACE_DROPDOWN_LOADING: {
      return {
        ...state,
        workspace_dropdown_loading: true
      };
    }

    case userConstants.WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: false,
        error:action.payload,
        workspace_loading: false,
        workspace_dropdown_loading: false
      };
    }

    case userConstants.WORKSPACE_LIST_DATA: {
      return {
        ...state,
        workspaceList: action.payload.data.workspaces,
        loading: false,
        workspace_loading: false,
        workspace_dropdown_loading: false
      }
    }

    case userConstants.WORKSPACE_REQUEST: {
      return {
        ...state,
        workspace: action.payload.data.workspace,
        loading: false,
        workspace_loading: false,
        workspace_dropdown_loading: false
      };
    }

    case userConstants.GET_WORKSPACE:{
      return{
        ...state,
        ...action.payload.data,
        loading: false,
        workspace_dropdown_loading: false,
        workspace_loading: false
      }
    }

    case userConstants.WORKSPACE_SHOW:{
      let attributes = {
        ...state,
        loading: false,
        can_access_workspace: action.payload.data.can_access_workspace,
        can_access_stage: action.payload.data.can_access_stage,
        can_access_sort_stage: action.payload.data.can_access_sort_stage,
        can_access_content: action.payload.data.can_access_content,
        can_access_move_content_calendar: action.payload.data.can_access_move_content_calendar,
        can_access_move_content_board: action.payload.data.can_access_move_content_board,
        can_access_edit_title: action.payload.data.can_access_edit_title,
        can_access_approval_request: action.payload.data.can_access_approval_request,
        can_access_move_stage_board: action.payload.data.can_access_move_stage_board,
        can_access_live_chat: action.payload.data.can_access_live_chat,
        can_view_live_chat: action.payload.data.can_view_live_chat,
        can_hide_live_chat: action.payload.data.can_hide_live_chat,
        can_access_assigned_user: action.payload.data.can_access_assigned_user,
        can_access_user_add_invite: action.payload.data.can_access_user_add_invite,
        can_access_show_content_details: action.payload.data.can_access_show_content_details,
        can_access_move_content_list: action.payload.data.can_access_move_content_list,
        can_access_add_change_date: action.payload.data.can_access_add_change_date,
        can_access_approval_dots: action.payload.data.can_access_approval_dots,
        can_access_edit_label: action.payload.data.can_access_edit_label
      }

      if (action.payload.data.workspace == state.currentWorkSpace){
        return{
          ...attributes
        }
      }
      else{
        return{
          ...attributes,
          currentWorkSpace: action.payload.data.workspace
        }
      }
    }

    case notificationConstants.WORKSPACE_SHOW_FROM_NOTIFICATION:{
      return {
        ...state,
        loading: false
      }
    }

    case userConstants.STAGE_CREATE_REQUEST: {
      const workspace = [state.currentWorkSpace]
      const stage = action.payload.data
      let replaceObj = workspace.map((item, index) => {
        if (item.id === stage.workspace_id) {
          return { ...item, stages: [...item.stages, stage] };
        }
        return {...item}
      })
      return {
        ...state,
        loading: false,
        currentWorkSpace: replaceObj.reduce((a, b) => Object.assign(a, b), {}),
        workspace_loading: false
      }
    }

    case userConstants.STAGE_UPDATE_REQUEST: {
      const workspace = [state.currentWorkSpace]
      const singleStage = action.payload.data
      let resultStageUpdate = workspace.map((st_item, index) => {
        if (st_item.id === singleStage.workspace_id) {
          let updateRequest = st_item.stages.map((stll, i) => (stll.id === singleStage.id ?
              singleStage
            :
              stll
          ))
          return {...st_item, stages: updateRequest}
        }
         return {...st_item}
      })

      return {
        ...state,
        loading: false,
        currentWorkSpace: resultStageUpdate.reduce((a, b) => Object.assign(a, b), {}),
        workspace_loading: false
      }
    }

    case userConstants.STAGE_DELETE_REQUEST: {
      const workspace = [state.currentWorkSpace]
      const stage = action.payload.data
      let resultStageDelete = workspace.map((item, index) => {
        if (item.id === stage.workspace_id) {
          return { ...item, stages: item.stages.filter(stg => stg.id !== stage.id) };
        }
        return {...item}
      })
      return {
        ...state,
        loading: false,
        currentWorkSpace: resultStageDelete.reduce((a, b) => Object.assign(a, b), {}),
        workspace_loading: false
      }
    }

    case userConstants.UPDATE_CONTENTGROUP_WORKSPACE: {
      const workspace = [state.currentWorkSpace]
      const cg = action.payload.data
      let resultInlineEditDate = []

      resultInlineEditDate = workspace.map((item, index) => {
        if ( item !== undefined && item.id === cg.workspace_id) {
          let contentGroupUpdate = item.content_groups.map((el, i) => (
            (el && cg) && el.id === cg.id ? {...el, due_date: cg.due_date, start_date: cg.start_date, name: cg.name }: el
          ))
          return {...item, content_groups: contentGroupUpdate}
        }
        return {...item}
      });

      return {
        ...state,
        loading: false,
        currentWorkSpace: resultInlineEditDate.reduce((a, b) => Object.assign(a, b), {}),
        workspace_loading: false,
      }
    }

    case userConstants.ASSIGN_CONTENT_LABEL_TO_CONTENT_GROUP: {
      const workspace = [state.currentWorkSpace]
      const labelData = action.payload.data
      let resultLabelUpdate = workspace.map((item, index) => {
        if (item.id == labelData.workspace_id) {
          var result = item.content_groups.map((el, index) => {
            if(el.id == labelData.content_group_id) {
              return { ...el, labels: labelData.content_labels };
            } else {
             return { ...el }
            }
          })
          return { ...item, content_groups: [...result] };
        }
      })
      return {
        ...state,
        loading: false,
        currentWorkSpace: resultLabelUpdate.reduce((a, b) => Object.assign(a, b), {}),
        workspace_loading: false
      }
    }

    case userConstants.EDIT_WORKSPACE:{
      return {
        ...state,
        initialData: action.payload.data.workspace,
        editWorkSpace: action.payload.data,
        loading: false,
        workspace_loading: false
      }
    }

    case userConstants.UPDATE_WORKSPACE:{
      return {
        ...state,
        editContentGroup: null,
        loading: false,
        currentWorkSpace: action.payload.data.workspace,
      }
    }

    case userConstants.WORKSPACE_REMOVE:{
      return {
        ...state,
        currentWorkSpace: null,
      }
    }

    case userConstants.DELETE_WORKSPACE:{
      let id = action.payload.data.data.id
      return {
        ...state,
        work_spaces: state.work_spaces && state.work_spaces.filter(item => item.id !== id),
        loading: false
      }
    }

    case userConstants.WORKSPACE_INITIAL_DATA:{
      return {
        ...state,
        initialData: action.payload.data,
        loading: false,
        workspace_loading: false
      }
    }

    case userConstants.WORKSPACE_VIEW_CHANGE: {
      return {
        ...state,
        workspaceView: action.payload.workspaceView,
      }
    }

  default:
    return state
  }
}
