import React from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import ProjectForm from "../ProjectPage/form";
import { projectActions } from '../../../actions';
import { LoadSpinner } from '../../../components';

function NewProject(props) {
  const dispatch = useDispatch();
  const { handleCloseNewForm } = props
  const project_error = useSelector(state => state.project);

  const showResults = (values) => {
     dispatch(projectActions.createProject(values, handleCloseNewForm));
  }

  if (props.loading) {
    return <LoadSpinner />
  }

  return (
    <div className="container-fluid">
      <ProjectForm
        onSubmit={showResults}
        project_error={project_error && project_error}
        handleCloseForm={handleCloseNewForm}
        newForm="newForm"
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.project.loading,
  };
};

export default connect(mapStateToProps, { projectActions })(NewProject);
