import React from "react";
import autosize from "autosize";

export const textAreaAutoSize = ({
  input,
  type,
  placeholder,
  label,
  id,
  meta: { touched, error },
  className,
  onKeyUp,
  onKeyDown,
  style,
  custom_rows,
  ...rest
}) => {
  return (
    <div className={className} style={style}>
      { label ?  <label className="mb-1" style={{ fontSize: "14px" }}> {label}</label> : null}
      <textarea
        {...input}
        type={type}
        placeholder={placeholder}
        id={id}
        className="form-control"
        rows={custom_rows ? 5 : 1}
        onKeyDown={(event) => {
          if (!!onKeyDown) {
            onKeyDown(event)
          }
        }}
        onKeyUp={(event) => {
          if (!!onKeyUp) {
            onKeyUp(event)
          }

          event.target.focus();
          autosize(event.target);
        }}
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export default textAreaAutoSize;
