import React from "react";

export default function Timer(props) {
  const { time } = props;

  let d = Number(time) / 1000;
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  return (
    <div className="timer">
      <span className="digits">
          {h}:
      </span>
      <span className="digits">
          {("0" + m).slice(-2)}:
      </span>
      <span className="digits">
          {("0" + s).slice(-2)}
      </span>
    </div>
  );
}
