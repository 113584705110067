import { userConstants } from '../constants';
import { makeGETRequest, makeFormDataPOSTRequest, makeDELETERequest } from '../utils/Axios';
import { buildFormDataFromObject } from '../utils/commonUtils';
import { alertActions } from './';

export const search_historyActions = {
  getSearchHistories,
  createSearchHistory,
  destroySearchHistory
};

export function getSearchHistories(workspace_id) {
  return dispatch => {
    try{
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/search_histories`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_SEARCH_HISTORIES,
            payload: response
          })
        }
        else{
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.SEARCH_HISTORY_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function createSearchHistory(workspace_id, text) {
  return dispatch => {
    try{
      var objValues = { text: text }
      let formData = new FormData();
      buildFormDataFromObject(formData, objValues, "search_history");

      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/search_histories`, formData)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.CREATE_SEARCH_HISTORY,
            payload: response
          })
        }
        else{
          //   Set empty search after failed fetch
          // so the content_groups would still be loaded
          // at workspace show page.
          dispatch({
            type: userConstants.CREATE_SEARCH_HISTORY,
            payload: { data: { search_history: { text: '', workspace_id: workspace_id } } }
          })

          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.SEARCH_HISTORY_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroySearchHistory(workspace_id, id) {
  return dispatch => {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/search_histories/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.DELETE_SEARCH_HISTORY,
            payload: response
          })
        }else{
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.SEARCH_HISTORY_FAILURE,
        payload: console.log(e),
      })

    }
  }
}
