import { daysBetweenDates } from '../utils';

export function daysBeforeToday(end_date) {

  const dateTimeToday = new Date();
  const dateToday = new Date();
  const endDate = new Date(end_date);

  const past_due = end_date ? dateTimeToday > new Date(end_date) :  false;

  endDate?.setHours(0,0,0,0);
  dateToday?.setHours(0,0,0,0);

  const diff_in_day = (past_due || end_date === undefined) ? 0 : daysBetweenDates(dateToday, endDate);

  return diff_in_day
}
