import React, { useState, useEffect } from "react";
import Timer from "./stopwatch/Timer";
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

function StopWatch(props) {
  const { initialTime, initialStartDate, isActive }  = props;
  let date_now = new Date();
  let date_from = new Date(initialStartDate);
  let seconds = (date_now.getTime() - date_from.getTime());
  let startTime = false ? (initialTime + seconds) : initialTime
	const [time, setTime] = useState(startTime || 0);
  const [startDate, setStartDate] = useState(initialStartDate);

	useEffect(() => {
		let interval = null;

		if (isActive) {
			interval = setInterval(() => {
				setTime((time) => time + 1000);
			}, 1000);
		} else {
			if (interval) clearInterval(interval);
		}
		return () => {
			if (interval) clearInterval(interval);
		};
	}, [isActive]);

  useEffect(() => {
    if (startDate !== initialStartDate ){
      setStartDate(initialStartDate)
      setTime(initialTime)
    }
	}, [initialTime]);

  useEffect(() => {
    if (initialTime !== time ){
      setTime(initialTime)
    }
	}, [isActive]);


	return (
		<div className="stop-watch">
			<Timer time={time} />
		</div>
	);
}

export { StopWatch };
