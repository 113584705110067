import React, { useEffect, useState, useRef } from "react";
import "./contentForm.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { userActions } from "../../../actions";
import { contentActions, content_groupActions } from '../../../actions';
import { contentValidation } from "../../../shared/form-validations";
import { textInput } from "../../../shared/form-elements";
import textArea from "../../../shared/form-elements/textArea";
import textAreaAutoSize from "../../../shared/form-elements/textAreaAutoSize";
import { reduxForm, Field, reset, change, submit } from "redux-form";
import { Button, Row, Col } from "react-bootstrap";
import { adminBaseURL, buildFormDataFromObject } from '../../../utils/commonUtils';
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LoadSpinner } from '../../../components';
import FileUploadIcon from "../../../images/icons/file.svg";

function Content(props) {
  const {
    workspace_id,
    handleSubmit,
    newForm,
    content_id,
    loading,
    handleClose,
    content_group_id,
    hasContents,
    editContent,
    content_popup_notification,
    workspace
  } = props
  // const { workspace_id } = useParams();
  const [files, setFiles] = useState([]);
  const content_error = useSelector(state => state.content);
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const [isDisabled, setIsDisabled] = useState(false);

  let content_attachments = useSelector(state => state.content.contents);

  let new_content = false;
  useEffect(() => {
    if(newForm == "newForm"){
      dispatch(change("contentForm", "attachment", ''));
    }
  }, []);

  const onSubmit = (values) => {
    if(!isDisabled){
      new_content = true
      setIsDisabled(true);

      let formData = new FormData();
      buildFormDataFromObject(formData, values, "content");
      if(newForm == "newForm"){
        dispatch(contentActions.createContent(formData, workspace_id, content_group_id, content_popup_notification ));
        setFiles([]);
      }else {
        if ( newForm == "replaceForm" ) {
          formData.append("replace", true)
        }
        dispatch(contentActions.updateContent(formData, content_id, new_content, workspace_id, content_group_id, handleClose, setIsDisabled, content_popup_notification));
      }
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    dispatch(change("contentForm", "attachment", acceptedFiles));
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true, accept: 'image/jpeg, image/png, .pdf' });

  const getContentMaxHeight = () => {
    const currentContentContainer = document.getElementById("content_popup_wrapper");

    if (!currentContentContainer) {
      return null;
    } else {
      let containerHeight = currentContentContainer.offsetHeight;
      let portionHeight = containerHeight * 0.6;
      let minimumHeight = 500;

      return Math.max(portionHeight, minimumHeight);
    }
  };

  const submitForm = (form) => {
    new_content = true
  };

  const onFileChange = (e) => {
    let changeCallback = getInputProps().onChange

    if (!!changeCallback) {
      changeCallback(e)
    }

    return false

    e.target.form.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )

    onSubmit(e.target.form)
  };

  return (
    <div className="">
      {props.loading && <LoadSpinner />}

      <div className="col-8 offset-2">
        <form onSubmit={handleSubmit(onSubmit)}>

          { files.length > 0 ?
            files.map((file, index) => {

              return (
                  <div key={index} className="center-secton mb-2 text-center">
                  {
                    file.type == "application/pdf" ?
                      <>
                        <i className="fa fa-file-pdf-o mr-2 small-icon d-block" aria-hidden="true"></i>
                        {file.name}
                      </>
                    :
                      <img style={{ display: "block", marginRight: "auto", marginLeft: "auto", minHeight: "" + getContentMaxHeight() + "px", maxHeight: "" + getContentMaxHeight() + "px" }}
                        src={file.preview}
                        alt={file.name}
                      />
                  }
                  </div>
              )
            })
          :
            <>
              {
                newForm == "replaceForm" && editContent && editContent.content_attachment &&

                <img
                  src={editContent && editContent.original.url}
                  style={{ display: "block", marginRight: "auto", marginLeft: "auto", minHeight: "" + getContentMaxHeight() + "px", maxHeight: "" + getContentMaxHeight() + "px" }}
                />
              }
            </>


          }

          <div className="mb-3 empty-content_container">
            <div {...getRootProps()}>
              <input {...getInputProps()} name="attachment" onChange={onFileChange} />
              { isDragActive ? (
                <p>
                  { isDisabled ?
                      'Submitting...'
                    :
                      'Drop the file here ...'
                  }
                </p>
              ) : (
                <div className="file_upload_p">
                  <img src={FileUploadIcon} height="18" className=""/>
                  { isDisabled ?
                      'Submitting...'
                    :
                      files.length == 0 ? 'Add file' : 'Replace file'
                  }
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="d-flex mb-3 mt-4">
              {
                (hasContents || newForm == "replaceForm") &&

                <Button
                  onClick={() => handleClose(newForm == "replaceForm")}
                  variant="default"
                  type="submit"
                  className="half-width p-0"
                >
                  Cancel
                </Button>
              }
              <Button
                onClick={(e) => submitForm(null)}
    variant="primary"
   type="submit"
   className="half-width ml-auto mr-4"
   disabled={isDisabled}
   >
   {isDisabled ? 'Submitting...' : 'Save'}
   </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

Content = reduxForm({
  form: "contentForm",
  enableReinitialize: true
})(Content);

const mapStateToProps = (state) => {
  const initialData = state.content.editContent;
  return {
    loading: state.content.loading,
    error: state.content.error,
    editContent: initialData,
    initialValues: {
      content_attachments: initialData && initialData.content_attachments
    },
    currentWorkSpace: state.content.currentWorkSpace
  };
};

export default connect(mapStateToProps, { contentActions })(Content);
