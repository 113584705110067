import React, { useEffect, useState } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import { template_stageActions } from '../../../actions';
import { Button, Table } from 'react-bootstrap';
import { Popup, LoadSpinner } from '../../../components';
import TemplateStageNew from '../TemplateStagePage/new';
import TemplateStageEdit from '../TemplateStagePage/edit';

function TemplateStageList(props) {
  const dispatch = useDispatch();
  const { template_stages, editTemplateStage } = props

  useEffect(() => {
    dispatch(template_stageActions.getTemplateStage());
  }, []);

  const deleteTemplateStage = (id) => {
    console.log(id)
    dispatch(template_stageActions.destroyTemplateStage(id));
  }

  /*
    Popup Modal
  */
  const [popup, setPopUp] = useState({
    show: false,
    id: null,
  });;

  const handleClose = () => {
    setPopUp({show: false, id: null});
  }

  const handleShow = (id) => setPopUp({show: true, id});

  /*
    Popup New Form Modal
  */
  const [popupNewForm, setPopUpNewForm] = useState({
    show: false
  });;

  const handleCloseNewForm = () => {
    setPopUpNewForm({show: false});
  }

  const handleShowNewForm = () => {
    dispatch(template_stageActions.newTemplateStage(setPopUpNewForm));
  }

  /*
    Popup Edit Form Modal

  */

  const [popupEditForm, setPopUpEditForm] = useState({
    show: false,
    id: null
  });;

  const handleCloseEditForm = () => {
    setPopUpEditForm({ show: false, id: null });
  }

  const handleShowEditForm = (id) => {
    dispatch(template_stageActions.editTemplateStage(id, setPopUpEditForm));
  }


  const handleDelete = () => {
    deleteTemplateStage(popup.id);
    handleClose();
  }

  if (props.loading) {
    return <LoadSpinner />
  }

  return (
    <div  className="container-fluid">
      {props.template_stage_loading && <LoadSpinner />}
      <div className="col-sm-12">
        <ol className="breadcrumb float-sm-right">
          <li className="breadcrumb-item">Organization</li>
          <li className="breadcrumb-item active">Board Stages</li>
        </ol>
      </div>
      <div className="col-lg-12">
        <h2 className="mb-2">Board Stages</h2>

        <div className="mb-3">
          <Button onClick={() => handleShowNewForm()} className="px-2 btn-sm" variant="primary">Add Board Stage</Button>
        </div>
        <Table className="table table-bordered table-light" responsive>
          <thead>
            <tr>
              <th>Board Stage</th>
              <th style={{width: '15%'}} className="text-center"></th>
            </tr>
          </thead>
          <tbody>
           {  template_stages && template_stages.length > 0
              ?
                template_stages.map((data, index) => (
                  <tr key={index}>
                    <td>{data.name}</td>
                    <td>
                      <div className="table-actions">
                        <Button onClick={() => handleShowEditForm(data.id)} className="px-2" size="sm" variant="secondary">Edit</Button>
                        <Button onClick={() => handleShow(data.id)} className="px-2" size="sm" variant="danger">Delete</Button>
                      </div>
                    </td>
                  </tr>
                ))
              :
               <tr>
                  <td colSpan="2">
                    <div className="d-flex justify-content-center py-4">
                      There are no board stages yet.
                    </div>
                  </td>
               </tr>
            }
          </tbody>
        </Table>
      </div>

      {popup.show &&
        <Popup
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      }

      {popupNewForm.show &&
        <TemplateStageNew
          handleCloseNewForm={handleCloseNewForm}
        />
      }

      {popupEditForm.show &&
          <TemplateStageEdit
            handleCloseEditForm={handleCloseEditForm}
            template_stage_id={popupEditForm.id}
            editTemplateStage={editTemplateStage && editTemplateStage.data}
          />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    template_stage_loading: state.template_stage.template_stage_loading,
    loading: state.template_stage.loading,
    template_stages: state.template_stage.template_stages,
    editTemplateStage: state.template_stage.editTemplateStage
  };
};

export default connect(mapStateToProps, { template_stageActions })(TemplateStageList);
