import React, { useEffect, useState } from 'react';
import ActionCable from "../ChatPage/actionCable";
import ChatForm from "../ChatPage/chatForm";
import CountActionCable from "../ChatPage/countActionCable";

function ChatMessage(props) {
  return (
    <div className="chat-message">
      <ActionCable/>
      <CountActionCable />
      <ChatForm/>
    </div>
  );
}

export default ChatMessage