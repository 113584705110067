import { userConstants } from '../constants';
const initialState = { loading: false, error: '' };

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.USER_SUMMARY_LOADING: {
      return {
        ...state,
        user_summary_loading: true
      };
    }

    case userConstants.USER_SUMMARY_LOADING: {
      return {
        ...state,
        user_profile_loading: true
      };
    }

    case userConstants.LOGIN_USER:
      return {
        ...state,
        loginUser: action.payload,
        loading: false
      }
    default:
      return state
  }
}
