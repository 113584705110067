import { useState } from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton'

function ImagePopup(props) {
  const { handleClose, image_url } = props

  return (
    <Modal show={true} onHide={handleClose} keyboard={true}>
      <Modal.Body className="custom_body">
         <div onClick={handleClose} className="close_modal"><CloseButton title="Close" /></div>
        <div className="text-center">
          <Image
            src={ image_url }
            fluid
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { ImagePopup };
