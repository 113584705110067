import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton'
import { buttonClassForContentGroupAction } from '../../../../utils/contentGroupUtils';

function StatusChangePopup(props) {
  const {
    otherHandleClose,
    handleSubmit,
    setDescription,
    description,
    status,
    statuses,
    isDisabled
  } = props;

  const [show, setShow] = useState(true);

  const handleClose = () => {
    otherHandleClose();
    setShow(false);
  }

  const handleShow = () => setShow(true);

  var title = () => {
    switch(status){
      case statuses[0]:
        return "Disapprove"
        break;
      case statuses[1]:
        return "Submit for Approval"
        break;
      case statuses[2]:
        return "Cancel Submission"
        break;
      case statuses[3]:
        return "Approve"
        break;
      case statuses[4]:
        return "Accept Request"
        break;
      case statuses[5]:
        return "Reject Request"
        break;
    }
  };

  var placeholder = () => {
    switch(status){
      case statuses[0]:
        return "What are your revisions?"
        break;
      default:
        return "Send them a message..."
        break;
    }
  };

  return (
    <Modal show={show} onHide={handleClose} keyboard={true}>
      <Modal.Body className="custom_body p-4">
        <div className="text-center">
          <textarea
            className="form-control mb-4"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder={placeholder()}
            rows={5}
          />

          <div className="d-flex align-items-center justify-content-center">
            <Button variant="default" size="sm" onClick={handleClose} className="px-3"> Cancel </Button>
            <Button variant={buttonClassForContentGroupAction(title())} size="sm" onClick={handleSubmit} className="mx-3 px-3"  disabled={!description || isDisabled}>{isDisabled ? 'Submitting...' : title()}</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StatusChangePopup;
