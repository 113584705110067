export function buttonClassForContentGroupAction(action) {
  if (action === 'Submit for Approval') {
    return 'success';
  } else if (action == 'Comment') {
    return 'info';
  } else if (action === 'Void Submission') {
    return 'danger';
  } else if (action == 'Accept Request') {
    return 'success';
  } else if (action == 'Reject Request') {
    return 'danger';
  } else if (action == 'Approve') {
    return 'success';
  } else if (action == 'Request Revisions') {
    return 'brand';
  } else if (action == 'Select') {
    return 'info';
  } else if (action == 'Disapprove') {
    return 'brand';
  }
}
