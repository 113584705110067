export const removeSearchHistoryDuplicate = (array) => {
  let workspace_ids = [...new Set(array.map((a) => a['workspace_id']))]

  var i;
  let key = 'text';
  let uniqueArray = [];

  for(i=0; i < workspace_ids.length; i++){
    let arrayUniqueByKey = [...new Map(array.filter((e) => e['workspace_id'] === workspace_ids[i]).map(item =>
      [item[key], item])).values()];
    uniqueArray.push(...arrayUniqueByKey)
  }

  return uniqueArray;
};
