import { useState, useEffect } from "react";
export const useDetectOutsideClick = (el, initialState, delayed_click = 0) => {
  const [isActive, setIsActive] = useState(initialState);
  const [ignoredClicks, setIgnoredClicks] = useState(delayed_click)

  useEffect(() => {
    if(!isActive) setIgnoredClicks(delayed_click);
  }, [isActive]);

  useEffect(() => {
    const onClickOutside = e => {
      if (
          (
            el.current !== null &&
            !el.current.contains(e.target) &&
            !e.target.classList.contains('shares_popup') &&
            ignoredClicks <= 0
          ) ||
            e.target.classList.contains('btn-close')
          ) {
            setIsActive(false);
            setIgnoredClicks(delayed_click);
      } else {
        setIgnoredClicks(prev => prev - 1)
      }
    };

    if (isActive) {
      document.addEventListener("click", onClickOutside, true);
    }

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [isActive, ignoredClicks, el]);

  return [isActive, setIsActive];
};
