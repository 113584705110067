import React, { useEffect } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import TemplateStageForm from "../TemplateStagePage/form";
import { template_stageActions } from '../../../actions';
import { change } from "redux-form";

function EditTemplateStage(props) {
  const dispatch = useDispatch();
  const { editTemplateStage, handleCloseEditForm, template_stage_id, template_stage_loading } = props;

  useEffect(() => {
  }, []);

  const template_stage = useSelector(state => state.template_stage);

  const showResults = (values) => {
    if(template_stage_loading) return;

    dispatch(template_stageActions.updateTemplateStage(values, template_stage_id, handleCloseEditForm));
  }
  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <TemplateStageForm onSubmit={showResults}
      template_stage_error={template_stage && template_stage}
      editTemplateStage = {editTemplateStage}
      handleCloseForm={handleCloseEditForm}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.template_stage.loading,
    error: state.template_stage.error,
    template_stage_loading: state.template_stage.template_stage_loading,
    editTemplateStage: state.template_stage.editTemplateStage
  };
};

export default connect(mapStateToProps, { template_stageActions })(EditTemplateStage);
