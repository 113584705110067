import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { UserAvatar, UserProfileStatus } from "../../../../components";
import { Button } from 'react-bootstrap';
import { user_profileActions } from '../../../../actions/user_profile.actions.js';
import { useParams } from "react-router-dom";
import DefaultBannerImage from '../../../../images/user-summary_default-banner.png';

function ProfileBanner(props) {
  const { currentUser, loading, availability, workspace } = props;
  const dispatch = useDispatch();
  let { user_id } = useParams();
  const [editingBanner, setEditingBanner] = useState(false);

  useEffect(() => {
    if (user_id) {
      dispatch(user_profileActions.showUser(user_id));
    }
  }, []);

  const bannerStyle = () => {
    return {
      backgroundImage: `url(${DefaultBannerImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }
  }

  return (
    <div className='user_profile-banner-container mb-2'>
      <div
        className='user_profile-banner'
        style={bannerStyle()}
      >
        {
          false ?
            editingBanner ?
              <>
                <Button
                  className="btn-save-banner"
                  onClick={() => setEditingBanner(false)}
                >
                  Save
                </Button>

                <Button
                  className="btn-cancel-banner"
                  onClick={() => setEditingBanner(false)}
                >
                  Cancel
                </Button>
              </>
            :
              <Button
                className="btn-edit-banner"
                onClick={() => setEditingBanner(true)}
              >
                Edit Banner
              </Button>
          :
            <></>
        }
      </div>
      <div
        className='user_profile-details-container d-md-flex p-1'
      >
        {currentUser &&
          <>
            <UserAvatar
              name={currentUser.name}
              name_initials={currentUser.name_initials}
              icon={currentUser.logo?.icon?.url}
              size={120}
              bordered={true}
              containerClassName={'avatar-container'}
            />

            <div className='user_profile-details-name pt-2'>
                <h1 className='h2 mb-1'>
                  {currentUser.name}
                </h1>

                { currentUser?.roles?.length > 0 &&
                  <ul className="user_profile-details-roles list-unstyled">
                    {
                      currentUser?.roles.map((role, index) => (
                        <li key={`role_${index}`}>
                          { role.toLowerCase() }
                        </li>
                      ))
                    }
                  </ul>
                }

                {
                  false &&
                  <UserProfileStatus className='justify-content-md-start justify-content-center'/>
                }
            </div>
          </>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.user_profile.user_profile_loading,
    currentUser: state.user_profile.currentUserProfile,
    workspace: state.user_profile.activity_workspace,
    availability: state.user_profile.profileAvailability
  };
};

export default connect(mapStateToProps, {})(ProfileBanner);
