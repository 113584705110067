import { useEffect, useContext } from 'react'
import { useDispatch } from "react-redux";
import { work_spaceActions, alertActions, content_groupActions } from "../../../../actions";
import { buildFormDataFromObject, adminBaseURL } from '../../../../utils/commonUtils';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button } from "react-bootstrap";
import CardGroup from "./card_group";
import EmptyStage from '../../../../images/empty-stage.png';
import { WorkspaceShowContext } from "../../../../contexts/WorkspaceShowContext";
import { CircleAddIcon } from '../../../../components';

function Board(props) {
  const {
    stages,
    content_groups,
    workspace_id,
    organization_id,
    can_crud_content_group,
    setNewStageFormPopup,
    can_access_move_content_board,
    can_access_move_stage_board,
    can_access_edit_title,
    can_access_show_content_details,
    can_access_approval_dots,
    can_access_edit_label
  } = props;
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces");

  // Function for getting Data
  const getContentGroups = (obj) => {
    return obj.content_groups.map(item => ({
      id: item.id.toString(),
      name: item.name,
      stage_id: item.stage_id,
      stage: item.stage,
      first_record: item.first_record,
      preview: item.preview,
      due_date: item.due_date,
      updated_at: item.updated_at,
      discussion_count: item.discussion_count,
      internal_note_count: item.internal_note_count,
      index: item.index,
      assigned_users: item.assigned_users,
      labels: item.labels
    }))
  }

  const {
    can_access_stage,
    rows,
    setRows,
    getStages,
    updateRecords
  } = useContext(WorkspaceShowContext);

  useEffect(() => {
    updateRecords(props);
  }, []);

  useEffect(() => {
    updateRecords(props);
  }, [props.stages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const visibleDivObserver = new IntersectionObserver((entries, imgObserver) => {
        var collect_id = []
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const find_value = entry.target
            if (find_value.getAttribute('data-image-pending') == 'true') {
              collect_id.push(find_value.getAttribute('data-id'));
              find_value.setAttribute('data-image-pending', 'false');
            }
          }
        })
        if(collect_id.length > 0) {
          var content_groups_id = collect_id
          dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, content_groups_id ));
        }
      });
      var visible_div = document.querySelectorAll("#parent_div_board > div > div > div > div .visible_board_div");
      visible_div.forEach((v) => {
        visibleDivObserver.observe(v);
      })
    }, 300);
    return () => clearTimeout(timer);
  }, [rows]);

  /*
    Update BackEnd
  */
  const updateIndexContentGroup = (newColumn, content_drag_id, source_stage_id, destination_stage_id, type) => {
      const hash = newColumn.map((column) => ({
        id: column.id,
        items: column.items.map((item) => ({
          id: item.id
        }))
      }))

      let formData = new FormData();
      buildFormDataFromObject(formData, hash, "sort");
      dispatch(work_spaceActions.updateSortContentGroupWorkSpace(formData, workspace_id, [content_drag_id], source_stage_id, destination_stage_id, type));
  }

  const onDragEnd = (result, rows, setRows) => {
    if(can_access_move_content_board) {
      if (!result.destination) return;
      const { source, destination, type, draggableId } = result;
      let newColumn = []
      let content_drag_id = ""
      let source_stage_id = ""
      let destination_stage_id = ""

      if (type == "COLUMN") {
        const columnArr = rows
        const [removed] = columnArr.splice(source.index, 1);
        content_drag_id = draggableId.replace(/column-/g,'')

        columnArr.splice(destination.index, 0, removed);

        setRows(columnArr);

        updateIndexContentGroup(columnArr, content_drag_id, source_stage_id, destination_stage_id, type)
      } else {
        content_drag_id = result.draggableId.replace(/card-/g,'')

        if (source.droppableId !== destination.droppableId) {

          let sourceColumn = rows.find((element) => element.id == source.droppableId);
          let destColumn = rows.find((element) => element.id == destination.droppableId);
          source_stage_id = sourceColumn.id
          destination_stage_id = destColumn.id

          const sourceItems = [...sourceColumn.items];
          const destItems = [...destColumn.items];
          const [removed] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, removed);

          destColumn.items = destItems;
          sourceColumn.items = sourceItems;

          rows = rows.filter(el => el.id !== source.droppableId || el.id !== destination.droppableId)

          newColumn = [
            ...rows
          ]

          setRows(newColumn);
        } else {

          const column = rows.find((element) => element.id == source.droppableId);
          const index = rows.findIndex((element) => element.id == source.droppableId);
          const copiedItems = [...column.items];
          const [removed] = copiedItems.splice(source.index, 1);
          copiedItems.splice(destination.index, 0, removed);

          column.items = copiedItems;

          rows[index] = column;

          newColumn = [
            ...rows
          ]

          setRows(newColumn)
        }
        updateIndexContentGroup(newColumn, content_drag_id, source_stage_id, destination_stage_id, type)
      }

    }else {
      dispatch(alertActions.error("You are not authorized to access this page"));
    }
  }

  let column_length = rows.length;

  return (
    <>
      {
        column_length == 0  ?
          <div className="container layout__container-wrapper">
            <div style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>
              <img src={ EmptyStage } style={{ width: "500px", maxWidth: '100%', textAlign: 'center' }} />
              <p className="mt-3" style={{ fontWeight: '600', fontSize: '1.4rem' }}>This workspace has no stage yet.</p>

              { can_access_stage &&
                <Button variant="brand" onClick={() => setNewStageFormPopup(true)} className="mt-3 d-flex align-items-center">
                  <CircleAddIcon color="white" height={'16'} width={'16'} className="mr-2"/>
                  Add stage
                </Button>
              }
            </div>
          </div>
        :
        <DragDropContext
          onDragEnd={result => onDragEnd(result, rows, setRows)}
          style={{ maxHeight: "100%" }}
        >
          <Droppable
            droppableId="Board"
            type="COLUMN"
            direction="horizontal"
          >
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="kanban-board-stages"
                  id="parent_div_board"
                >
                  {rows.map((column, index) => {
                    return (
                      <CardGroup
                        key={column.id}
                        index={index}
                        column={column}
                        content_groups={content_groups}
                        can_crud_content_group={can_crud_content_group}
                        workspace_id={workspace_id}
                        organization_id={organization_id}
                        can_access_move_content_board={can_access_move_content_board}
                        can_access_edit_title={can_access_edit_title}
                        can_access_move_stage_board={can_access_move_stage_board}
                        can_access_show_content_details={can_access_show_content_details}
                        can_access_approval_dots={can_access_approval_dots}
                        can_access_edit_label={can_access_edit_label}
                      />
                    );
                  })}

                  {provided.placeholder}

                  <Draggable
                    draggableId={column_length.toString()}
                    index={column_length}
                    isDragDisabled={true}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="kanban-card-group add-button d-flex empty_card_group"
                          style={{
                            userSelect: "none",
                            ...provided.draggableProps.style,
                          }}
                        >
                          {can_access_stage &&
                            <div>
                              <Button
                                onClick={() => setNewStageFormPopup(true)}
                                className="add-stage_button" title="Add Stage">
                                <div
                                  className={`align-items-center mx-0 button-icon--hover ${column_length == 0 && 'mr-2'}`}
                                >
                                  <CircleAddIcon color="white"/>
                                </div>
                                <div
                                  className={`align-items-center mx-0 button-icon--unhover ${column_length == 0 && 'mr-2'}`}
                                >
                                  <CircleAddIcon color="#8D8E9D"/>
                                </div>
                                {column_length == 0 && "Add Stage"}
                              </Button>

                              {
                                rows.length == 0 &&
                                <p className="mt-3">No available stage.</p>
                              }
                            </div>
                          }
                        </div>
                      );
                    }}
                  </Draggable>

                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      }
    </>
  );
}

export default Board
