import React, { useEffect } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import StageForm from "../StagePage/form";
import { stageActions } from '../../../actions';
import { useParams } from 'react-router-dom';
import { change } from "redux-form";
import { LoadSpinner } from '../../../components';

function EditStage(props) {
  const dispatch = useDispatch();
  let { workspace_id } = useParams();
  const workspace = useSelector(state => state.stage.currentWorkspace);
  const { can_access_approval_request, stage_id, handleCloseEditForm, fromKanban  } = props

  useEffect(() => {
    //dispatch(stageActions.editStage(workspace_id, stage_id));
  }, []);

  const stage_error = useSelector(state => state.stage);

  const showResults = (values) => {
    dispatch(stageActions.updateStage(values, workspace_id, stage_id, handleCloseEditForm, fromKanban));
  }

  return (
    <StageForm onSubmit={showResults}
      request_approval={can_access_approval_request}
      goupId={workspace_id}
      stage_error={stage_error && stage_error}
      handleCloseForm={handleCloseEditForm}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.stage.loading,
    error: state.stage.error,
    can_access_approval_request: state.stage.can_access_approval_request
  };
};

export default connect(mapStateToProps, { stageActions })(EditStage);
