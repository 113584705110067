import { Spinner } from 'react-bootstrap';

function LoadSpinner() {


  return (
    <div id="overlay">
      { false &&
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
    </div>
  );
}

export { LoadSpinner };
