import React, { useEffect, useState } from 'react';
import ChatComposer from "../ChatPage/chatcomposer";
import ChatWindow from "../ChatPage/chatWindow";
import Chat from '../../../images/chat.png'
import Close from '../../../images/close.png'
import CloseWhite from '../../../images/white-close.png'
import { buildFormDataFromObject } from '../../../utils/commonUtils';
import { connect, useDispatch, useSelector } from "react-redux";
import { chatActions } from "../../../actions";
import { useParams } from 'react-router-dom';

function ChatMessage(props) {
  const dispatch = useDispatch();
  const [showPanel, togglePanel] = useState(false);
  const currentUser = useSelector(state => state.dashboard.currentUser);
  const currentConversation = useSelector(state => state.chat.currentConversation);
  const can_hide_live_chat = useSelector(state => state.work_space.can_hide_live_chat);
  const messageCount = useSelector(state => state.chat.messageCount);
  let { workspace_id } = useParams();
  
  const submitted = value => {
    let formData = new FormData();
    buildFormDataFromObject(formData, value, "message");
    dispatch(chatActions.senderMessage(formData, currentConversation.id, workspace_id));
  }

  const handleToggle = () => {
    dispatch(chatActions.createConversation(workspace_id));
    togglePanel(!showPanel)
  }
  return (
    <div className="chat-message">
      {showPanel && (
        <div className={showPanel?'fadeIn':'fadeOut'}>
          <div className="chat-popup" id="is_chat">
            <div className="chat-header">
              <div className="chat-username">{currentUser && currentUser.first_name}</div>
              <div onClick={() => togglePanel(!showPanel)} className="cursor-pointer"><img src={CloseWhite} alt="close"/></div>
            </div>
            <div className="chat-body">
              <ChatWindow />
            </div>
            <div className="chat-footer">
              <ChatComposer closePopup={() => { togglePanel(false) }} submitted={submitted}/>
            </div>
          </div>
        </div>
      )}
      {!can_hide_live_chat && (
        <div className="chat-toggle" onClick={handleToggle}>
          {showPanel ?
            <img src={Close} alt="chat"/> :
            <div>
              {messageCount && messageCount.length > 0 ?
                messageCount.map((data, key) => {
                  return(
                    <div key={key}>
                      { data.unread_message_count != 0 ?
                        <span className="show-count">{data.unread_message_count}</span>
                        :
                        <img src={Chat} alt="chat" style={{width: '18px'}}/>
                      }
                    </div>
                  )
                })
                :
                 <img src={Chat} alt="chat" style={{width: '18px'}}/>
              }
            </div>
          }
        </div>
      )}
    </div>
  );
}

export default ChatMessage
