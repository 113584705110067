export const removeSearchHistory = (array, item) => {

  const sameWorkspaceArray = array.filter(obj => {
    return obj.workspace_id === item.workspace_id;
  });

  const newSameWorkspaceArray = sameWorkspaceArray.filter(obj => {
    return obj.text !== item.text;
  });

  const filteredArray = array.filter(obj => {
    return obj.workspace_id !== item.workspace_id; ;
  });

  return [...filteredArray, ...newSameWorkspaceArray]
}
