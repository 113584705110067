import React, { useEffect } from 'react';
import {connect,  useDispatch } from 'react-redux';
import { multi_userActions, organizationActions } from '../../../actions';
import UserFormEdit from "../MultiUserPage/form";
import { Link } from 'react-router-dom';

function NewUser(props) {
  const dispatch = useDispatch();
  const {
          getWorkspace,
          getCommonData,
          editUser,
          current_organization
        } = props
  const { id } = props.match.params;

  useEffect(() => {
    dispatch(multi_userActions.getWorkspaceData());
    dispatch(multi_userActions.editUserDetails(id))
    dispatch(multi_userActions.getAllUsrs());
  }, []);

  useEffect(() => {
    if(current_organization && !current_organization.personal){
      dispatch(organizationActions.getAllowedUserDetails(current_organization.id));
    }
  }, [current_organization]);

  const showResults = (values) => {
    dispatch(multi_userActions.updateUser(values, id));
  }

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <div  className="container-fluid">
      <div className="col-sm-12">
        <ol className="breadcrumb float-sm-right">
          <li className="breadcrumb-item">Organization</li>
          <li className="breadcrumb-item"><Link to="/admin/users">Users</Link></li>
          { editUser && <li className="breadcrumb-item active">{editUser.invited_users && editUser.invited_users.first_name}</li> }
          <li className="breadcrumb-item active">Edit</li>
        </ol>
      </div>
      <div className="col-lg-12">
        { editUser &&
          <UserFormEdit
            onSubmit={showResults}
            setInitialWorkspace={getWorkspace}
            setCommonData={getCommonData}
            userId={id}
            editUser={editUser}
            loading={props.loading}
            editForm="editForm"
          /> }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.multiUser.loading,
    getWorkspace: state.multiUser.getWorkspace,
    editUser: state.multiUser.editUserList,
    getCommonData: state.dashboard.getCommonData,
    current_organization: state.organization.current_organization
  };
};

export default connect(mapStateToProps, { multi_userActions })(NewUser);
