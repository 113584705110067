import React, {useState, useEffect} from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { userConstants } from '../../../constants';
import { chatActions } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function ActionCable(props) {
  const dispatch = useDispatch();


  let { workspace_id } = useParams();
  const handleReceivedLists=(res)=> {
    let elementId = document.getElementById('is_chat')
    if(elementId !== null) {
      dispatch(chatActions.messageRead(res.data.id, workspace_id));
    }else {
      dispatch(chatActions.getCurrentMessageCount(workspace_id))
    }
    dispatch({type: userConstants.CURRENT_MESSAGE, payload: res});
  }
  return (
    <ActionCableConsumer
      key={workspace_id }
      channel={{ channel: "ChatRoomChannel", room_id: workspace_id}}
      onConnected={() => "Connected to server."}
      onReceived={handleReceivedLists} 
    />
  );
}

export default ActionCable