function TeamCommentIcon(props) {
  const { className, height, width } = props

  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "24"}
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.75 17.2C1.75 16.6333 1.89583 16.1125 2.1875 15.6375C2.47917 15.1625 2.86667 14.8 3.35 14.55C4.38333 14.0333 5.43333 13.6458 6.5 13.3875C7.56667 13.1292 8.65 13 9.75 13C10.85 13 11.9333 13.1292 13 13.3875C14.0667 13.6458 15.1167 14.0333 16.15 14.55C16.6333 14.8 17.0208 15.1625 17.3125 15.6375C17.6042 16.1125 17.75 16.6333 17.75 17.2V18C17.75 18.55 17.5542 19.0208 17.1625 19.4125C16.7708 19.8042 16.3 20 15.75 20H3.75C3.2 20 2.72917 19.8042 2.3375 19.4125C1.94583 19.0208 1.75 18.55 1.75 18V17.2ZM19.2 20C19.3833 19.7 19.5208 19.3792 19.6125 19.0375C19.7042 18.6958 19.75 18.35 19.75 18V17C19.75 16.2667 19.5458 15.5625 19.1375 14.8875C18.7292 14.2125 18.15 13.6333 17.4 13.15C18.25 13.25 19.05 13.4208 19.8 13.6625C20.55 13.9042 21.25 14.2 21.9 14.55C22.5 14.8833 22.9583 15.2542 23.275 15.6625C23.5917 16.0708 23.75 16.5167 23.75 17V18C23.75 18.55 23.5542 19.0208 23.1625 19.4125C22.7708 19.8042 22.3 20 21.75 20H19.2ZM9.75 12C8.65 12 7.70833 11.6083 6.925 10.825C6.14167 10.0417 5.75 9.1 5.75 8C5.75 6.9 6.14167 5.95833 6.925 5.175C7.70833 4.39167 8.65 4 9.75 4C10.85 4 11.7917 4.39167 12.575 5.175C13.3583 5.95833 13.75 6.9 13.75 8C13.75 9.1 13.3583 10.0417 12.575 10.825C11.7917 11.6083 10.85 12 9.75 12ZM19.75 8C19.75 9.1 19.3583 10.0417 18.575 10.825C17.7917 11.6083 16.85 12 15.75 12C15.5667 12 15.3333 11.9792 15.05 11.9375C14.7667 11.8958 14.5333 11.85 14.35 11.8C14.8 11.2667 15.1458 10.675 15.3875 10.025C15.6292 9.375 15.75 8.7 15.75 8C15.75 7.3 15.6292 6.625 15.3875 5.975C15.1458 5.325 14.8 4.73333 14.35 4.2C14.5833 4.11667 14.8167 4.0625 15.05 4.0375C15.2833 4.0125 15.5167 4 15.75 4C16.85 4 17.7917 4.39167 18.575 5.175C19.3583 5.95833 19.75 6.9 19.75 8Z"/>
    </svg>
  );
}

export { TeamCommentIcon };
