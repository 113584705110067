import {
  Modal,
  Button,
  CloseButton
} from 'react-bootstrap';

function PopupAlert(props) {
  const {
    show,
    handleSubmit,
    handleClose,
    display_text,
    btn_text
  } = props;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={true}
    >
      <Modal.Body
        className="custom_body"
      >
         <div onClick={handleClose} className="close_modal"><CloseButton title="Close" /></div>
        <div className="text-center">
          <div className="mb-4">{display_text}</div>
          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="primary"
              size="sm"
              onClick={handleSubmit}
              className="mx-3 px-3"
            >
              {btn_text ? btn_text : 'OK'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { PopupAlert };
