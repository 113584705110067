import React, { useEffect, useRef, useState } from 'react';
import { Button } from "react-bootstrap";
import {connect,  useDispatch, useSelector } from 'react-redux';
import { validation } from '../../../shared/form-validations';
import { content_groupActions } from '../../../actions';
import { useParams } from 'react-router-dom';
import { textInput, hiddenField } from '../../../shared/form-elements'
import { reduxForm, Field, reset, change } from "redux-form";
import CheckIcon from "../../../images/icons/white/check.svg";

function NewContentGroupSingleFieldForm(props) {
  const dispatch = useDispatch();
  const { workspace_id } = useParams();
  const {
    stageId,
    handleSubmit,
    handleOpenContentGroupPopUp,
    handleClose,
    addContentGroupRowRef,
    loading
  } = props
  const [showForm, setShowForm] = useState(true)
  const intialLoaded = useRef(false)

  useEffect(() => {
    dispatch(change("content_groupform", "name", ''))
    dispatch(change("content_groupform", "stage_id", stageId));
    dispatch(change("content_groupform", "workspace_id", workspace_id));

    const handleKeyDown = (event) => {
      if (event.key == "Escape") {
        handleClose()
      }
    }

    document.addEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    const onClick = e => {
      if(intialLoaded.current) {
        if (addContentGroupRowRef.current !== null && !addContentGroupRowRef.current.contains(e.target)) {
          setShowForm(false);
        } else {
          setShowForm(true);
        }
      } else {
        intialLoaded.current = true
      }
    };

    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  useEffect(() => {
    if (!showForm) {
      handleClose();
    }
  }, [showForm]);

  return (
    <div className="content-group-form">
      <form onSubmit={ (e) => loading ? null : handleSubmit(e) }>
        <Field component={textInput} name="name" type="text" placeholder="Add title" input_class_name="inline_edit_input" autoFocus />
        <Field component={ hiddenField } name="stage_id" type="hidden" />
        <Field component={ hiddenField } name="workspace_id" type="hidden" />

        <Button variant="primary" type="submit" size="sm" disabled={loading}>
          <img src={CheckIcon} className="button-icon" />
          Save
        </Button>
        <Button type="submit" variant="hollow" size="sm" onClick={ handleClose }>Cancel</Button>
      </form>
    </div>
  );
}

NewContentGroupSingleFieldForm = reduxForm({
  form: 'content_groupform',
  validate: validation,
  enableReinitialize: true
})(NewContentGroupSingleFieldForm);

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      name: '',
      stage_id: ownProps.stageId,
      workspace_id: null
    },
    loading: state.content_group.content_group_loading
  };
};

export default connect(mapStateToProps, { content_groupActions })(NewContentGroupSingleFieldForm);
