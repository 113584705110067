import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function content_label(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.CONTENT_LABEL_LOADING: {
      return {
        ...state,
        content_label_loading: true
      };
    }

    case userConstants.CONTENT_LABEL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        content_label_loading: false
      };
    }
    
    case userConstants.GET_CONTENT_LABEL:{
      return{
        ...state,
        contentLabels: action.payload.data.data,
        loading: false
      }
    }
    
    case userConstants.CONTENT_LABEL_REQUEST:{
      return {
        ...state,
        contentLabels: [...state.contentLabels, action.payload],
        loading: false,
        content_label_loading: false,
      }
    }


    case userConstants.EDIT_CONTENT_LABEL: {
      return {
        ...state,
        editContentLabel: action.payload.data,
        loading: false,
        content_label_loading: false
      }
    }

    case userConstants.UPDATE_CONTENT_LABEL: {
      let label = action.payload
      let update_labels = state.contentLabels.map((el, i) => (
        el.id === label.id ? {...el, name: label.name, color_hex: label.color_hex }: el
      ))
      return {
        ...state,
        contentLabels: update_labels,
        content_label_loading: false
      };
    }

    case userConstants.DELETE_CONTENT_LABEL:{
      let id = action.payload.id
      return {
        ...state,
        contentLabels: state.contentLabels.filter(item => item.id !== id),
        loading: false
      }
    }

  default:
    return state
  }
}
