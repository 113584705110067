import React, { useEffect, useState, useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Table, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
import { workspace_userActions, organizationActions, work_spaceActions } from '../../../actions';
import { Popup, ElementTooltip } from '../../../components';
import { popUpConstants } from '../../../constants';
import SingleInviteForm from "../CollaboratorPage/single_invite_form";
import RoleUpdateForm from "../CollaboratorPage/role_update_form";
import { UserAvatar } from '../../../components';
import DeleteIcon from "../../../images/icons/delete.svg";
import { TopbarContext } from '../../../contexts/TopbarContext';
import { tooltipConstants } from '../../../constants';

function CollaboratorList(props) {
  const {
    workspaceId,
    handleClose,
    listWorkspaceUser,
    listInviteUsers,
    can_invite_collaborator,
    can_access_user_add_invite,
    current_organization,
    remaining_users,
    ignore_limit
  } = props
  const dispatch = useDispatch();
  const workspace = useSelector(state => state.work_space.currentWorkSpace);
  const isUserRole = localStorage.getItem('user_role');

  const {
    setActiveOffCanvas,
  } = useContext(TopbarContext);

  useEffect(() => {
    dispatch(workspace_userActions.listCollaborators(workspaceId));
  }, [workspaceId]);

  useEffect(() => {
    if(current_organization && !current_organization.personal){
      dispatch(organizationActions.getAllowedUserDetails(current_organization.id));
    }
  }, [current_organization, listWorkspaceUser, listInviteUsers]);

   const resentInviteUser = (id, params) => {
    if (params === "notification"){
      dispatch(workspace_userActions.resentNotification(id));
    }else {
      dispatch(workspace_userActions.inviteUserDestroy(id));
    }
  }

  /*
    Popup Modal
  */
  const [popup, setPopUp] = useState({
    show: false,
    id: null,
  });

  const [userDestroyPopUp, setUserDestroyPopUp] = useState({
    show: false,
    id: null,
  });

  const handleCloseDelete = () => {
    setPopUp({show: false, id: null});
  }

  const handleShow = (id) => setPopUp({show: true, id});
  const toggleUserDestroyPopUp = (id) => setUserDestroyPopUp({show: !userDestroyPopUp.show, id: userDestroyPopUp.show ? null : id})

  const handleDelete = () => {
    resentInviteUser(popup.id, "destroy");
    handleCloseDelete();
  }

  const handleUserDestroy = () => {
    dispatch(workspace_userActions.addedUserDestroy(workspaceId, userDestroyPopUp.id));
    toggleUserDestroyPopUp();
  }

  const removeWorkspace = () => {
    dispatch(work_spaceActions.removeWorkspace());
    setActiveOffCanvas(false);
    handleClose();
  }

  const tooltips = [
    {
      tooltip_title: 'Team Lead',
      tooltip_text: tooltipConstants.ROLE_TEAM_LEAD
    },
    {
      tooltip_title: 'Coordinator',
      tooltip_text: tooltipConstants.ROLE_COORDINATOR
    },
    {
      tooltip_title: 'Production',
      tooltip_text: tooltipConstants.ROLE_PRODUCTION
    },
    {
      tooltip_title: 'Spectator',
      tooltip_text: tooltipConstants.ROLE_APPROVER
    },
    {
      tooltip_title: 'Contributor',
      tooltip_text: tooltipConstants.ROLE_CONTRIBUTOR
    }
  ]

  return (
    <Modal show={true} onHide={handleClose} size={"lg"} className="workspace-users-popup">
      <Modal.Header closeButton>
        <Modal.Title>
          Participants

          <ElementTooltip
            tooltips={tooltips}
            id={'select-team_lead'}
            className={'ml-1'}
            placement={'auto'}
           >
          </ElementTooltip>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          current_organization &&
          <>
            {
              !current_organization.personal && !ignore_limit &&
              ` Seats Available: ${remaining_users || 0} of ${current_organization.allowed_user_count}.`
            }
            {
              isUserRole === "admin" &&
              <Link
                to={`/organizations/${current_organization.id}/admin/users`}
                onClick={removeWorkspace}
                className="px-1"
              >
                View Users
              </Link>
            }
          </>
        }
        {can_access_user_add_invite &&
          <SingleInviteForm
            workspaceId={workspaceId}
          />
        }

        <div className='my-3'>
          { listWorkspaceUser && listWorkspaceUser.length > 0
            ?
              listWorkspaceUser.map((data, index) => (
                <Row key={'list_workspace_user_'+index} className="my-3">
                  <Col lg={8} className={'d-flex align-items-center'}>
                    <UserAvatar
                      containerClassName={'avatar_container'}
                      icon={ data.logo.url ? data.logo.url : null }
                      name={ data.name }
                      name_initials={ data.name_initials }
                      size="36"
                    />

                    <div className="p-1 text-truncate">
                      <div className="workspace-users-popup__name text-truncate">
                        { data.name }
                      </div>

                      {
                        can_access_user_add_invite &&
                        <div className="workspace-users-popup__email text-truncate">
                          { data.email }
                        </div>
                      }
                    </div>
                  </Col>
                  <Col className="position-relative" lg={4}>
                    {
                      data.is_admin ?
                        data?.roles?.map((role, index) => (
                          <div key={index} className="form-control d-flex align-items-center justify-content-center admin_role">
                            {role}
                          </div>
                        ))
                      :
                        <RoleUpdateForm
                          workspaceId={workspaceId}
                          roles={data && data.roles}
                          user_id = {data.id}
                        />
                    }

                    { can_invite_collaborator &&
                      <div className="workspace-users-popup__delete-user">
                        { !data.user_roles.length > 0 &&
                          <Button
                            variant="transparent"
                            onClick={() => toggleUserDestroyPopUp(data.id)}
                            size="sm"
                            title="Remove User"
                            className="d-flex align-items-center"
                          >
                            <img src={DeleteIcon} height="14" width="14" />
                          </Button>
                        }
                      </div>
                    }
                  </Col>
                </Row>
              ))
            :
              <Row>
                <Col sm={12}>
                  <div className="text-center py-3">There are no users yet.</div>
                </Col>
              </Row>
          }

          { can_access_user_add_invite && listInviteUsers && listInviteUsers.length > 0
              ?
              listInviteUsers.filter(d => d.status === "Invited").map((data, index) => (
                <Row key={"listInviteUsers_"+index}>

                  <Col lg={8} className={'d-flex align-items-center'}>
                    <div className="avatar_container" />
                    <div className="p-1 text-truncate">
                      <div className="workspace-users-popup__pending-invitation-label"
                           onClick={() => resentInviteUser(data.id, "notification")}>
                        Resend invitation
                      </div>

                      <div className={`workspace-users-popup__email text-truncate`}>
                        { data.email }
                      </div>
                    </div>
                  </Col>


                  <Col lg={4} className="position-relative">
                    <RoleUpdateForm
                      workspaceId={workspaceId}
                      roles={data && data.invited_workspace_users}
                      user_id={data.id}
                      userType={true}
                    />

                    { can_invite_collaborator &&
                      <div className="workspace-users-popup__delete-user">
                        <Button
                          variant="transparent"
                          onClick={() => handleShow(data.id)}
                          size="sm"
                          title="Remove User"
                          className="d-flex align-items-center"
                        >
                          <img src={DeleteIcon} height="14" width="14" />
                        </Button>
                      </div>
                    }
                  </Col>
                </Row>
                ))
              :
              <Row>
                <Col sm={12}>
                  <div className="text-center py-3"> There are no pending invitations.</div>
                </Col>
              </Row>
            }
        </div>

        {popup.show &&
          <Popup
            display_text={ popUpConstants.INVITED_USER_DELETE }
            delete_btn_text={'Yes'}
            close_btn_text={'Cancel'}
            handleClose={handleClose}
            handleDelete={handleDelete}
          />
        }

        {userDestroyPopUp.show &&
          <Popup
            display_text={
              `Remove user from workspace?`
            }
            delete_btn_text={'Yes'}
            close_btn_text={'Cancel'}
            handleClose={toggleUserDestroyPopUp}
            handleDelete={handleUserDestroy}
          />
        }

      </Modal.Body>
    </Modal>
  );
}


const mapStateToProps = (state) => {
  return {
    can_invite_collaborator: state.workspaceUser.can_invite_collaborator,
    listWorkspaceUser: state.workspaceUser.listWorkspaceUser,
    listInviteUsers: state.workspaceUser.listInviteUsers,
    current_organization: state.organization.current_organization,
    remaining_users: state.organization.remaining_users,
    ignore_limit: state.organization.ignore_limit,
    can_access_user_add_invite: state.workspaceUser.can_access_user_add_invite
  };
};

export default connect(mapStateToProps, { workspace_userActions })(CollaboratorList);
