import { useState, useRef } from 'react';
import { ThumbsUpIcon } from '../../components';

function ProgressIcon(props) {
  const {
    content_group
  } = props;

  return (
    <>
      { !content_group.stage.approved ?
          content_group.stage.status == "pending" ?
            <span className="content_group_icon_status-icon rejected"><i className="fa fa-thumbs-o-down text-white" aria-hidden="true"></i></span>
          :
            <span className="content_group_icon_status-icon submitted"><i className="fa fa-check text-white" aria-hidden="true"></i></span>
        :
          <ThumbsUpIcon color={'#2ECDAB'} height={"16"} width={"16"}/>
      }
    </>
  );
}

export { ProgressIcon };
