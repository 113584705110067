import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { userActions, alertActions } from '../../../actions';
import { validation } from '../../../shared/form-validations';
import { textInput } from '../../../shared/form-elements'
import { reduxForm, Field, reset } from "redux-form";
import { Button } from 'react-bootstrap';
import { LoginHeader } from '../LayoutPage/loginHeader';
import { Image, isLoggedIn } from '../../../utils';
import { AlertText } from '../../../components/';
import { history } from '../../../helpers';
import { userConstants } from '../../../constants';
import { Helmet } from "react-helmet";

function PasswordNew(props) {
  const { handleSubmit} = props
  const dispatch = useDispatch();
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if(isLoggedIn()){
      history.push('/');
      dispatch(alertActions.error(userConstants.LOGIN_ALREADY));
    }else{
      dispatch(userActions.logout());
    }
  }, []);

  const formSubmit = (values) => {
    const successCallback = () => {
      dispatch(reset('passwordNewForm'));
      setSubmitted(true);
    }

    dispatch(userActions.newPassword(values, successCallback));
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className="login-page full_height row no-gutters">
        <div className="col-md-8 login-page__info d-sm-none d-md-block"
             style={{ backgroundImage: 'url("' + Image.Layout.LoginBackground.default + '")' }}/ >
        <div className="login-page__box d-flex justify-content-center align-items-center col-12 col-md-4">
          <div
            className="col-md-10"
          >
            <LoginHeader />

            <h3 className="font-weight-bold text-center mb-4">Forgot Password</h3>

            {
              submitted ?
                <div
                  className={'login_message text-center success my-3'}
                >
                  Check your email for instructions.
                </div>
              :
                <>
                  <form
                    onSubmit={handleSubmit(formSubmit)}
                    className="login-page__form"
                  >
                    <div className="mb-3">
                      <Field name="email" type="email" component={textInput} label="Email Address"/>
                    </div>
                    <div className="mb-4 my-3">
                      <div className="my-2 mt-4">
                        <AlertText />
                      </div>
                      <Button variant="brand" type="submit" className="full-width">Request Password Reset</Button>
                    </div>
                  </form>

                  <hr />
                </>
            }
            <div className="mt-4">
              <ul
                className="login-form__button-list"
              >
                <li
                  className="mb-2"
                >
                  <Link to="/login">Back to log in</Link>
                </li>

                { !submitted && <li><Link to="/register">Create an account</Link></li> }
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

PasswordNew =  reduxForm({
  form: 'passwordNewForm',
  validate: validation,
  touchOnBlur: false
})(PasswordNew);


const mapStateToProps = (state) => {
  return { email: ""  };
};

export default connect(mapStateToProps, { userActions })(PasswordNew);
