import React, { useState } from "react";

export const PasswordInput = ({
  input,
  placeholder,
  label,
  defaultValue,
  id,
  autoFocus,
  meta: { touched, error },
  className,
  style,
  ...rest
}) => {
  const [inputType, setInputType] = useState('password');

  function toggleType() {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }

  return (
    <div className={className} style={style} id={touched && error ? "input_validaion": null}>
      { label ?  <label className="mb-1" style={{ fontSize: "14px" }}> {label}</label> : null}
      <div className="password-input">
        <input
          {...input}
          type={inputType}
          id={id}
          placeholder={placeholder}
          className={rest.input_class_name ? rest.input_class_name : "form-control"}
          autoFocus={autoFocus}
        />
        <span className="password-type-option" onClick={toggleType}>
          {
            inputType === 'password' ?
              <i className="fa fa-eye"></i>
             :
              <i className="fa fa-eye-slash"></i>
          }
        </span>
      </div>
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export default PasswordInput;
