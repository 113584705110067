function CloseIcon(props) {
  const { className, color } = props

  return (
    <svg
      style={{pointerEvents: 'none'}}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
    	viewBox="0 0 24 24"
      xmlSpace="preserve"
      fill={color}
    >
      <path d="M18.3,5.71c-0.39-0.39-1.02-0.39-1.41,0L12,10.59L7.11,5.7c-0.39-0.39-1.02-0.39-1.41,0s-0.39,1.02,0,1.41L10.59,12
    	 L5.7,16.89c-0.39,0.39-0.39,1.02,0,1.41s1.02,0.39,1.41,0L12,13.41l4.89,4.89c0.39,0.39,1.02,0.39,1.41,0s0.39-1.02,0-1.41L13.41,12
    	  l4.89-4.89C18.68,6.73,18.68,6.09,18.3,5.71z"
      />
    </svg>

  );
}

export { CloseIcon };
