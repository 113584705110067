import React from "react";

export const textInput = ({
  input,
  type,
  placeholder,
  label,
  defaultValue,
  id,
  autoFocus,
  meta: { touched, error },
  className,
  style,
  ...rest
}) => {
  let add_validation_id = ''
  if(className === "empty-content_container"){
   add_validation_id = touched && error ? "input_validaion_border": null
  }else {
    add_validation_id = touched && error ? "input_validaion": null
  } 

  return (
    <div className={className} style={style} id={add_validation_id}>
      { label ?  <label className="mb-1" style={{ fontSize: "14px" }}> {label}</label> : null}
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        id={id}
        className={rest.input_class_name ? rest.input_class_name : "form-control"}
        autoFocus={autoFocus}
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export default textInput;
