import React, { useEffect } from 'react';

class Alert extends React.Component {
  static get FadeTimeoutMs() { return 5000 }
  static get FadeDurationMs() { return 3000 }

  constructor(props) {
    super(props)

    this.state = { hide: false }
    this.timeouts = []
  }

  clearTimeouts() {
    this.timeouts.forEach((info, i) => {
      let timeout = info[0]
      let type = info[1]

      this.timeouts = this.timeouts.filter((item) => { return item !== info })

      clearTimeout(timeout)
    })
  }

  render() {
    const { type, message, clearAlert } = this.props

    console.debug(type, message)

    const showAlert = () => {
      this.state = { hide: false }
    }

    const closeAlert = () => {
      this.state = { hide: true }
      this.timeouts.push(setTimeout(clearAlert, 0))
    }

    const removeAlert = () => {
      this.state = { hide: true }
      this.timeouts.push(setTimeout(clearAlert, Alert.FadeDurationMs))
    }

    if (!message || !type) {
      return null
    }

    if (message != true) {
      this.persistedMessage = message
    }

    showAlert()

    this.clearTimeouts()
    this.timeouts.push(setTimeout(removeAlert, Alert.FadeTimeoutMs));

    return (
      <div
        className={ "container alert-container " + ( this.state.hide ? "fade" : "" ) }
        style={{zIndex: 1025}}
      >
        <div
          className={ `alert ${type}` }
        >
          { this.persistedMessage }
          <button type="button" className="btn-close" onClick={closeAlert}></button>
        </div>
      </div>
    )
  }
}

export { Alert };
