function InfoIcon(props) {
  const { height, width, color } = props

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 14 14"
      height={height ? height : "24px"}
      width={width ? width : "24px"}
      fill={color ? color : "#5f6368" }
    >
      <path class="st0" d="M7,10.49c.2,0,.37-.07.5-.2.14-.14.21-.3.21-.5v-2.93c0-.2-.07-.37-.2-.5-.13-.14-.3-.2-.5-.2s-.37.07-.5.2c-.14.14-.21.3-.21.5v2.93c0,.2.07.37.2.5.13.14.3.2.5.2ZM7,4.94c.21,0,.38-.07.52-.21.14-.14.21-.31.21-.52s-.07-.38-.21-.52c-.14-.14-.31-.21-.52-.21s-.38.07-.52.21c-.14.14-.21.31-.21.52,0,.21.07.38.21.52.14.14.31.21.52.21ZM7,14c-.97,0-1.87-.18-2.72-.55-.85-.36-1.59-.87-2.23-1.5-.64-.64-1.14-1.38-1.5-2.23-.36-.85-.55-1.76-.55-2.73s.18-1.88.55-2.72c.36-.84.87-1.59,1.5-2.22.64-.64,1.38-1.14,2.23-1.5.85-.36,1.76-.55,2.73-.55s1.88.18,2.72.55c.84.36,1.59.87,2.22,1.5.64.64,1.14,1.38,1.5,2.23s.55,1.75.55,2.72-.18,1.87-.55,2.72c-.36.85-.87,1.59-1.5,2.23-.64.64-1.38,1.14-2.23,1.5-.85.36-1.75.55-2.72.55ZM7,12.52c1.54,0,2.84-.54,3.91-1.61,1.07-1.07,1.61-2.38,1.61-3.91s-.54-2.84-1.61-3.91c-1.07-1.07-2.38-1.61-3.91-1.61s-2.84.54-3.91,1.61c-1.07,1.07-1.61,2.38-1.61,3.91,0,1.54.54,2.84,1.61,3.91,1.07,1.07,2.38,1.61,3.91,1.61Z"/>
    </svg>
  );
}

export { InfoIcon };
