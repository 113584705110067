import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function stage(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.STAGE_LOADING: {
      return {
        ...state,
        stage_loading: true
      };
    }

    case userConstants.STAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        stage_loading: false,
        error:action.payload
      };
    }

    case userConstants.STAGE_REQUEST: {
      return {
        ...state,
        loading: false,
        stage_loading: false,
      };
    }

    case userConstants.GET_STAGE:{
      return{
        ...state,
        stages: action.payload.data.data,
        currentWorkspace: action.payload.data.workspace,
        loading: false,
        stage_loading: false,
      }
    }

    case userConstants.NEW_STAGE:{
      return{
        ...state,
        currentWorkspace: action.payload.data.workspace,
        loading: false,
        stage_loading: false,
      }
    }

    case userConstants.EDIT_STAGE:{
      return {
        ...state,
        editStage: action.payload.data,
        currentWorkspace: action.payload.data.workspace,
        can_access_approval_request: action.payload.data.can_access_approval_request,
        loading: false,
        stage_loading: false,
      }
    }

    case userConstants.GET_STAGE_LIST_INITIAL_DATA:{
      return {
        ...state,
        loading: false,
        stage_loading: false
      }
    }

    case userConstants.GET_CALENDAR_DATA: {
      return {
        ...state,
        loading: false,
        schedule: action.payload.data.content_groups
      }
    }

    case userConstants.STAGES_TO_UPDATE:{
      return {
        ...state,
        loading: false,
        stage_loading: false,
        stages_to_update: action.payload.data.stages
      }
    }

    case userConstants.DELETE_STAGE:{
      let id = action.payload.data.data.id
      return {
        ...state,
        stages: state.stages && state.stages.filter(item => item.id !== id),
        loading: false,
        stage_loading: false,
      }
    }
  default:
    return state
  }
}
