import React from "react";

export const hiddenField = ({
  input,
  ...rest
}) => {
  return (
    <input
      {...input}
      type="hidden"
    />
  );
};

export default hiddenField;
