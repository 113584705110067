import { alertActions, commentActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETBlobRequest, makeDELETERequest } from '../utils/Axios';
import { reset } from "redux-form";

export const commentAttachmentActions = {
  downloadCommentAttachment,
  destroyCommentAttachment
};

export function downloadCommentAttachment(workspace_id, content_group_id, comment_id, attachment_id, fileName) {
  return function (dispatch) {
    try{
      makeGETBlobRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/comments/${comment_id}/attachments/${attachment_id}`)
      .then(response => {
        if(response.status == 200) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
                new Blob([response.data], {
                  encoding: 'UTF-8'
                }),
                fileName
              );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                encoding: 'UTF-8'
              })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }else{
          dispatch(alertActions.error(response.data.error))
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.COMMENT_ATTACHMENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyCommentAttachment(workspace_id, content_group_id, comment_id, attachment_id, activeTab) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/comments/${comment_id}/attachments/${attachment_id}`)
      .then(response => {
        if(response.status == 200) {
          dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, false, true));
        }else{
          dispatch(alertActions.error(response.data.error))
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.COMMENT_ATTACHMENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
