import { useState, useRef, useEffect, useCallback } from 'react';

function OrganizationInlineEdit(props) {
  const { organization_title, organization_id, handleInlineEdit } = props;
  const [ title, setTitle] = useState(organization_title);
  const [ editMode, setEditMode ] = useState(false);
  const input = useRef();

  useEffect(() => {
    setTitle(organization_title)
  }, [organization_title]);

  const handleTitleClick = useCallback(() => {
    setEditMode(true) ;
    setTimeout(() => {
      input.current.focus();
      input.current.select();
    }, 50);
  }, [input]);

  const onKeyDown = (event) => {
    if (event.key === "Escape") {
      setTitle(organization_title);
      setEditMode(false);
    }

    if (event.key === "Enter") {
      event.target.blur();
    }

    event.stopPropagation();
  }

  const handleInputOnChange = (event) => setTitle(event.target.value);

  const handleOnBlurInput = (e) => {
    if(e.target.value != '' && e.target.value != organization_title) {
      handleInlineEdit(organization_id, {name: e.target.value})
      setEditMode(false)
    }else {
      setTitle(organization_title)
      setEditMode(false)
    }
  }

  return (
    <>
      { editMode === false ?
          <h2
            className="organization_title font-weight-bold text-truncate mb-0"
            onClick={handleTitleClick}
          >
            {title}
          </h2>
        :
          <input
            ref={input}
            type="text"
            value={title}
            onKeyDown={onKeyDown}
            onChange={handleInputOnChange}
            placeholder="..."
            onBlur={handleOnBlurInput}
            className="inline_edit_input organization_title font-weight-bold w-100"
          />
      }
    </>
  );
}

export { OrganizationInlineEdit };
