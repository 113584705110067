import React, {useState, useEffect} from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { userConstants } from '../../../constants';
import { chatActions } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function ActionCable(props) {
  const dispatch = useDispatch();
  let { workspace_id } = useParams();
  const user_id = useSelector(state => state.dashboard.currentUser);
  
  const handleReceivedLists=(res)=> {
    dispatch({type: userConstants.MESSAGE_COUNT, payload: res.data})
  }
  return (
    <ActionCableConsumer
      key={user_id && user_id.id }
      channel={{ channel: "MessageCountChannel", user_id: user_id && user_id.id }}
      onConnected={() => "Connected to server."}
      onReceived={handleReceivedLists} 
    />
  );
}

export default ActionCable