import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './helpers';
import { App } from './App';
import 'font-awesome/css/font-awesome.min.css';
import { ActionCableProvider } from 'react-actioncable-provider';
import { GoogleOAuthProvider } from '@react-oauth/google';

render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <ActionCableProvider url={process.env.REACT_APP_CABLE_URL}>
  	  <Provider store={store}>
  	    <App />
  	  </Provider>
  	</ActionCableProvider>
  </GoogleOAuthProvider>,
  document.getElementById('app')
);
