import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect,  useDispatch, useSelector } from 'react-redux';
import { dashboard_userActions } from '../../../actions';

function HomePage(props) {
  const { dashboardData } = props
  const isUserRole = localStorage.getItem('user_role')
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboard_userActions.DashboardnitialData());
    dispatch(dashboard_userActions.CommonInitialData())
  }, []);

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <div className="mt-0 container-fluid">
      <h5 className="mb-3">Dashboard</h5>
      <div className="row">
        <div className="col-xl-4 col-md-6 mb-4">
          <Link to={'/'} className="widget_a">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs text-uppercase mb-1">Total Workspaces</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboardData && dashboardData.workspace_count}</div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        { isUserRole === "admin" ?
            <div className="col-xl-4 col-md-6 mb-4">
              <Link to="/admin/users" className="widget_a">
                <div className="card border-left-info shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs text-uppercase mb-1">Total Users</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboardData && dashboardData.user_count}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          : null
        }
      </div>
     </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.dashboard.loading,
    dashboardData: state.dashboard.dashboardData
  };
};

export default connect(mapStateToProps, { dashboard_userActions })(HomePage);
