const workspaceUserValidaton = (values, props) => {
  const errors = {};
  const {
    remaining_users,
    usersList,
    current_organization,
    ignore_limit
  } = props;

  if(!values.user_roles){
    errors.user_roles = 'required'
  }

  if (!values.invited_users || !values.invited_users.length) {
    errors.invited_users = { _error: 'At least one member must be entered' };
  } else {
    const usersArrayErrors = [];

    values.invited_users.forEach((user, userIndex) => {
      const usersErrors = {};

      if (!user || !user.first_name) {
        usersErrors.first_name = 'Required';
        usersArrayErrors[userIndex] = usersErrors;
      }

      if (!user || !user.email) {
        usersErrors.email = 'required';
        usersArrayErrors[userIndex] = usersErrors;
      } else if (
        user.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)
      ) {
        usersErrors.email = 'requires valid email';
        usersArrayErrors[userIndex] = usersErrors;
      }

      if (
          user &&
          user.email &&
          usersList &&
          current_organization &&
          !current_organization.personal &&
          !ignore_limit
      ){
        const isInvited = usersList?.filter((e) => e.email === user.email).length > 0

        if (!isInvited && (userIndex + 1 > remaining_users)){
          usersErrors.email = 'no seats available for new users';
          usersArrayErrors[userIndex] = usersErrors;
        }
      }

      if (!user || !user.workspace_role_id) {
        usersErrors.workspace_role_id = 'Required';
        usersArrayErrors[userIndex] = usersErrors;
      }
    });

    if (usersArrayErrors.length) {
      errors.invited_users = usersArrayErrors;
    }
  }


  if (!values.workspace_users || !values.workspace_users.length) {
    errors.workspace_users = { _error: 'At least one member must be entered' };
  } else {
    const workspace_usersArrayErrors = [];
    values.workspace_users.forEach((w_user, w_userIndex) => {
      const workspace_usersErrors = {};
      if (!w_user || !w_user.user_id) {
        workspace_usersErrors.user_id = 'Required';
        workspace_usersArrayErrors[w_userIndex] =  workspace_usersErrors;
      }
      if (!w_user || !w_user.role_id) {
        workspace_usersErrors.role_id = 'Required';
        workspace_usersArrayErrors[w_userIndex] =  workspace_usersErrors;
      }
    });

    if ( workspace_usersArrayErrors.length) {
      errors.workspace_users =  workspace_usersArrayErrors;
    }
  }

  return errors;
};

export default workspaceUserValidaton;
