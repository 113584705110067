import React, { useEffect } from "react";
import "./contentForm.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { contentActions } from '../../../actions';
import { Button, Row, Col, Card } from "react-bootstrap";
import { LoadSpinner } from '../../../components';

function ContentVersionShow(props) {
  const { workspace_id, content_group_id, content_id, version_id } = useParams();
  const { content, workspace, content_group, version } = props
  const dispatch = useDispatch();
  const content_attachment = version && version.content_attachment;

  useEffect(() => {
    dispatch(contentActions.showVersionContent(workspace_id, content_group_id, content_id, version_id));
  }, []);

  return (
    <div className="container-fluid">
      {props.loading && <LoadSpinner />}
      <div className="col-lg-6 pl-0">
        {content ?
          <div style={{ marginRight:0, marginLeft:0 }}>
            <Col xs={12} className="pl-0">
              <div>
                <Card style={{ marginBottom: "2rem" }}>
                  {content_attachment ?
                      <Card.Img variant="top" src={content_attachment.file.url}></Card.Img>
                    :
                      null
                  }
                </Card>
              </div>
            </Col>
            <Col xs={12} className="pl-0">
            </Col>
          </div>
          :
            <div className="centered">
              <h2>No Content</h2>
              <p>to preview.</p>
            </div>
          }
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.content.loading,
    content: state.content.currentContent,
    workspace: state.content.currentWorkSpace,
    content_group: state.content.currentContentGroup,
    version: state.content.currentVersion
  };
};

export default connect(mapStateToProps, { contentActions })(ContentVersionShow);
