import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function AdminRoute({ component: Component, roles, adminOnly, ...rest }) {
    const isUserLoggedIn = localStorage.getItem('user') ? true : false;
    const roleName = localStorage.getItem('user_role')
    console.log("&& adminOnly: ", roleName, adminOnly)
    return (
        <Route {...rest} render={props => {
            if (!localStorage.getItem('user')) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            if (roleName !== 'admin') {
                // not admin in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { AdminRoute };
