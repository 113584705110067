export function chatReset() {
  const message_element = document.getElementById("hubspot-messages-iframe-container");

  console.log("Executing chatReset()");

  // Test function call count value
  setTimeout(function(){
    if(message_element) message_element.style.visibility="hidden";

    console.log("Reset Chat Widget");
    window.HubSpotConversations.clear({resetWidget:true});

    // Makes HubSpot iframe container visible with a delay
    setTimeout(function(){
      if(message_element) message_element.style.visibility="visible";
    }, 500);
  }, 500);
}
