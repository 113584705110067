import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch} from 'react-redux';
import { userActions, alertActions } from '../../../actions';
import { validation } from '../../../shared/form-validations';
import { textInput, PasswordInput } from '../../../shared/form-elements'
import { reduxForm, Field, change } from "redux-form";
import { Button } from 'react-bootstrap';
import { LoginHeader } from '../LayoutPage/loginHeader';
import { Image, isLoggedIn } from '../../../utils';
import { AlertText } from '../../../components';
import { history } from '../../../helpers';
import { userConstants } from '../../../constants';
import { Helmet } from "react-helmet";

function RregisterForm(props) {

  const { handleSubmit } = props
  const dispatch = useDispatch();
  const query = new URLSearchParams(props.location.search.replace(/&amp;/g, '&').replace(/%40/g, '@'));
  const [ submitted, setSubmitted ] = useState(false);
  let invited_user_id = query.get('invite_id');
  // reset login status
  useEffect(() => {
    if(isLoggedIn()){
      history.push('/');
      dispatch(alertActions.error(userConstants.LOGIN_ALREADY));
    }else{
      dispatch(userActions.logout());
      dispatch(userActions.showInvitation(invited_user_id));
    }
  }, []);

  const registerSubmit = (values) => {
    const successCallback = () => {
      setSubmitted(true)
    }

    dispatch(userActions.register(values, successCallback));
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Free forever. Get more things done, less time wasted."
        />
      </Helmet>
      <main className="login-page full_height row no-gutters">
        <div className="col-md-8 login-page__info d-sm-none d-md-block"
             style={{ backgroundImage: 'url("' + Image.Layout.LoginBackground.default + '")' }}/ >
        <div className="login-page__box d-flex justify-content-center align-items-center col-12 col-md-4">
          <div
            className="col-md-10 h-100 d-flex align-items-center"
          >
            <div className="w-100 vertical-scroll_thin mh-100">
              <LoginHeader />

              <h3 className="font-weight-bold text-center mb-4">Create Account</h3>

              {
                submitted ?
                  <div
                    className={'login_message text-center success my-3'}
                  >
                    {
                      invited_user_id ?
                        'You may now log in.'
                      :
                        'Check your email for confirmation.'
                    }

                  </div>
                :
                  <>
                    <form
                      onSubmit={handleSubmit(registerSubmit)}
                      className={'login-page__form'}
                      autocomplete="off"
                    >
                      <Field name="invite_id" type="hidden" component={textInput} autocomplete="off" />

                      <div className="mb-2">
                        <Field name="first_name" type="text" component={textInput} label="First Name" autocomplete="off" />
                      </div>

                      <div className="mb-2">
                        <Field name="last_name" type="text" component={textInput} label="Last Name" autocomplete="off" />
                      </div>

                      { !invited_user_id && <div>

                        <div className="mb-2">
                          <Field name="email" type="email" component={textInput} label="Email Address" />
                        </div>
                      </div> }

                      <div className="mb-3">
                        <Field id="password" name="password" type="password" component={PasswordInput} label="Password" autocomplete="new-password" />
                      </div>

                      <div className="mb-3">
                        <Field id="password_confirmation" name="password_confirmation" type="password" component={PasswordInput} label="Confirm Password" autocomplete="new-password" />
                      </div>


                      <div className="mb-3 mt-4">
                        <div className="my-2 mt-4">
                          <AlertText />
                        </div>
                        <Button variant="brand" type="submit" className="full-width">Create account</Button>
                      </div>
                    </form>

                    <hr/>
                  </>
              }

              <div className="text-center">
                <ul className="login-form__button-list">
                  <li><Link to="/login">Back to log in</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

RregisterForm =  reduxForm({
  form: 'registerfom',
  validate: validation,
  enableReinitialize: true,
  touchOnBlur: false
})(RregisterForm);


const mapStateToProps = (state) => {
  const initialData = state.registration?.invited_user_info;
  return {
    user: state.authentication.user,
    initialValues: {
      invite_id: initialData?.token,
      first_name: initialData?.first_name,
      last_name: initialData?.last_name,
      email: initialData?.email,
      password: '',
      password_confirmation: ''
    }
  };
};

export default connect(mapStateToProps, { userActions })(RregisterForm);
