import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { validation } from '../../../shared/form-validations';
import { textInput, selectField } from '../../../shared/form-elements'
import { reduxForm, Field, reset, change } from "redux-form";
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';

function StageForm(props) {
  const { handleSubmit, newForm , editStage, goupId, stage_error, handleCloseForm, request_approval} = props
  const dispatch = useDispatch();

  useEffect(() => {
    if(newForm === "newForm") {
      dispatch(change("stageform", "name", ''))
      dispatch(change("stageform", "workspace_id", goupId));
      dispatch(change("stageform", "visible_to_client", false))
      dispatch(change("stageform", "require_approval_from_client", false));
      dispatch(change("stageform", "enable_timestamp", false));
      dispatch(change("stageform", "enable_revision", false));
    }
    document.getElementById("input_foucus").focus();
  }, []);


  return (
    <Modal
      show={true}
      onHide={handleCloseForm}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          { newForm == "newForm" ? "Add Stage" : "Edit Stage" }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {stage_error.error && stage_error.error.map((data, index) => (
            <div className="error_message" key={index}>{data}</div>
          ))}
          <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
              <Field name="workspace_id" type="hidden" component={textInput}/>
              <div className="mb-2">
                <Field name="name" type="text" component={textInput} label="Title" id="input_foucus"/>
              </div>
              {request_approval && (
                <>
                  <div className="mb-4">
                    <div className="form-check form-switch">
                      <Field
                        name="visible_to_client"
                        type="checkbox"
                        component="input"
                        className="form-check-input"
                      />
                      <label className="form-check-label mt-0">Visible to Partner</label>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="form-check form-switch">

                      <Field
                        name="enable_revision"
                        type="checkbox"
                        component="input"
                        className="form-check-input"
                      />
                      <label className="form-check-label mt-0">Revision Count</label>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="form-check form-switch">

                      <Field
                        name="require_approval_from_client"
                        type="checkbox"
                        component="input"
                        className="form-check-input"
                      />
                      <label className="form-check-label mt-0">Requires Approval</label>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="form-check form-switch">

                      <Field
                        name="enable_timestamp"
                        type="checkbox"
                        component="input"
                        className="form-check-input"
                      />
                      <label className="form-check-label mt-0">Enable Timestamp</label>
                    </div>
                  </div>
                </>

              )}
              <div className="form-buttons">
                <Button onClick={handleCloseForm} variant="default" style={{marginRight: 20}}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  {
                    newForm ?
                      'Add'
                    :
                      'Submit'
                  }
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
}

StageForm =  reduxForm({
  form: 'stageform',
  validate: validation,
  enableReinitialize: true
})(StageForm);

const mapStateToProps = (state) => {
  return {
    loading: state.stage.loading,
    error: state.stage.error,
    editStage: state.stage.editStage,
    can_access_approval_request: state.stage.can_access_approval_request,
    initialValues: {
      name: state.stage.editStage && state.stage.editStage.data && state.stage.editStage.data.name,
      visible_to_client: state.stage.editStage && state.stage.editStage.data && state.stage.editStage.data.visible_to_client,
      workspace_id: state.stage.editStage && state.stage.editStage.data && state.stage.editStage.data.workspace_id,
      require_approval_from_client: state.stage.editStage && state.stage.editStage.data && state.stage.editStage.data.require_approval_from_client,
      enable_timestamp: state.stage.editStage?.data?.enable_timestamp,
      enable_revision: state.stage.editStage?.data?.enable_revision
    }
  };
};

export default connect(mapStateToProps,{})(StageForm);
