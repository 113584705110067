import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { user_profileActions } from '../../../actions';
import { validation } from '../../../shared/form-validations';
import { textInput, PasswordInput, CheckboxGroup, fileUpload } from '../../../shared/form-elements'
import { reduxForm, Field, reset, change } from "redux-form";
import { buildFormDataFromObject } from '../../../utils/commonUtils';
import { Button } from 'react-bootstrap';

function UserPasswordForm(props) {
  const { handleSubmit , editUserPassword, handleCloseUserProfileForm} = props
  const user_error = useSelector(state => state.user_profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(change("user_passwordform", "password", ''));
    dispatch(change("user_passwordform", "current_password", ''));
    dispatch(change("user_passwordform", "confirmation_password", ''));
  }, []);

  const onSubmit = (values) => {
    let formData = new FormData();
    buildFormDataFromObject(formData, values, "registration");

    dispatch(user_profileActions.updateUserPassword(formData, handleCloseUserProfileForm));
  }

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="mb-2">Edit Password</h4>

        <div className="col-md-12 col-lg-12">
          <div className="mb-2 validation_div">
            <Field name="current_password" type="password" component={PasswordInput} label="Current Password"/>
          </div>
          <div className="mb-2 validation_div">
            <Field name="password" type="password" component={PasswordInput} label="New Password"/>
          </div>

          <div className="mb-2 validation_div">
            <Field name="confirmation_password" type="password" component={PasswordInput} label="Confirm New Password"/>
          </div>

          <div className="mb-3 mt-4">
            <Button variant="primary" type="submit">Change Password</Button>
          </div>
        </div>
      </form>
    </div>
  );
}

UserPasswordForm =  reduxForm({
  form: 'user_passwordform',
  validate: validation,
  enableReinitialize: true
})(UserPasswordForm);

const mapStateToProps = (state) => {
  return {
    loading: state.user_profile.loading,
    error: state.user_profile.error,
    editUserProfile: state.user_profile.editUserPassword,
    initialValues: {
      password: "",
      current_password: "",
      confirmation_password: ""
    }
  };
};

export default connect(mapStateToProps, { user_profileActions })(UserPasswordForm);
