import { contentConstants } from '../constants';

export function checkImagesSize(images, is_workspace_personal) {
  const uploaded = [];
  let limitExceeded = false;
  const { IMAGE_MAX_SIZE,  BUSINESS_IMAGE_MAX_SIZE } = contentConstants;
  const file_limit = is_workspace_personal ? IMAGE_MAX_SIZE : BUSINESS_IMAGE_MAX_SIZE;

  images.some((file) => {
      if (file.name.match(/\.(jpg|jpeg|png|gif|webp)$/i)){
        if (file.size > file_limit){
          limitExceeded = true;
        }
      } else {
        alert(`File selected is not an image`)
      }
  });

  if(limitExceeded){
    alert(`Maximum file size is ${is_workspace_personal ? '5' : '10'}MB.`);
  }

  return limitExceeded
}
