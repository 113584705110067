import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../../../actions';
import { history } from '../../../helpers';
import { isLoggedIn } from '../../../utils';
import { userConstants } from '../../../constants';

function ConfirmPage(props) {

  const dispatch = useDispatch();
  const query = new URLSearchParams(props.location.search);
  const token = query.get('unlock_token');

  useEffect(() => {
    if(isLoggedIn()){
      history.push('/');
      dispatch(alertActions.error(userConstants.LOGIN_ALREADY));
    }else{
      dispatch(userActions.unlockAccount({unlock_token: token}));
    }
  }, []);

  return (
    <main className="d-flex align-items-center justify-content-center">

    </main>
  );
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, { userActions })(ConfirmPage);
