import { alertActions, dashboard_userActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePOSTRequest, makePUTRequest, makeDELETERequest } from '../utils/Axios';
import { adminBaseURL } from '../utils/commonUtils';

export const workspace_userActions = {
  getWorkspaceData,
  createWorkspaceUser,
  resentInviteUser,
  resentNotification,
  inviteUserDestroy,
  addedUserDestroy,
  acceptInvitation,
  listCollaborators,
  getpendingInvitation,
  acceptAllInvitation,
  updateWorkspaceUserRole,
};

export function getWorkspaceData(workspace_id) {
  return dispatch => {
    try{
      //dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/collaborator_workspace_data`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.WORKSPACE_USER_INITIAL_DATA, payload: response})
        }else {
          dispatch({ type: userConstants.WORKSPACE_USER_INITIAL_FAILURE, payload: response})
        }
      })
    }catch{

    }
  }
}

export function createWorkspaceUser(workspace_id, reqparams, successCallback) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return dispatch => {
    try{
      const id = workspace_id
      makePOSTRequest(`/api/v1/workspaces/${workspace_id}/workspace_users`, reqparams)
      .then(response => {
        if (response.data.status === "ok"){
          dispatch({ type: userConstants.WORKSPACE_USER_NEW, payload: response});
        } else {
          if(response.data.error){
            dispatch({ type: userConstants.WORKSPACE_USER_FAILURE, payload: response.data.message});
            dispatch(alertActions.error(response.data.message));
          } else {
            dispatch({ type: userConstants.WORKSPACE_USER_NEW, payload: response});
            dispatch(listCollaborators(workspace_id));
            dispatch(alertActions.success(response.data.message));

            if (successCallback) {
              successCallback()
            }
          }
        }
      })
    }catch{

    }
  }
}

export function updateWorkspaceUserRole(workspace_id, reqparams, successCallback) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return dispatch => {
    try{
      const id = workspace_id
      makePOSTRequest(`/api/v1/workspaces/${workspace_id}/update_workspace_user_role`, reqparams)
      .then(response => {
        if (response.data.status === "ok"){
          dispatch({ type: userConstants.WORKSPACE_USER_NEW, payload: response});
          dispatch(listCollaborators(workspace_id));
          dispatch(alertActions.success(response.data.message));
          if (successCallback) {
            successCallback()
          }
        }else {
          dispatch({ type: userConstants.WORKSPACE_USER_FAILURE, payload: response.data.message});
        }
      })
    }catch{

    }
  }
}

export function resentInviteUser() {
  // TODO: Remove this
  return dispatch => {
    try{
      //dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/invited_users`)
      .then(response => {
        if (response.data.status === "ok"){
          dispatch({ type: userConstants.RESENT_INVITE_USER, payload: response})
        }
      })
    }catch{

    }
  }
}

export function resentNotification(reqparams) {
  return dispatch => {
    try{
      makePOSTRequest(`/api/v1/resent_notification`, {id: reqparams})
      .then(response => {
        dispatch({ type: userConstants.RESENT_NOTIFICATIONS, payload: response})
        dispatch(alertActions.success(response.data.message));
      })
    }catch{

    }
  }
}

export function inviteUserDestroy(id) {
  return dispatch => {
    try{
      makeDELETERequest(`/api/v1/invited_users/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.INVITE_USER_DESTROY_SUCESS, payload: response})
        }else {
          dispatch({ type: userConstants.INVITE_USER_DESTROY_FAILURE, payload: response})
        }
      })
    }catch{

    }
  }
}

export function addedUserDestroy(workspace_id, id) {
  return dispatch => {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/workspace_users/${id}`)
      .then(response => {
        if(response.data.error){
          dispatch({ type: userConstants.WORKSPACE_USER_DESTROY_SUCCESS, payload: response});
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch(alertActions.success(response.data.message));
          dispatch({ type: userConstants.WORKSPACE_USER_DESTROY_SUCCESS, payload: response})
        }
      })
    }catch{

    }
  }
}

export function listCollaborators(workspace_id) {
  return dispatch => {
    try{
      //dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/collaborators`)
      .then(response => {
        if(response.data.error){
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch({ type: userConstants.LIST_COLLABORATORS, payload: response})
        }
      })
    }catch{

    }
  }
}

export function acceptInvitation(workspace_id, id, status) {
  return dispatch => {
    try{
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/workspace_users/${id}/accept_invitation?status=${status}`)
      .then(response => {
        if(response.data.status == "ok"){
          dispatch({type: userConstants.ACCEPT_INVITATION, payload:response})
          dispatch(alertActions.success(response.data.message));
          dispatch({type: userConstants.PENDING_NOTIFICATION_COUNT, payload: response.data.notification_count})
          dispatch(dashboard_userActions.CommonInitialData());
        }else {
          dispatch(alertActions.error(response.data.error));
        }

      })
    }catch{

    }
  }
}
export function getpendingInvitation() {
  return dispatch => {
    try{
      makeGETRequest(`/api/v1/pending_invitation`)
      .then(response => {
        if(response.data.status == "ok"){
           dispatch({ type: userConstants.PENDING_NOTIFICATION, payload: response})
        }else {
          dispatch(alertActions.error(response.data.error));
        }

      })
    }catch{

    }
  }
}

export function acceptAllInvitation(status) {
  return dispatch => {
    try{
      makeGETRequest(`/api/v1/accept_all_invitation?status=${status}`)
      .then(response => {
        if(response.data.status == "ok"){
          dispatch({ type: userConstants.All_ACCEPT_INVITATION, payload: response})
          dispatch({type: userConstants.PENDING_NOTIFICATION_COUNT, payload: response.data.notification_count})
          dispatch(alertActions.success(response.data.message));

          dispatch(dashboard_userActions.CommonInitialData());
        }else {
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch{

    }
  }
}
