import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { dateTimeFormatter } from '../../utils/';
import { dateTimeConstants } from '../../constants';

const DateTimePickers = ({due_date, dueDateInitialize,  input: { value: omitValue, onChange, onBlur, ...inputProps },meta: omitMeta,touched, error,...props}) => {
  const [value, setValue] = useState( due_date ? new Date(dueDateInitialize) : null);
  const [active, setActive] = useState(false);
  const handleChange = (value) => {
    setValue(value)
    onChange(value)
  }

  const handleClose = () =>{
    setActive(false)
  }

  const handleOpen = () =>{
    setActive(true)
  }

  // TODO: format 'MMM DD, YYYY hh:mma' is not supported by the package
  return (
    <>
      {
        active ?
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              open={ true }
              value={value}
              onAccept={(newValue) => handleChange(newValue)}
              renderInput={(params) => <TextField {...params} />}
              onClose={ handleClose }
              format={dateTimeConstants.DISPLAY_FORMAT}
              slotProps={{
                textField: {
                  InputProps: {}
                },
                actionBar: {
                  actions: ['clear', 'accept']
                }
              }}
            />
          </LocalizationProvider>
        :
          <div
            className="cursor-pointer w-100"
            onClick={handleOpen}
            style={{
              height: '24px'
            }}
          >
            {value ? dateTimeFormatter(value) : '-'}
          </div>
      }
    </>
  )
}

export default DateTimePickers
