function SortIcon(props) {
  const { className, color, height, width } = props

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 14 17.27"
      height={height ? height : "24"}
      width={width ? width : "24"}
      fill={color}
      className={className}
    >
      <path class="st0" d="M4.23,9.75c-.27,0-.49-.09-.68-.28-.19-.18-.28-.41-.28-.68V3.27l-1.63,1.63c-.19.19-.42.29-.68.29s-.48-.1-.67-.29c-.19-.19-.29-.41-.29-.67s.1-.49.29-.68L3.56.27c.09-.09.2-.16.31-.2.12-.05.24-.07.36-.07s.25.02.36.07c.12.05.22.12.3.2l3.26,3.27c.19.19.29.42.29.67,0,.25-.09.47-.29.67-.19.19-.42.28-.68.28-.26,0-.48-.1-.68-.29l-1.63-1.6v5.53c0,.27-.09.49-.28.68-.18.18-.41.28-.68.28ZM9.77,17.27c-.13,0-.25-.02-.36-.07-.12-.05-.22-.12-.3-.2l-3.26-3.27c-.19-.19-.28-.42-.28-.68,0-.26.11-.49.3-.68.19-.19.42-.28.68-.28.26,0,.48.1.68.29l1.6,1.63v-5.53c0-.27.09-.49.28-.68.18-.18.41-.28.68-.28s.49.09.68.28c.19.18.28.41.28.68v5.53l1.63-1.63c.19-.19.42-.29.68-.29s.48.1.67.29c.19.19.29.41.29.67s-.1.49-.29.68l-3.27,3.27c-.09.09-.2.16-.31.2s-.24.07-.36.07Z"/>
    </svg>
  );
}

export { SortIcon };
