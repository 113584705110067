import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../../../actions';
import { history } from '../../../helpers';
import { Link } from 'react-router-dom';
import { LoginHeader } from '../LayoutPage/loginHeader';
import { Image, isLoggedIn } from '../../../utils';
import { userConstants } from '../../../constants';
import { Helmet } from "react-helmet";

function ConfirmPage(props) {
  const dispatch = useDispatch();
  const query = new URLSearchParams(props.location.search);
  const token = query.get('confirmation_token');

  useEffect(() => {
    if(isLoggedIn()){
      history.push('/');
      dispatch(alertActions.error(userConstants.LOGIN_ALREADY));
    }else{
      dispatch(userActions.confirm(token));
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className="login-page full_height row no-gutters">
        <div className="col-md-8 login-page__info"
             style={{ backgroundImage: 'url("' + Image.Layout.LoginBackground.default + '")' }}/ >
        <div className="login-page__box d-flex justify-content-center align-items-center col-12 col-md-4">
          <div
            className="col-md-10"
          >
            <LoginHeader />

            <h3 className="font-weight-bold text-center mb-4">Confirmation</h3>

            <div
              className={'login_message text-center success my-3'}
            >
              Email confirmed successfully. <br/>
              You may now log in.
            </div>

            <hr />
            <div className="text-center">
              <ul className="login-form__button-list">
                <li><Link to="/login">Back to log in</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, { userActions })(ConfirmPage);
