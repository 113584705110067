import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { validation } from '../../../shared/form-validations';
import { textInput, selectField } from '../../../shared/form-elements'
import { reduxForm, Field, reset, change } from "redux-form";
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';

function TemplateStageForm(props) {
  const { handleSubmit, newForm , editTemplateStage, template_stage_error, handleCloseForm, template_stage_loading} = props
  const dispatch = useDispatch();

  useEffect(() => {
    if(newForm === "newForm") {
      dispatch(change("template_stageform", "name", ''))
      dispatch(change("template_stageform", "visible_to_cient", false))
      dispatch(change("template_stageform", "require_approval_from_client", false))
      dispatch(change("template_stageform", "enable_revision", false))
      dispatch(change("template_stageform", "enable_timestamp", false))
    }
  }, []);

  return (
    <Modal
      show={true}
      onHide={handleCloseForm}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          { newForm == "newForm" ? "Add Board Stages" : "Edit Board Stage" }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12 pt-3">
          {template_stage_error.error && template_stage_error.error.map((data, index) => (
            <div className="error_message" key={index}>{data}</div>
          ))}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Field name="name" type="text" component={textInput} label="Name"/>
            </div>

            <div className="mb-3">
              <div className="form-check form-switch">
                <Field
                  name="visible_to_client"
                  type="checkbox"
                  component="input"
                  className="form-check-input"
                />
                <label className="form-check-label">Visible to client</label>
              </div>
            </div>

            <div className="mb-3">
              <div className="form-check form-switch">
                <Field
                  name="enable_revision"
                  type="checkbox"
                  component="input"
                  className="form-check-input"
                />
                <label className="form-check-label">Enable Revision</label>
              </div>
            </div>

            <div className="mb-3">
              <div className="form-check form-switch">
                <Field
                  name="require_approval_from_client"
                  type="checkbox"
                  component="input"
                  className="form-check-input"
                />
                <label className="form-check-label">Requires approval from client</label>
              </div>
            </div>

            <div className="mb-3">
              <div className="form-check form-switch">
                <Field
                  name="enable_timestamp"
                  type="checkbox"
                  component="input"
                  className="form-check-input"
                />
                <label className="form-check-label">Enable Timestamp</label>
              </div>
            </div>

            <div className="form-buttons">
              <Button onClick={handleCloseForm} variant="default" style={{marginRight: 20}}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={template_stage_loading}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

TemplateStageForm =  reduxForm({
  form: 'template_stageform',
  validate: validation,
  enableReinitialize: true
})(TemplateStageForm);

const mapStateToProps = (state) => {
  return {
    loading: state.template_stage.loading,
    template_stage_loading: state.template_stage.template_stage_loading,
    error: state.template_stage.error,
    editTemplateStage: state.template_stage.editTemplateStage,
    initialValues: {
      name: state.template_stage.editTemplateStage && state.template_stage.editTemplateStage.data && state.template_stage.editTemplateStage.data.name,
      visible_to_client: state.template_stage.editTemplateStage && state.template_stage.editTemplateStage.data && state.template_stage.editTemplateStage.data.visible_to_client,
      require_approval_from_client: state.template_stage.editTemplateStage && state.template_stage.editTemplateStage.data && state.template_stage.editTemplateStage.data.require_approval_from_client,
      enable_timestamp: state.template_stage.editTemplateStage?.data?.enable_timestamp,
      enable_revision: state.template_stage.editTemplateStage?.data?.enable_revision
    }
  };
};

export default connect(mapStateToProps,{})(TemplateStageForm);
