function DownArrowIcon(props) {
  const { className, color, height, width } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14.39"
      width={width ? width : "18"}
      height={height ? height : "18"}
      fill={color}
      className={className}
    >
      <path class="st0" d="M5.96,10.83V1.04c0-.29.1-.54.3-.74.2-.2.44-.3.73-.3s.54.1.74.3c.2.2.3.45.3.74v9.79l4.18-4.18c.21-.21.45-.31.73-.31.28,0,.53.1.75.31.2.21.3.46.3.74,0,.28-.11.53-.32.73l-5.96,5.95c-.1.1-.22.18-.34.23-.13.05-.26.08-.39.08-.14,0-.27-.03-.39-.08-.13-.05-.24-.13-.33-.22L.31,8.13C.1,7.91,0,7.67,0,7.39c0-.28.12-.52.32-.73.22-.21.47-.32.75-.32s.53.11.73.32l4.15,4.18Z"/>
    </svg>
  );
}

export { DownArrowIcon };
