import { userConstants } from '../constants';
import { removeNullInArray, addSearchHistory, removeSearchHistory } from '../utils';

const initialState = { loading: false, error: '', current_search_text: null };

export function search_history(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_SEARCH_HISTORIES: {
      let new_search_histories = removeNullInArray(action.payload.data.data)

      return {
        ...state,
        search_histories: new_search_histories
      };
    }

    case userConstants.CREATE_SEARCH_HISTORY: {
      let new_search_histories = state.search_histories ? addSearchHistory(state.search_histories, action.payload.data.search_history): [action.payload.data.search_history]
      new_search_histories = removeNullInArray(new_search_histories)

      return {
        ...state,
        search_histories: new_search_histories,
        current_search_text: action.payload.data.search_history.text
      };
    }

    case userConstants.DELETE_SEARCH_HISTORY: {
      let new_search_histories = state.search_histories ? removeSearchHistory(state.search_histories, action.payload.data.data) : [action.payload.data.search_history]

      return {
        ...state,
        search_histories: new_search_histories
      };
    }

    case userConstants.SEARCH_HISTORY_FAILURE: {
      return {
        ...state
      };
    }


  default:
    return state
  }
}
