import { alertActions, work_spaceActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePOSTRequest, makeDELETERequest, makePUTRequest } from '../utils/Axios';
import { reset } from "redux-form";
import { adminBaseURL } from '../utils/commonUtils';

export const stageActions = {
  createStage,
  getStage,
  editStage,
  destroyStage,
  newStage,
  updateStage,
  getListInitialData,
  getCalendarData,
  listPaginate,
  refreshListPaginate,
  inlineEditStage,
  sortByDueDate
};

export function newStage(workspace_id) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const org_id = localStorage.getItem('org_id');

  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/stages/new`)
      .then(response => {
        if(response.data.error){
          history.push(`/organizations/${org_id}${workspaceBaseUrl}/${workspace_id}/list`);
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch({ type: userConstants.NEW_STAGE, payload: response})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function createStage(reqparams, workspace_id, closeModal, fromKanban) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.STAGE_LOADING });
      makePOSTRequest(`/api/v1/workspaces/${workspace_id}/stages`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          // dispatch({ type: userConstants.STAGE_REQUEST, payload: response})
          dispatch(reset('stageform'));
          closeModal();

          if(fromKanban){
            dispatch({
              type: userConstants.STAGE_CREATE_REQUEST,
              payload: response.data
            })
            // dispatch(work_spaceActions.showWorkSpace(workspace_id, true));
          }
          else{
            dispatch(getStage(workspace_id));
            dispatch(alertActions.success(userConstants.STAGE_REQUEST));
          }
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.STAGE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateStage(reqparams, workspace_id, stage_id, closeModal, fromKanban) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.STAGE_LOADING });
      makePUTRequest(`/api/v1/workspaces/${workspace_id}/stages/${stage_id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(alertActions.success(userConstants.UPDATE_STAGE));
          dispatch({
            type: userConstants.UPDATE_STAGE,
            payload: response
          })
          closeModal();

          if(fromKanban){
            dispatch({
              type: userConstants.STAGE_UPDATE_REQUEST,
              payload: response.data
            })
            // dispatch(work_spaceActions.showWorkSpace(workspace_id, true));
          }
          else
          {
            dispatch(getStage(workspace_id));
          }
        }else{
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.STAGE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getStage(workspace_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/stages`)
      .then(response => {
        dispatch({
          type: userConstants.GET_STAGE,
          payload: response
        })
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editStage(workspace_id, stage_id, openModal) {
  return function (dispatch) {
    try{
      //dispatch({ type: userConstants.STAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/stages/${stage_id}`)
      .then(response => {

        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.EDIT_STAGE,
            payload: response
          })

          openModal({ show: true, id: stage_id });
        }else{
          history.push("/");
          dispatch({ type: userConstants.STAGE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyStage(workspace_id, stage_id) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/stages/${stage_id}`)
      .then(response => {
        if(response.data.status === "ok") {
          dispatch({
            // type: userConstants.DELETE_STAGE,
            type: userConstants.STAGE_DELETE_REQUEST,
            payload: response.data
          })
          dispatch(alertActions.success(userConstants.DELETE_STAGE));
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.STAGE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getListInitialData(workspace_id, stage_id, setContentGroups, page, perPage, setCanCrudContentGroup) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.STAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/stages/${stage_id}/list_data?page=${page}&per_page=${perPage}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.GET_STAGE_LIST_INITIAL_DATA });
          setContentGroups(response.data.content_groups);
          setCanCrudContentGroup(response.data.can_crud_content_group);
        }else {
          history.push('/');
          dispatch({ type: userConstants.STAGE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getCalendarData(workspace_id, date, currentSearchText = '', calendarCallback) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      let search = currentSearchText ? currentSearchText : '*';
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/stages/calendar_data?date=${date}&search=${search}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.GET_CALENDAR_DATA, payload: response})
          calendarCallback(response?.data?.content_groups)
        }else {
          dispatch({ type: userConstants.STAGE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function listPaginate(workspace_id, stage_id, addContentGroups, setLoading, page, perPage, currentSearchText = '') {
  return function (dispatch) {
    try{
      setLoading(true);
      dispatch({ type: userConstants.STAGE_LOADING });
      let search = currentSearchText ? currentSearchText : '*';
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/stages/${stage_id}/list_data?page=${page}&per_page=${perPage}&search=${search}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.GET_STAGE_LIST_INITIAL_DATA });
          addContentGroups(response.data);
        }else {
          history.push('/');
          dispatch({ type: userConstants.STAGE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }

        setLoading(false);
      })
    }catch(e){
      setLoading(false);
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function refreshListPaginate(workspace_id, stage_id, addContentGroups, page, perPage, currentSearchText = '') {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      let search = currentSearchText ? currentSearchText : '*';

      makeGETRequest(`/api/v1/workspaces/${workspace_id}/stages/${stage_id}/list_data?page=${page}&per_page=${perPage}&search=${search}`)
      .then(response => {
        if(response.data.status === "ok"){
          addContentGroups(response.data);
        }else {
          history.push(workspaceBaseUrl);
          dispatch({ type: userConstants.STAGE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function inlineEditStage(handleOnBlur, reqparams, workspaceId, stageId) {
  return function (dispatch) {
    try {
      dispatch({ type: userConstants.STAGE_LOADING });
      makePUTRequest(`/api/v1/workspaces/${workspaceId}/stages/${stageId}`, reqparams)
      .then(response => {
        if (response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_STAGE,
            payload: response
          });
          dispatch({
            type: userConstants.STAGE_UPDATE_REQUEST,
            payload: response.data
          })
          dispatch(alertActions.success(userConstants.UPDATE_STAGE));
          handleOnBlur()
        }else{
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response.data.error})
          handleOnBlur()
        }
      })
    } catch(e) {
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function sortByDueDate(workspace_id, stage_id, sort_type = 'asc') {
  return function (dispatch) {
    try{
      makePUTRequest(`/api/v1/workspaces/${workspace_id}/stages/${stage_id}/sort?sort_type=${sort_type}`)
      .then(response => {
        if (response.data.status === 'ok') {
          dispatch({
            type: userConstants.STAGES_TO_UPDATE,
            payload: response
          });

          dispatch(alertActions.success(userConstants.STAGE_SORT));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.STAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
