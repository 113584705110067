import React, { useEffect } from 'react';
import logo from '../../../images/logo/UPP-Logotype-Landscape-Tomato-Transparent.png';

function LoginHeader(props) {
  return (
    <div className="layout__login-logo">
      <img src={ logo } title="Upprovall" alt="Upprovall" height="40" />
    </div>
  );
}

export { LoginHeader };
