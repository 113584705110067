import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from "react-redux";
import { BasicSpinner } from "../../../../components";
import { work_spaceActions, content_groupActions } from '../../../../actions';
import { useParams } from "react-router-dom";
import { adminBaseURL } from '../../../../utils/commonUtils';
import NotificationRow from '../../NotificationPage/notificationRow';
import { Table } from 'react-bootstrap';
import ContentList from "../../ContentPage/list";
import { useIsVisible } from '../../../../utils/useIsVisible';

function ActivityList(props) {
  const { loading, workspace } = props
  let { user_id } = useParams();
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const perPage = 10;
  const [page, setPage] = useState(1);
  const [activities, setActivities] = useState([]);
  const hasNextPage = useRef(true);
  const bottomPageRef = useRef();
  const isVisible = useIsVisible(bottomPageRef);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [ contentGroupPopUp, setContentGroupPopUp] = useState({id: null, show: false });
  const [ workspaceId, setWorkspaceId] = useState();

  const addStageToList = (result) => {
    setActivities((prevState) => [...prevState, ...result.activities]);
    hasNextPage.current = result.has_next_page;
  }

  const handleScroll = () => {
    const wrappedElement = document.getElementById('mainContainer');
    if (wrappedElement.scrollHeight - wrappedElement.scrollTop > wrappedElement.clientHeight + 100 || loading || !hasNextPage.current) {
      return;
    }
    loadNextPage()
  }

  useEffect(() => {
    if(isVisible){
      handleScroll()
    }
  }, []);

  useEffect(() => {
    if(isVisible){
      handleScroll()
    }
  }, [isVisible, activities]);

  /*
    For Pagination
  */
  useEffect(() => {
    if (user_id) {
        dispatch(work_spaceActions.getActivities(user_id, page, perPage, addStageToList));
    }
  }, [page]);

  const loadNextPage = () => {
    setPage((prevState) => prevState + 1)
  }

  const workspaceClickHandler = (e) =>{
    e.stopPropagation();

    window.location = `${workspaceBaseUrl}/${workspace?.id}/list`

    return true;
  }

  const handleInlineEdit = (handleOnBlur, values, content_group_id) => {
    dispatch(content_groupActions.inlineEditContentGroup(handleOnBlur, values, content_group_id, workspaceId));
  }

  const handleCloseContentGroupPopUp = () => {
    setContentGroupPopUp({id: null, show: false})
  }

  return (
    <div className='user_profile-activities-container'>
      <div className='user_profile-activities-header p-4'>
        <span className='user_profile-activities-title py-2 px-3'>
          Activity
        </span>

      </div>

      <div className="user-activities__table">
        { activities && activities.length > 0
           ?
             activities?.filter(act => act.summary !== '-' )
             .map((data, index) => (
               <NotificationRow
                 mode="user_activity"
                 data={ data }
                 key={ "activity"+data.is_read+"_"+data.id }
                 setWorkspaceId={ setWorkspaceId }
                 setContentGroupPopUp={ setContentGroupPopUp }
                 className={ "profile" }
                 readable={ false }
                 noName={true}
               />
             ))
           :
             <div className="d-flex justify-content-center py-4">
               There are no activities.
             </div>
         }
       </div>

      { contentGroupPopUp.show &&
          <ContentList
            content_group_id={contentGroupPopUp.id}
            workspace_id={workspaceId}
            updated_at={workspace && workspace.updated_at}
            handleCloseContentGroupPopUp={handleCloseContentGroupPopUp}
            handleInlineEdit={handleInlineEdit}
            setContentGroupPopUp={setContentGroupPopUp}
            content_popup_notification="show_content_notification"
          />
      }

      {
        loading &&
        <BasicSpinner />
      }

      <div ref={bottomPageRef}>
        {/* For updating the activity list */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    activities: state.user_profile.activities,
    workspace: state.user_profile.activity_workspace,
    loading: state.user_profile.activity_loading
  };
};

export default connect(mapStateToProps, {})(ActivityList);
