import React, { useEffect } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import ContentForm from "../ContentGroupPage/form";
import { content_groupActions } from '../../../actions';
import { useParams } from 'react-router-dom';
import { change } from "redux-form";

function NewContentGroup(props) {
  const dispatch = useDispatch();
  let { workspace_id } = useParams();
  const workspace = useSelector(state => state.content_group.currentWorkSpace);
  const { handleCloseNewForm, handleOpenContentGroupPopUp, selected_stage_id } = props

  useEffect(() => {
  }, []);

  const projects = useSelector(state => state.content_group.projects);
  const content_group_error = useSelector(state => state.content_group);

  const handleCreateContentGroupSuccess = (id) => {
    handleCloseNewForm();
    handleOpenContentGroupPopUp(id);
  };

  const showResults = (values) => {
    dispatch(content_groupActions.createContentGroup(values, workspace_id, handleCreateContentGroupSuccess));
  }

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  console.log(selected_stage_id)

  return (
    <ContentForm onSubmit={showResults}
      selected_stage_id={selected_stage_id}
      goupId={workspace_id}
      projects={projects && projects}
      newForm="newForm"
      handleCloseForm ={handleCloseNewForm}
      content_group_error={content_group_error && content_group_error}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.content_group.loading
  };
};

export default connect(mapStateToProps, { content_groupActions })(NewContentGroup);
