import React, { useEffect, useState } from 'react';
import { connect,  useDispatch, useSelector } from 'react-redux';
import OrganizationForm from "./form";
import { organizationActions } from '../../../actions';
import { buildFormDataFromObject } from '../../../utils/commonUtils';
import { Popup, LoadSpinner } from '../../../components';
import { change } from "redux-form";

function OrganizationNew(props) {
  const dispatch = useDispatch();
  const {
          handleCloseForm
        } = props;

  useEffect(() => {
    dispatch(change("organization_form", "name", ''))
  }, []);

  const showResults = (values) => {
    let formData = new FormData();
    buildFormDataFromObject(formData, values, "organization");

    const successCallback = () =>{
      handleCloseForm();
      dispatch(organizationActions.getOrganizations());
    }

    dispatch(organizationActions.createOrganization(formData, successCallback));
  }

  if (props.loading) {
    return <LoadSpinner />
  }

  return (
    <OrganizationForm
      onSubmit={showResults}
      handleCloseForm={handleCloseForm}
      newForm={true}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.organization.loading
  };
};

export default connect(mapStateToProps, { organizationActions })(OrganizationNew);
