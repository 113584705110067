import React, { useEffect } from "react";
import "./contentForm.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { contentActions } from '../../../actions';
import { validation } from "../../../shared/form-validations";
import ContentForm from "../ContentPage/form";

function Content(props) {
  const {
    workspace_id,
    editContent,
    content_group_id,
    content_id,
    handleClose,
    hasContents,
    content_popup_notification
  } = props
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  return (
    <ContentForm
      newForm="replaceForm"
      content_id={content_id}
      content_group_id={content_group_id}
      handleClose={handleClose}
      hasContents={hasContents}
      content_popup_notification={content_popup_notification}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.content.loading
  };
};

export default connect(mapStateToProps, { contentActions })(Content);
