import React from "react";
import { Field } from "redux-form";
import { textInput } from '../../../../shared/form-elements'

class renderUsers extends React.Component {
  render() {
    const { fields, editForm } = this.props;
    const removeName = (index, users) => {
      fields.remove(index);
    };
    console.log("USERSSSS in edit USER: ", fields);
    return (
      <div>
        {fields.map((users, index) => {
          return (
            <div key={index} className={index + "findClass_types"}>
              <div className="row d-flex align-items-center mb-3">
                <div className="col-lg-4">
                  <Field
                    name={`${users}.first_name`}
                    type="text"
                    component={textInput}
                    label="First Name"
                    autoFocus="autoFocus"
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name={`${users}.last_name`}
                    type="text"
                    component={textInput}
                    label="Last Name"
                  />
                </div>
                <div className="col-lg-3">
                  <Field
                    name={`${users}.email`}
                    type="text"
                    component={textInput}
                    label="Email Address"
                  />
                </div>
                <div className={editForm == "editForm" ? "hidden" : "col-lg-1"}>
                  <div key={index} className="mt-4">
                    <div onClick={() => removeName(index, users)} className="remove_btn">Remove</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className={editForm == "editForm" ? "hidden" : "py-1"}>
          <div  onClick={() => fields.push({})} className="addmore_btn"> + Add More</div>
        </div>
      </div>
    );
  }
}

export default renderUsers;
