const profileValidation = (values) => {
  const errors = {};

  if(!values.first_name){
    errors.first_name = 'required'
  }

  return errors;
};

export default profileValidation;
