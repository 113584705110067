import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { CheckIcon } from "../components";
import UncheckedIcon from '../images/icons/unchecked-box.svg';
import CheckedIcon from '../images/icons/white/checked-box.svg';
import { Image } from 'react-bootstrap';

function MarkAsCompleteButton(props) {
  const {
    content_group,
    updated_content_group,
    handleInlineEdit
  } = props;
  const [initial_loaded, setInitialLoaded] = useState(false);
  const [mark_as_complete, setAsComplete] = useState(content_group?.completed || false)

  useEffect(() => {
    if(content_group?.completed !== mark_as_complete){
      setAsComplete(content_group?.completed)
    }
  }, [content_group?.completed]);

  useEffect(() => {
    if(initial_loaded === false){
      setInitialLoaded(true);
      return;
    }

    const searchTimeout = setTimeout(
        function() {
          handleInlineEdit({completed: mark_as_complete})
        }
        .bind(this),
        0
    );

    return () => clearTimeout(searchTimeout)
  }, [mark_as_complete]);

  const handleSubmit = (e) => {
    setAsComplete(e.target.checked)
  }

  return (
    <div className={`detail mark_as_complete-btn-container ${mark_as_complete && 'completed'}`}>
      <label className="mt-0 mark_as_complete-btn d-flex align-items-center cursor-pointer">
        {
          mark_as_complete ?
            <Image src={CheckedIcon} height={14} className="radio-icon"/>
          :
            <Image src={UncheckedIcon} height={14} className="radio-icon"/>
        }
        <div className="mark_as_complete-btn-check ">

          <input type="checkbox"
            name={`completed`}
            className={`d-none ${mark_as_complete ? 'checked' : ''}`}
            checked={mark_as_complete}
            onClick={(e) => {
              handleSubmit(e)
            }}
          />
        </div>
        <span className="ml-3 checkbox-text">{ mark_as_complete ? 'Marked as Completed' : 'Mark as Complete' }</span>
      </label>
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    updated_content_group: state.content_group.updated_content_group
  };
};

export default connect(mapStateToProps, { })(MarkAsCompleteButton);
