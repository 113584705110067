function UpArrowIcon(props) {
  const { className, color, height, width } = props

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 14 14.38"
      width={width ? width : "18"}
      height={height ? height : "18"}
      fill={color}
      className={className}
    >
      <path class="st0" d="M5.96,3.55L1.79,7.72c-.21.21-.45.31-.73.31s-.53-.1-.75-.31C.1,7.51,0,7.27,0,6.99c0-.28.11-.53.32-.73L6.27.31c.1-.1.22-.18.34-.23s.26-.08.39-.08.27.03.39.08.24.13.33.22l5.95,5.96c.22.22.32.46.32.73s-.1.51-.31.72c-.22.21-.47.32-.75.32s-.53-.11-.73-.32l-4.17-4.15v9.79c0,.29-.1.54-.3.74-.2.2-.44.3-.73.3-.29,0-.54-.1-.74-.3-.2-.2-.3-.45-.3-.74V3.55Z"/>
    </svg>
  );
}

export { UpArrowIcon };
