import { useState, useRef, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { mediaQueryConstants } from '../constants';

function StageInlineEdit(props) {
  const { value, stage } = props
  const [ content, setContent] = useState(value);
  const [ textareaHeight, setTextareaHeight] = useState(27);
  const [ editMode, setEditMode ] = useState(false);
  const span = useRef();
  const textInput = useRef(null);
  let new_height =  span?.current?.clientHeight + 5;
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` })
  const [isDisabled, setIsDisabled] = useState(true);
  const [waitingClick, setWaitingClick] = useState(null);
  const [lastClick, setLastClick] = useState(0);

  useEffect(() => {
    setContent(value)
  }, [value]);

  useEffect(() => {
    if(editMode){
      setTextareaHeight(new_height + 5)
    }
  }, [editMode]);

  const setEditModeCatcher = (value) =>{
    if(props.setEditModeCatcher !== undefined && props.setEditModeCatcher !== null){
      props.setEditModeCatcher(value)
    }
  }

  const setFocused = () => {
    setEditMode(true);
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }

    if (event.key === "Escape") {
      setEditModeCatcher(false);
      setEditMode(false);
      setContent(value);
    }

    setTextareaHeight(event.target.scrollHeight)
  }

  const handleContentClick = useCallback(() => {
    if(isDisabled) return;

    setEditModeCatcher(true);
    setEditMode(true);
    setTimeout(() => {
      textInput.current.focus();
      textInput.current.select();
    }, 50);
  }, [textInput, isDisabled]);

  const handleInputOnChange = (event) => {
    setContent(event.target.value);
  }

  const handleOnBlurInput = (e) => {
    setIsDisabled(true);
    if (e.target.value != '' &&  e.target.value != value) {
      props.handleInlineEdit(setFocused, {name: e.target.value})
      setEditModeCatcher(false);
      setEditMode(false);
    } else {
      setContent(value)
      setEditModeCatcher(false);
      setEditMode(false);
    }
  }

  const onInput = (target) => {
    if (target?.scrollHeight > 33) {
      target.style.height = "5px";
      target.style.height = target.scrollHeight + 5 + "px";
    }
  };

  useEffect(() => {
    if(textInput){
      onInput(textInput.current);
    }
  }, [onInput, textInput]);


  const titleStyle = () => {
    if (editMode){
      let newMarginRight = 0

      if (stage.require_approval_from_client) {
        newMarginRight = newMarginRight + 15
      }

      if (stage.visible_to_client) {
        newMarginRight = newMarginRight + 15
      }

      if (newMarginRight === 0 ){
        return { visibility: 'hidden'}
      } else {
        return { visibility: 'hidden',  marginRight: newMarginRight}
      }
    } else{
      return {}
    }
  }

  const inputStyle = () => {
    return { width: "100%" }
  }

  const processClick = (e) => {
    if(!isLargeScreen){
      setIsDisabled(false)
    }

    if(lastClick &&e.timeStamp - lastClick < 250 && waitingClick){
      //Double Click

      setLastClick(0);
      clearTimeout(waitingClick);
      setWaitingClick(null);
      setIsDisabled(false)

      e.preventDefault();
    }
    else{
      //Single Click

      setLastClick(e.timeStamp);
      setWaitingClick(setTimeout(()=>{
      setWaitingClick(null);
      e.preventDefault();
      }, 251))
    }
  }

  return (
    <>
      {
        props.edit_type === 'name' ?
          <h2
            ref={span}
            onClick={handleContentClick}
            className={`h4 mb-0 d-none`}
          >
            {content}
          </h2>
        :
          <div ref={span}>
          </div>
      }

      <textarea
        ref={textInput}
        value={content}
        onKeyDown={onKeyDown}
        onChange={handleInputOnChange}
        onFocus={handleContentClick}
        onInput={(event) => onInput(event.target)}
        placeholder="..."
        onBlur={handleOnBlurInput}
        className={`disableable inline_edit_input h4 vertical-scroll_thin hidden-scroll`}
        style={inputStyle()}
        onClick={processClick}
        readOnly={isDisabled}
      />
    </>
  );
}

export { StageInlineEdit };
