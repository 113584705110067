import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function template_stage(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAGE_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.TEMPLATESTAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error:action.payload,
        template_stage_loading: false
      };
    }

    case userConstants.TEMPLATESTAGE_REQUEST: {
      return {
        ...state,
        loading: false,
        template_stage_loading: false
      };
    }

    case userConstants.GET_TEMPLATESTAGE:{
      return{
        ...state,
        template_stages: action.payload.data.data,
        loading: false,
        template_stage_loading: false
      }
    }

    case userConstants.NEW_TEMPLATESTAGE:{
      return{
        ...state,
        editTemplateStage: null,
        loading: false,
        template_stage_loading: false
      }
    }

    case userConstants.EDIT_TEMPLATESTAGE:{
      return {
        ...state,
        editTemplateStage: action.payload.data,
        template_stage_loading: false,
        loading: false
      }
    }

    case userConstants.TEMPLATESTAGE_LOADING:{
      return {
        ...state,
        template_stage_loading: true
      }
    }

    case userConstants.DELETE_TEMPLATESTAGE:{
      let id = action.payload.data.data.id
      return {
        ...state,
        template_stages: state.template_stages.filter(item => item.id !== id),
        loading: false
      }
    }
  default:
    return state
  }
}
