function EditIcon(props) {
  const { className, color, height, width } = props

  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
      	viewBox="0 0 14 14"
        width={width ? width : "18"}
        height={height ? height : "18"}
        fill={color}
        className={className}
      >
        <path class="st0" d="M1.68,12.31h1.04l7.29-7.29-1.03-1.04L1.68,11.28s0,1.03,0,1.03ZM.85,14c-.23,0-.43-.08-.6-.24-.16-.16-.25-.36-.25-.6v-1.87c0-.22.04-.44.12-.64.08-.2.2-.39.38-.56L10.12.45c.16-.16.33-.27.51-.34C10.81.04,11,0,11.2,0c.2,0,.39.04.58.11.19.07.36.18.52.34l1.25,1.23c.16.16.27.33.34.52.07.19.11.38.11.58s-.04.4-.11.59-.19.36-.34.51L3.93,13.5c-.17.17-.36.3-.56.38-.2.08-.42.12-.64.12,0,0-1.88,0-1.88,0ZM9.5,4.5l-.51-.52,1.03,1.04-.52-.51Z"/>
      </svg>
    </>
  );
}

export { EditIcon };
