import React from "react";
import { Field } from "redux-form";
import { assignedUserCheckBox } from '../../../../shared/form-elements'

class renderUser extends React.Component {
  render() {
    const { users } = this.props
    return (
      <Field name="assigned_users" component={assignedUserCheckBox} options={users}  /> 
    );
  }
}

export default renderUser;