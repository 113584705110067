export const getDropdownPosition = (props) => {
  const { innerWidth, innerHeight } = window;
  const {
    target,
    dropdown,
    minimum_drpdwn_height = 120,
    left_padding = 80,
    top_padding = 100
  } = props;
  let styles = {}
  const targetBoundary = target?.getBoundingClientRect();
  const dropdownBoundary = dropdown?.getBoundingClientRect();
  let drpdwn_height = Math.max(minimum_drpdwn_height, dropdown?.offsetHeight);


  if (innerWidth >= (targetBoundary?.left + left_padding + dropdown?.offsetWidth)){
    styles.left = `${targetBoundary?.left + left_padding}px`;
  } else {
    styles.left = `${(targetBoundary?.left + 0) - dropdown?.offsetWidth }px`;
  }

  if(innerHeight - drpdwn_height <= targetBoundary?.top){
    let top_coordinates = (targetBoundary?.top + top_padding ) - drpdwn_height;
    styles.top = `${top_coordinates}px`;

    if(top_coordinates < 0){
      styles.top = top_coordinates * -1
    }
  } else {
    styles.top = `${targetBoundary?.top}px`;
  }


  return styles
};
