import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { organizationActions } from '../../../actions';
import { useLocation } from "react-router-dom";
import { matchPath } from 'react-router';

function OrganizationTracker(props) {
  const dispatch = useDispatch();
  const { current_organization, organizations } = props
  const location = useLocation();
  const params =  matchPath(location.pathname, { path: "/organizations/:organization_id", strict: false, exact: false })
  const organization_id = params?.params?.organization_id

  useEffect(() => {
    if(organization_id && organization_id !== 0){
      dispatch(organizationActions.getOrganizationDetail(organization_id))
    };
  }, [organization_id]);

  return (
    <>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organization.organizations,
    current_organization: state.organization.current_organization
  };
};

export default connect(mapStateToProps, { organizationActions })(OrganizationTracker);
