import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import ProfileBanner from './profile_sections/profile_banner';
import ActivityList from './profile_sections/activity_list';

function UserProfileShow(props) {
  const {} = props
  let activities = [{}, {}]

  useEffect(() => {
  }, []);

  return (
    <div className="activity-page_container container p-1">
      <ProfileBanner />
      <ActivityList />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {})(UserProfileShow);
