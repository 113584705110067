export const sortByDuplicate = (array) => {
  const counts = {}
  let newArray = []

  array.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

  const setData = new Set(array);
  const uniqueArray = Array.from(setData)

  return uniqueArray.sort((a, b) => counts[a] > counts[b] ? -1 : 1)
};
