function UpprovallPlusTitle(props) {
  const { beta }  = props;

	return (
    <>
		upprovall+
    {
      beta &&
        <sup
          className="fw-normal beta_superscript"
        >
          beta
        </sup>
    }
    </>
	);
}

export { UpprovallPlusTitle };
