import React from 'react';
import { useEffect } from 'react';

export function LiveChatWidget() {
  useEffect(() => {
      const script = document.createElement('script');
      script.setAttribute("type", "text/javascript");

      script.src = process.env.REACT_APP_LIVE_CHAT_WIDGET_URL
      script.async = true
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
  }, []);

    return (
        <>
        </>
    );
}
