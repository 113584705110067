export const renderNameAndSummary = (name = '', summary = '', bold_name = false, one_line = false) => {
  if (bold_name) {
    name = '<b>'+name+'</b>'
  }

  if (one_line) {
    return name + " " + summary.charAt(0).toLowerCase() + summary.slice(1);
  } else {
    return summary;
  }
}
