import { Spinner } from 'react-bootstrap';

function BasicSpinner() {

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center"
      }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export { BasicSpinner };
