import { alertActions, work_spaceActions } from './';
import { userConstants } from '../constants';
import { content_groupActions } from '../actions';
import { history } from '../helpers';
import { makeGETRequest, makeFormDataPOSTRequest, makeDELETERequest, makeFormDataPUTRequest } from '../utils/Axios';
import { reset, setState } from "redux-form";
import { adminBaseURL } from '../utils/commonUtils';

export const contentActions = {
  createContent,
  getContent,
  editContent,
  destroyContent,
  updateContent,
  showContent,
  hideContent,
  unhideContent,
  newContent,
  versionContent,
  showVersionContent,
  getAuthorizedUsers,
  updateContentInlineEdit,
  sortContentAttachment,
  removeCurrentContentGroup
};

export function newContent(workspace_id, content_group_id) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const org_id = localStorage.getItem('org_id');

  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/new`)
      .then(response => {
        if(response.data.error){
          history.push(`/organizations/${org_id}${workspaceBaseUrl}/${workspace_id}/list`);
          dispatch(alertActions.error(response.data.message));
          //dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error})
        }else {
          dispatch({ type: userConstants.NEW_CONTENT, payload: response})

        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function createContent(reqparams, workspace_id, content_group_id, content_popup_notification) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENT_LOADING });
      makeFormDataPOSTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.CONTENT_REQUEST, payload: response})

          dispatch(contentActions.getContent(workspace_id, content_group_id, ""));
          const content_id = "first_record"
          dispatch(contentActions.showContent(workspace_id, content_group_id, content_id, ""));
          dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, [content_group_id]));
          dispatch(work_spaceActions.showWorkSpace(workspace_id, false, content_popup_notification));
          dispatch(alertActions.success(userConstants.CONTENT_REQUEST));
        }else {
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.message})
          if(response.data.created_content > 0){
            dispatch(contentActions.getContent(workspace_id, content_group_id, ""));
            const content_id = "first_record"
            dispatch(contentActions.showContent(workspace_id, content_group_id, content_id, ""));
          }
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function showContent(workspace_id, content_group_id, id, status, closeModal, setInitialLoad) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const org_id = localStorage.getItem('org_id');

  return function (dispatch) {
    try{
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${id}?sort=${status}` )
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.CONTENT_SHOW, payload: response})

        }else {
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error})

          if (closeModal) {
            closeModal();
            history.push(`/organizations/${org_id}${workspaceBaseUrl}/${workspace_id}/list`);
            dispatch(alertActions.error(response.data.message));
          } else {
            dispatch(alertActions.error(response.data.message));
          }
        }

        if(setInitialLoad){
          setInitialLoad(false)
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })

      if(setInitialLoad){
        setInitialLoad(false)
      }
    }
  }
}


export function showVersionContent(workspace_id, content_group_id, content_id, id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${content_id}/versions/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.CONTENT_VERSION_SHOW, payload: response})
        }else {
          history.push('/');
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function versionContent(workspace_id, content_group_id, id, status) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${id}/versions` )
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.CONTENT_VERSION, payload: response})
        }else {
          history.push('/');
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function hideContent(workspace_id, content_group_id, id, sort) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${id}/hide`)
      .then(response => {
        alert("success");
        if(response.data.status === "ok"){
          dispatch(contentActions.getContent(workspace_id, content_group_id, sort));
          dispatch(alertActions.success(userConstants.CONTENT_HIDE));
        }else {
          alert("not ok");

          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function unhideContent(workspace_id, content_group_id, id, sort) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${id}/unhide`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(contentActions.getContent(workspace_id, content_group_id, sort));
          dispatch(showContent(workspace_id, content_group_id, "first_record", sort));

          dispatch(alertActions.success(userConstants.CONTENT_UNHIDE));
        }else {
          history.push(`/`);
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateContent(reqparams, id, make_new_content, workspace_id, content_group_id, closeModal, setIsDisabled) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENT_LOADING });
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          if(make_new_content){
            dispatch({ type: userConstants.CONTENT_REQUEST, payload: response})
            dispatch(reset('contentForm'));

          }
          else{
            dispatch({
              type: userConstants.UPDATE_CONTENT,
              payload: response
            })
          }

          dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, [content_group_id]));
          dispatch(work_spaceActions.showWorkSpace(workspace_id));
          closeModal(true);

          dispatch(alertActions.success(userConstants.UPDATE_CONTENT));
        }else{
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }

  setIsDisabled(false);
}

export function getContent(workspace_id, content_group_id, load, sort) {
  const workspaceBaseUrl = adminBaseURL("workspaces");
  return function (dispatch) {
    try{
      if (load){
        dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      }
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents?sort=${sort}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_CONTENT,
            payload: response
          })

          dispatch({
            type: userConstants.CONTENTGROUP_REQUEST,
            payload: response
          })

        }else {
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error});
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getAuthorizedUsers(workspace_id, content_group_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/authorized_users`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_AUTHORIZED_USERS,
            payload: response
          })

        }else {
          history.push('/');
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editContent(workspace_id, content_group_id, id, openModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENTGROUP_LOADING });
      makeGETRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${id}`)
      .then(response => {

        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.EDIT_CONTENT,
            payload: response
          })

          dispatch({
            type: userConstants.CONTENTGROUP_REQUEST,
            payload: response
          })

          openModal({id: id, show: true})
        }else {
          history.push('/');
          dispatch({ type: userConstants.CONTENTGROUP_FAILURE, payload: response.data.error});
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENTGROUP_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyContent(workspace_id, content_group_id, id, selected, content_popup_notification) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.DELETE_CONTENT,
            payload: response
          })
          dispatch(alertActions.success(userConstants.DELETE_CONTENT));

          if (selected || content_popup_notification) {
            dispatch(contentActions.showContent(workspace_id, content_group_id, "first_record"));
          }

        }else {
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateContentInlineEdit(reqparams, id,  workspace_id, content_group_id, content_popup_notification) {
  const workspaceBaseUrl = adminBaseURL("workspaces")
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENT_LOADING });
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/${id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({ type: userConstants.UPDATE_CONTENT, payload: response})
          dispatch({ type: userConstants.CONTENT_INLINE_UPDATE, payload: response})

          if (content_popup_notification) {
            dispatch(contentActions.getContent(workspace_id, content_group_id, ""));
            const content_id = "first_record"
            dispatch(contentActions.showContent(workspace_id, content_group_id, content_id, ""));
          }

          //dispatch(work_spaceActions.showWorkSpace(workspace_id, false, content_popup_notification))
          dispatch(alertActions.success(userConstants.UPDATE_CONTENT))
          dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, [content_group_id]));
        }else{
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.message});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function removeCurrentContentGroup() {
  return function(dispatch) {
    dispatch({ type: userConstants.REMOVE_CONTENT_GROUP, payload: {} });
  };
}

export function sortContentAttachment(reqparams, workspace_id, content_group_id) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.CONTENT_LOADING });
      makeFormDataPUTRequest(`/api/v1/workspaces/${workspace_id}/content_groups/${content_group_id}/contents/sort_attachments`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_CONTENT,
            payload: response
          });

          dispatch(contentActions.getContent(workspace_id, content_group_id, ""));
          const content_id = "first_record"
          dispatch(contentActions.showContent(workspace_id, content_group_id, content_id, ""));
          dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, [content_group_id]));
        }else{
          dispatch({ type: userConstants.CONTENT_FAILURE, payload: response.data.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.CONTENT_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
