import React, { useEffect, useContext } from 'react';
import { Offcanvas, Accordion } from 'react-bootstrap';
import { TopbarContext } from '../../../../contexts/TopbarContext';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import { connect, useDispatch } from 'react-redux';
import logo from '../../../../images/logo/UPP-Logotype-Landscape-Tomato-Transparent.png';
import WorkspaceInfo from '../sidebar/workspaceInfo';
import { history } from '../../../../helpers';
import { work_spaceActions } from '../../../../actions';
import { UpgradeButton } from '../../../../components';

function MobileSidebar(props) {
  const { organizations } = props;
  const dispatch = useDispatch();
  const {
    activeOffCanvas,
    setActiveOffCanvas,
    setIsHomePage,
    is_home_page,
    setShowNotificationsDropdown
  } = useContext(TopbarContext);

  const {
    default_modes,
    selected_sidebar_organization,
    setSelectedSidebarOrganization,
    selected_sidebar_tab,
    setSelectedSidebarTab
  } = useContext(SidebarContext);

  const handleClose = () => {
    setActiveOffCanvas(false)
  }

  const handleOnclickHeader = (tab, id) =>{

    if(id && id !== default_modes[0] && id !== default_modes[1]){
      history.push(`/organizations/${id}`)
    } else {
      history.push('/')
    }

    setSelectedSidebarTab(tab);
    setSelectedSidebarOrganization(id);
    setShowNotificationsDropdown(false);

    setIsHomePage(true);
    dispatch(work_spaceActions.removeWorkspace());
    if(tab !== default_modes[1]) handleClose();
  }

  const shared_organizations = organizations?.filter(org => org.organization_type === default_modes[1]);
  const business_organizations = organizations?.filter(org => org.organization_type === default_modes[2]);
  const owned_organizations = organizations?.filter(org => org.organization_type === default_modes[0]);

  return (
    <>
      <Offcanvas
        show={activeOffCanvas}
        onHide={handleClose}
        responsive="lg"
        className="web_sidebar"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <a href="/" className="logo__text">
              <img src={ logo } title="Upprovall" alt="Upprovall" height="20" />
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <WorkspaceInfo />

          <Accordion
            className="workspace_sidebar-accordion-mobile"
            defaultActiveKey={['0']}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header
                className="personal mb-1"
                onClick={() => handleOnclickHeader(default_modes[0], null)}
              >
                My Workspace
              </Accordion.Header>
            </Accordion.Item>

            {
              shared_organizations?.length > 0 &&
                <Accordion.Item eventKey="1">
                  <Accordion.Header
                    onClick={() => is_home_page ? handleOnclickHeader(default_modes[1], default_modes[1]) : null }
                  >
                    Shared
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="workspace_sidebar-accordion-mobile-workspaces list-unstyled">
                      {
                        shared_organizations?.map((data, index) => (
                          <li
                            className="cursor-pointer mb-2 workspace_sidebar-accordion-mobile-workspace_item"
                            key={'mobile_shared_'+data.id}
                            onClick={() => handleOnclickHeader(null, data.id)}
                          >
                            <div className="workspace_sidebar-accordion-mobile-workspace_name d-flex align-items-center">
                            { data.name }
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
            }

            {
              business_organizations?.length > 0 &&
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Businesses</Accordion.Header>
                  <Accordion.Body>
                    <ul className="workspace_sidebar-accordion-mobile-workspaces list-unstyled">
                      {
                        business_organizations?.map((data, index) => (
                          <li
                            className="cursor-pointer mb-2 workspace_sidebar-accordion-mobile-workspace_item"
                            key={'mobile_business_'+data.id}
                            onClick={() => handleOnclickHeader(default_modes[2], data.id)}
                          >
                            <div className="workspace_sidebar-accordion-mobile-workspace_name d-flex align-items-center">
                            { data.name }
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
            }
          </Accordion>

          <div
            className="d-grid gap-2"
            style={{marginTop: 50}}>
              <UpgradeButton />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.organization.loading,
    organizations: state.organization.organizations,
    can_create_organization: state.organization.can_create_organization
  };
};

export default connect(mapStateToProps)(MobileSidebar);
