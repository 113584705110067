import React, { Component } from "react";
import { connect  } from 'react-redux';
import dateFormat from "dateformat";
import ReactHtmlParser from 'react-html-parser'
import { Image, Row, Col } from 'react-bootstrap'
import { MessageBubble } from '../../../components'

class ChatWindow extends Component {
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.getMessages !== prevProps.getMessages) {
      this.messageListEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const { getMessages, currentUser } = this.props;
    return (
      <div>
        {getMessages && getMessages.map((data, index) => {
          const url = data.file.url
          const trime = url && url.split(/[#?]/)[0].split('.').pop().trim();
          const formatArray = ["png", "jpg", "svg", "jpeg", "gif", 'tiff'];
          let renderFile = ""
          if(formatArray.indexOf(trime) > -1){
            renderFile = <a href={data.file.url} target="_blank"><img src={data.file.url} alt="Uploaded image" /></a>
          }else {
            renderFile = <a href={data.file.url} target="_blank" className="word-break">{data.file.url}</a>
          }
          return (
            <div key={index}>
              <li>
                <MessageBubble
                  own={ currentUser && currentUser.id === data.user_id }
                  user_name={ data.user.full_name }
                  user_name_initials={ data.user.name_initials }
                  user_icon={ data.user.logo.url }
                  message={
                    <>
                      {ReactHtmlParser(data.content)}
                      {renderFile}
                    </>
                  }
                  date={dateFormat(data.created_at, "mmm-dd-YYYY - h:MM TT")}
                />
              </li>
            </div>
          )
        })}
        <div
          className="reference"
          ref={node => (this.messageListEnd = node)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getMessages: state.chat.getMessages,
    currentUser: state.dashboard.currentUser
  };
};

export default connect(mapStateToProps, { })(ChatWindow);
