import React, { useEffect, useState } from 'react';
import { work_spaceActions, notificationActions } from '../../../actions';
import { connect, useDispatch } from 'react-redux';
import { adminBaseURL } from '../../../utils/commonUtils';
import { UserAvatar, ReminderAvatar } from '../../../components';
import { renderNameAndSummary, renderDueNotificationSummary } from '../../../utils';

function NotificationRow(props) {
  const dispatch = useDispatch();
  const { mode, className, key, notification_load, noName } = props;
  const { data, setWorkspaceId, setContentGroupPopUp, setHighlightedComment, currentUser } = props;
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const [isRead, setIsRead] = useState(data ? data.is_read : false);
  const readable = props.readable ? !isRead : false;

  useEffect(() => {
    if(data){
      setIsRead(data.is_read)
    }
  }, [data]);

  useEffect(() => {
    if (notification_load && notification_load.id === data.id && props.readable) {
      setIsRead(notification_load.is_read)
    }
  }, [notification_load]);

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');
    if(!targetLink) return;
    e.preventDefault();
    var content_id = targetLink.getAttribute("data-contentId")
    var workspace_id = targetLink.getAttribute("data-workspaceId")
    var comment_id = targetLink.getAttribute("data-commentId")
    var internal = targetLink.getAttribute("data-internal")
    var cg_id = targetLink.getAttribute("data-commentCgId")
    sessionStorage.setItem("previous_url", window.location.pathname)
    setWorkspaceId(workspace_id)
    setHighlightedComment({id: comment_id, internal: internal, cg_id: cg_id})
    setContentGroupPopUp({id: content_id, show: true})
    return true
  }

  const handleRead = (notificationId) => {
    dispatch(notificationActions.read(notificationId));
  }

  const handleUnRead = (notificationId) => {
    dispatch(notificationActions.unRead(notificationId));
  }

  const workspaceClickHandler = (e, data) =>{
    e.stopPropagation();

    window.location = `/organizations/${data.organization_id}${workspaceBaseUrl}/${data?.workspace_id}/list`

    return true;
  }

  const activityDescriptionHtml = () => {
    const bold_name = mode === 'notification';

    if(data?.notification_type === 'due_reminder'){
      return renderDueNotificationSummary(data)
    }

    return renderNameAndSummary(data?.actor?.name, data?.summary, bold_name, shouldDisplayNameAndActivityInOneLine());
  }

  const shouldDisplayNameAndActivityInOneLine = () => {
    if(noName) return !noName;

    return true;
  }

  return (
    <div onClick={() => props.readable ? (isRead ? handleUnRead(data.id) : handleRead(data.id)) : null }>
      <div className={`${readable ? (isRead ? 'notifications read_message' : 'notifications unread_message') : 'notifications read_message' } ${className}`} onClick={contentClickHandler}>
        <div className="d-flex notification-info_container">
          {
            (data?.notification_type === 'due_reminder' || data?.notification_type === 'past_due_reminder') ?
              <div className="notification__image-wrapper">
                <ReminderAvatar due_date={data?.due_date} past_due={data?.notification_type === 'past_due_reminder'}/>
              </div>
            :
              (data?.actor?.icon || data?.actor?.name_initials) &&
                <div className="notification__image-wrapper">
                  <UserAvatar
                    icon={ data.actor.icon }
                    name={ data.actor.name }
                    name_initials={ data.actor.name_initials }
                    size="32"
                    user_id={data.actor.user_id}
                    workspace_id={ data.workspace_id }
                    organization_id={ data.organization_id }
                    openUserSummary={true}
                  />
                </div>

          }

          <div className="notification-description_container">
            { false &&
              <p className="notification__actor-name">{data.actor.name}</p>
            }

            <div className="notification__description" dangerouslySetInnerHTML={{ __html: activityDescriptionHtml() }} />
            <div className="notifications-dropdown__notification-timestamp" title={ data.created_at }>
              <small >
                <span className="mr-3">
                { data.created_at_time_from_now }
                </span>
                <span className="workspace_link" onClick={(e) => workspaceClickHandler(e, data)}>
                  { data.workspace_name }
                </span>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.dashboard.currentUser,
    notification_load: state.notification.notification_load
  };
};

export default connect(mapStateToProps, {})(NotificationRow);
