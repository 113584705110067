export const tooltipConstants = {
  LABEL_PARTICIPANTS: 'Only added participants of the card can receive notification updates.',
  LABEL_DUE_DATE: 'Cards with due date will appear in the calendar.',
  LABEL_REVISION_COUNT: 'Number of times Revision Request is accepted.',
  LABEL_TIME_TRACK: 'Time spent on a card placed on a stage that requires time tracking.',
  ROLE_ADMIN: 'Owner and has full control of the entire account.',
  ROLE_TEAM_LEAD: 'Has control of the workspace except account and workspace deletion.',
  ROLE_COORDINATOR: 'Has limited control of the workspace, restricted from performing workspace deletion, account deletion, and changing stage settings.',
  ROLE_PRODUCTION: 'Has limited control of the workspace, restricted from performing workspace deletion, account deletion, modifying stage settings, commenting in Approver, card submission and changing stage settings.',
  ROLE_SPECTATOR: 'View only workspace.',
  ROLE_APPROVER: 'View only workspace, comment and approve cards.',
  ROLE_CONTRIBUTOR: 'View only workspace and comment on cards.',
  COMMENT_TEAM: 'Hidden to Approver and Contributor roles.',
  COMMENT_APPROVER: 'Production role is restricted from commenting here.',
  INVITED_USER: 'Invite users to your workspace.'
}
