function ContentIcon(props) {
  const { className, height, width } = props

  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "24"}
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.25001 20V8.97501C7.25001 8.42501 7.45001 7.95835 7.85001 7.57501C8.25001 7.19168 8.72501 7.00001 9.27501 7.00001H20.25C20.8 7.00001 21.2708 7.19585 21.6625 7.58751C22.0542 7.97918 22.25 8.45001 22.25 9.00001V16.175C22.25 16.4417 22.2 16.6958 22.1 16.9375C22 17.1792 21.8583 17.3917 21.675 17.575L17.825 21.425C17.6417 21.6083 17.4292 21.75 17.1875 21.85C16.9458 21.95 16.6917 22 16.425 22H9.25001C8.70001 22 8.22918 21.8042 7.83751 21.4125C7.44585 21.0208 7.25001 20.55 7.25001 20ZM2.27501 6.25001C2.17501 5.70001 2.28335 5.20418 2.60001 4.76251C2.91668 4.32085 3.35001 4.05001 3.90001 3.95001L14.75 2.02501C15.3 1.92501 15.7958 2.03335 16.2375 2.35001C16.6792 2.66668 16.95 3.10001 17.05 3.65001L17.3 5.00001H9.25001C8.15001 5.00001 7.20835 5.39168 6.42501 6.17501C5.64168 6.95835 5.25001 7.90001 5.25001 9.00001V18.55C4.98335 18.4 4.75418 18.2 4.56251 17.95C4.37085 17.7 4.25001 17.4167 4.20001 17.1L2.27501 6.25001ZM20.25 16H17.25C16.9667 16 16.7292 16.0958 16.5375 16.2875C16.3458 16.4792 16.25 16.7167 16.25 17V20L20.25 16Z"/>
    </svg>
  );
}

export { ContentIcon };
