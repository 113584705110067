import React, { useEffect, useState } from "react";
import "./contentForm.css";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { contentActions } from '../../../actions';
import moment from 'moment';
import { LoadSpinner } from '../../../components';

function ContentVersion(props) {
  const { workspace_id, content_group_id, content_id } = useParams();
  const { content, workspace, content_group, versions } = props
  const dispatch = useDispatch();
  let selected_content = null;

  useEffect(() => {
    const { state } = props.location
    if(content_id){
      dispatch(contentActions.versionContent(workspace_id, content_group_id, content_id));
    }else {
    }
  }, []);

  const [currentId, setCurrentId] = useState(content && content.id);
  const [currentVersion, setCurrentVersion] = useState(null);

  const currentVersionHandle = (data, id) => {
    setCurrentVersion(data)
    setCurrentId(id)
  }

  let hasVersions = versions && versions.length > 0;

  return (
    <div className="container-fluid">
      {props.loading && <LoadSpinner />}

      <div className="row">
        <div className="col-lg-3">

          <div className={content ? "left-full-height" : "left-full-height position-relative"}>
            <div className={content && content.id === currentId ? 'content-card highlight-card' : 'content-card'} key={content && content.id}>
              <div onClick={() => currentVersionHandle(content && content, content && content.id)}>
                <div className="card_header">{content && content.id}</div>
                <div  className="card-image">
                  { content && content.content_attachment &&
                    <img src={ content.content_attachment }  alt="content" className="card-img-top"/>
                  }
                </div>
              </div>
              <div className="content-feature d-flex align-items-center justify-content-between">
                <div className="content-created">{moment(content && content.created_at).format("DD MMM YYYY")}</div>
              </div>
            </div>
            {
              versions && versions.map((data, index) => (
                <div className={currentVersion && data.id === currentId ? 'content-card highlight-card' : 'content-card'} key={data.id}>
                  <div onClick={() => currentVersionHandle(data.content, data.id)}>
                    <div className="card_header">{data.content.id}</div>
                    <div  className="card-image">
                      { data.content && data.content.content_attachment &&
                        <img src={ data.content.content_attachment }  alt="content" className="card-img-top"/>
                      }
                    </div>
                  </div>
                  <div className="content-feature d-flex align-items-center justify-content-between">
                    <div className="content-created">{moment(data.content.created_at).format("DD MMM YYYY")}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        <div className="col-lg-9 version-center">
          {
            currentVersion  ?
              <>
                <h5 className="mb-2 mt_10">{currentVersion && currentVersion.id}</h5>
                <div className="center-secton mb-1 d-flex align-items-center justify-content-center">
                  { currentVersion && currentVersion.content_attachment &&
                    <a href={ currentVersion.content_attachment } target="_blank">
                      <img src={ currentVersion.content_attachment } alt="content" style={{height: '460px'}}/>
                    </a>
                  }
                </div>
              </>
            :
              <>
                <h5 className="mb-2 mt_10">{content && content.id}</h5>
                <div className="center-secton mb-1 d-flex align-items-center justify-content-center">
                  { content && content.content_attachment &&
                    <a href={ content.content_attachment } target="_blank">
                      <img src={ content.content_attachment } alt="content" style={{height: '460px'}}/>
                    </a>
                  }
                </div>
              </>
          }
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.content.loading,
    content: state.content.currentContent,
    workspace: state.content.currentWorkSpace,
    content_group: state.content.currentContentGroup,
    versions: state.content.versions

  };
};

export default connect(mapStateToProps, { contentActions })(ContentVersion);
