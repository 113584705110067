import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Image } from '../../utils';
import { UpprovallPlusTitle } from '../';
import topbarLogo from '../../images/logo/top-bar-logo.png';
import sidebarLogo from '../../images/logo/sidebar-logo.png';

function UpgradeButton(props) {
  const {
    upgrade_button_style
  } = props;
  const [open_popup, setOpenPopup] = useState(false);

  return (
    <>
    <Modal
      show={open_popup}
      keyboard={true}
      className="upgrade_modal"
      size="lg"
    >
      <Modal.Body className="custom_body"
      >
        <div className="text-center">
          <div className="d-flex align-items-center justify-content-center position-relative">
            <img src={Image.Upgrade.Details.default} className="img-fluid"/>
            <div className="upgrade_links-container">
              {
                process.env.REACT_APP_INQUIRY_EMAIL &&
                <p>
                  Inquire at&nbsp;
                  <a href={`mailto: ${process.env.REACT_APP_INQUIRY_EMAIL}`} className="font-weight-bold">
                    {process.env.REACT_APP_INQUIRY_EMAIL}
                  </a>
                </p>
              }

              <Button
                variant="link"
                size="sm"
                className="mx-3 px-3"
                onClick={() => setOpenPopup(false)}
              >
                Maybe later
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    {
      upgrade_button_style === 'small' ?
        <Button variant="upgrade" size="sm" onClick={() => setOpenPopup(true)} >
          <img src={ topbarLogo } title="Upprovall" alt="Upprovall" height="13" />
        </Button>
      :
        <Button variant="upgrade" onClick={() => setOpenPopup(true)}>
          <img src={ sidebarLogo } title="Upprovall" alt="Upprovall" height="35" />
        </Button>
    }

    </>
  );
}

export { UpgradeButton }
