import { alertActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePOSTRequest, makeDELETERequest, makePUTRequest } from '../utils/Axios';
import { reset } from "redux-form";
import { adminBaseURL } from '../utils/commonUtils';

export const template_stageActions = {
  newTemplateStage,
  createTemplateStage,
  getTemplateStage,
  editTemplateStage,
  destroyTemplateStage,
  updateTemplateStage
};

export function newTemplateStage(openModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.TEMPLATESTAGE_LOADING });
      makeGETRequest(`/api/v1/template_stages/new`)
      .then(response => {
        if(response.data.error){
          history.push('/');
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch({ type: userConstants.NEW_TEMPLATESTAGE, payload: response})
          openModal({show: true});
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.TEMPLATESTAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function createTemplateStage(reqparams, closeModal) {
  const templateStageBaseUrl = adminBaseURL("stage-templates");
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.TEMPLATESTAGE_LOADING });
      makePOSTRequest('/api/v1/template_stages', reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          closeModal();
          dispatch({ type: userConstants.TEMPLATESTAGE_REQUEST, payload: response})
          dispatch(reset('template_stageform'));
          dispatch(template_stageActions.getTemplateStage(true));
          dispatch(alertActions.success(userConstants.TEMPLATESTAGE_REQUEST));
        }else {
          dispatch({ type: userConstants.TEMPLATESTAGE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.TEMPLATESTAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateTemplateStage(reqparams, id, closeModal) {
  const templateStageBaseUrl = adminBaseURL("stage-templates");
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.TEMPLATESTAGE_LOADING });
      makePUTRequest(`/api/v1/template_stages/${id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          closeModal();
          dispatch({
            type: userConstants.UPDATE_TEMPLATESTAGE,
            payload: response
          });
          dispatch(reset('template_stageform'));
          dispatch(template_stageActions.getTemplateStage(true));
          dispatch(alertActions.success(userConstants.UPDATE_TEMPLATESTAGE));
        }else{
          dispatch({ type: userConstants.TEMPLATESTAGE_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.TEMPLATESTAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getTemplateStage(template_stage_load) {
  return function (dispatch) {
    try{
      if(template_stage_load != true){
        dispatch({ type: userConstants.PAGE_LOADING });
      }

      makeGETRequest('/api/v1/template_stages')
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_TEMPLATESTAGE,
            payload: response
          })
        }else{
          history.push("/");
          dispatch({ type: userConstants.TEMPLATESTAGE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.TEMPLATESTAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editTemplateStage(id, openModal) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.TEMPLATESTAGE_LOADING });
      makeGETRequest(`/api/v1/template_stages/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.EDIT_TEMPLATESTAGE,
            payload: response
          })

          openModal({ show: true, id: id });
        }else{
          history.push("/");
          dispatch({ type: userConstants.TEMPLATESTAGE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.TEMPLATESTAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyTemplateStage(id) {
  return function (dispatch) {
    try{
      makeDELETERequest(`/api/v1/template_stages/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.DELETE_TEMPLATESTAGE,
            payload: response
          })
          dispatch(alertActions.success(userConstants.DELETE_TEMPLATESTAGE));
        }else{
          dispatch({ type: userConstants.TEMPLATESTAGE_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.TEMPLATESTAGE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
