import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { workspace_userActions } from '../../../actions';
import { history } from '../../../helpers';

function AcceptWorkspaceInvitation(props) {
  const dispatch = useDispatch();
  const { workspace_invite_id } = props.match.params;
  const { workspace_id } = props.match.params;

  useEffect(() => {
    dispatch(workspace_userActions.acceptInvitation(workspace_id, workspace_invite_id, "Accept"));
    setTimeout(function() {
      window.location.href = "/notifications"
    }, 3000);
  }, []);

  return (
    <div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.workspaceUser.loading
  };
};

export default connect(mapStateToProps, { workspace_userActions })(AcceptWorkspaceInvitation);
