export function getLabelColor(color_hex) {
  let text_color = "white"

  if(color_hex){
    var rgb = color_hex.replace('#', '');

    const r = parseInt(rgb.substr(0, 2), 16);
    const g = parseInt(rgb.substr(2, 2), 16);
    const b = parseInt(rgb.substr(4, 2), 16);
    let sum = 0;
    if(!isNaN(r)){
      sum += r * 299
    }

    if(!isNaN(g)){
      sum += g * 587
    }

    if(!isNaN(b)){
      sum += b * 114
    }
    const brightness = sum / 1000;

    if (brightness > 155) {
      text_color = "black"
    }
  } else {
    var rgb = "000000";
  }

  var s = new Option().style;
  s.color = "#"+rgb;

  return { background: rgb, text: s.color ? text_color : 'black' }
}
