import { useState, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { search_historyActions } from '../actions';
import { ContentGroupPopupContext } from '../contexts/ContentGroupPopupContext';
import { SearchContext } from '../contexts/SearchContext';
import { useHistory } from 'react-router';
import { HistoryIcon, SearchIcon, CloseIcon } from './';

function WorkspaceSearchResultItem(props) {
  const { id, workspace_id, html_text, text, index, alreadySearched, setShowResult } = props
  const windowHistory = useHistory();
  const dispatch = useDispatch();
  const { setContentGroupPopUp } = useContext(ContentGroupPopupContext);
  const { searchBarRef, searchTimeout } = useContext(SearchContext);
  const [hidden, setHidden] = useState(false);

  const handleOpen = (value) => {
    dispatch(search_historyActions.createSearchHistory(workspace_id, value));
    clearTimeout(searchTimeout);
    setShowResult(false);
    searchBarRef.current.value = value;
    windowHistory.push({
      pathname: window.location.pathname,
      search: `?search=${value}`
    })
  }

  const handleClose = (e, id) => {
    e.stopPropagation();

    if (alreadySearched) {
      dispatch(search_historyActions.destroySearchHistory(workspace_id, id))
    } else {
      setHidden(true)
    }
  }

  return (
    <li className={`workspace-search-result_item d-flex p-2 align-items-center ${alreadySearched ? 'searched' : ''} ${hidden ? 'd-none' : ''}`} onClick={() => handleOpen(text)}>
      {
        alreadySearched ?
          <HistoryIcon className="mr-2"/>
        :
          <SearchIcon className="mr-2"/>
      }

      <div className="content-details">
        <div className="content-details_title text-truncate">{ html_text }</div>
      </div>
      {
        alreadySearched &&

        <div className="content-details_close-button text-center" onClick={(e) => handleClose(e, id)}>
          <CloseIcon className="btn-close"/>
        </div>
      }
    </li>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { })(WorkspaceSearchResultItem);
