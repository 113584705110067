import React from "react";
import EditIcon from "../../../images/icons/white/edit.svg";
import DeleteIcon from "../../../images/icons/white/delete.svg";
import { getLabelColor } from '../../../helpers';

function ContentLabelSelector(props) {
  const {
    selected,
    labelId,
    labelName,
    colorHex,
    onUpdate,
    onEdit,
    onDelete,
    can_access_edit_label
  } = props

  const handleToggle = (close_popup = false) => {
    onUpdate(labelId, !selected, close_popup)
  }

  const label_color = getLabelColor(colorHex)

  return (
    <div className="content-label-selector">
      <span
        className={ "content-label-selector__checkbox cursor-pointer" }
        style={ selected ? { borderColor: "#" + colorHex, backgroundColor: "#" + colorHex } : {} }
        onClick={ () => handleToggle(false) }>
      </span>

      <div
        className={ "content-label-selector__details cursor-pointer" }
        style={{ backgroundColor: "#" + colorHex }}
        onClick={ () => handleToggle(true) }>
        <span
          style = {{
            color: label_color.text
          }}>
          { labelName }
        </span>
        <div className="d-flex align-items-center">
          <img
            src={ EditIcon }
            title="Edit"
            width="15"
            onClick={ (e) => onEdit(labelId, e) }
            style={{marginTop: 0, filter: label_color.text != 'white' ? 'invert(88%)' : null }}
            className="cursor-pointer edit-icon"
          />

          <img
            src={ DeleteIcon }
            title="Edit"
            width="15"
            onClick={ (e) => onDelete(labelId, e) }
             style={{marginTop: 0, display: 'none'}}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}

export default ContentLabelSelector
