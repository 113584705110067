import { useState, useContext, useRef, useEffect } from 'react';
import { AlertTimeIcon } from '/';
import { reminderTagClassName, daysBeforeToday, daysBetweenDates } from '../utils';

function ReminderAvatar(props) {
  const {
    due_date,
    past_due
  } = props

  const diff_in_day = daysBeforeToday(due_date)
  const completed = false;

  return (
    <div
      className={`d-flex justify-content-center align-items-center tag-notification_avatar ${reminderTagClassName(past_due ? -1 : diff_in_day, completed)}`}
    >
      <AlertTimeIcon />
    </div>
  );
}

export { ReminderAvatar };
