import { alertActions, contentActions, work_spaceActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makeFormDataPOSTRequest, makeDELETERequest, makeFormDataPUTRequest } from '../utils/Axios';
import { adminBaseURL } from '../utils/commonUtils';

export const organizationActions = {
  getOrganizations,
  getWorkspaces,
  getOrganizationDetail,
  getAllowedUserDetails,
  updateOrganization,
  editOrganization,
  createOrganization,
  destroyOrganization
};

export function getOrganizations() {
  return function (dispatch) {
    try{
      makeGETRequest(`/api/v1/organizations`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.GET_ORGANIZATIONS,
            payload: response
          });
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({ type: userConstants.ORGANIZATION_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ORGANIZATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getWorkspaces(organization_id, serializer_type = "dashboard") {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.WORKSPACE_DROPDOWN_LOADING });
      makeGETRequest(`/api/v1/organizations/workspaces`, { organization_id: organization_id, serializer_type: serializer_type })
      .then(response => {
        if(response.data.status === "ok"){
          localStorage.setItem('user_role', response.data.can_acces_admin_login);

          dispatch({
            type: userConstants.GET_WORKSPACE,
            payload: response
          })

          dispatch({
            type: userConstants.SET_CURRENT_ORGANIZATION,
            payload: response
          });
        }else {
          dispatch(alertActions.error(response.data.message));
          dispatch({ type: userConstants.WORKSPACE_FAILURE, payload: response})
          window.location = '/'
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getOrganizationDetail(organization_id) {
  let old_org_id = localStorage.getItem('org_id');

  return function (dispatch) {
    try{
      localStorage.setItem('org_id', organization_id);

      makeGETRequest(`/api/v1/organizations/${organization_id}/detail`)
      .then(response => {
        if(response.data.status === "ok"){
          localStorage.setItem('user_role', response.data.can_acces_admin_login);

          dispatch({
            type: userConstants.SET_CURRENT_ORGANIZATION,
            payload: response
          });
        }else {
          dispatch(alertActions.error(userConstants.ORGANIZATION_FAILURE));
          dispatch({ type: userConstants.ORGANIZATION_FAILURE, payload: response.data.error})
          window.location = '/'
        }
      })
    }catch(e){
      dispatch(alertActions.error(userConstants.ORGANIZATION_FAILURE));
      window.location = '/'
      dispatch( {
        type: userConstants.ORGANIZATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getAllowedUserDetails(organization_id) {
  return function (dispatch) {
    try{
      makeGETRequest(`/api/v1/organizations/${organization_id}/get_allowed_user_details`)
      .then(response => {
        if(response.data.status === "ok"){

          dispatch({
            type: userConstants.SET_ORGANIZATION_ALLOWED_USER_DETAILS,
            payload: response
          });
        }else {
          dispatch({ type: userConstants.ORGANIZATION_FAILURE, payload: response.data.error})
        }
      })
    }catch(e){
      dispatch(alertActions.error(userConstants.ORGANIZATION_FAILURE));

      dispatch( {
        type: userConstants.ORGANIZATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function editOrganization(id, successCallback) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.ORGANIZATION_LOADING });
      makeGETRequest(`/api/v1/organizations/${id}/edit`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.EDIT_ORGANIZATION,
            payload: response
          })

          successCallback();
        }else {
          dispatch({ type: userConstants.ORGANIZATION_FAILURE, payload: response.data.error});
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ORGANIZATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateOrganization(id, values, successCallback) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.ORGANIZATION_LOADING });
      makeFormDataPUTRequest(`/api/v1/organizations/${id}`, values)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.UPDATE_ORGANIZATION,
            payload: response
          })

          successCallback();
        }else {
          dispatch({ type: userConstants.ORGANIZATION_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
          window.location = '/';
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ORGANIZATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}


export function createOrganization(values, successCallback) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.ORGANIZATION_LOADING });
      makeFormDataPOSTRequest(`/api/v1/organizations`, values)
      .then(response => {
        if(response.data.status === "ok"){
          successCallback();
          dispatch({ type: userConstants.CREATE_ORGANIZATION, payload: response});
          dispatch(alertActions.success(userConstants.CREATE_ORGANIZATION));
        }else {
          dispatch({ type: userConstants.ORGANIZATION_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
          window.location = '/';
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ORGANIZATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyOrganization(id, successCallback) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.ORGANIZATION_LOADING });
      makeDELETERequest(`/api/v1/organizations/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch(alertActions.success(userConstants.DESTROY_ORGANIZATION));
          successCallback();
        }else {
          dispatch({ type: userConstants.ORGANIZATION_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.ORGANIZATION_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
