import moment from 'moment';
import { dateTimeConstants } from '../constants'

export function dateTimeFormatter(date, format = 'display') {
  if(!date) return '';

  switch(format){
    case 'display': {
      return moment(date).format(dateTimeConstants.DISPLAY_FORMAT)
    }
    case 'input':{
      return moment(date).format(dateTimeConstants.INPUT_FORMAT)
    }

    case 'calendar_date_time' :{
      return moment(date).format(dateTimeConstants.CALENDAR_DATE_TIME_FORMAT)
    }

    case 'calendar_month' :{
      return moment(date).format(dateTimeConstants.CALENDAR_MONTH_FORMAT)
    }

    default:{
      return moment(date).format(dateTimeConstants.DISPLAY_FORMAT)
    }
  }
}
