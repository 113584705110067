import { alertActions } from './';
import { userConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePOSTRequest, makePUTRequest, makeDELETERequest } from '../utils/Axios';

export const multi_userActions = {
  getWorkspaceData,
  createUser,
  getAllUsrs,
  editUserDetails,
  updateUser,
  showUser,
  destroyUser,
  resetUser
};

export function getWorkspaceData() {
  return dispatch => {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest('/api/v1/get_workspace')
      .then(response => {
        if(response.data.status === 'ok'){
          dispatch({
            type: userConstants.GET_WORKSPACE_DATA,
            payload: response
          })
        } else {
          history.push("/");
          dispatch({ type: userConstants.WORKSPACE_USER_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }

      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function createUser(reqparams) {
  let organization_id = localStorage.getItem('org_id');
  let userBaseUrl = `/organizations/${organization_id}/admin/users`

  return dispatch => {
    try{
      //dispatch({ type: userConstants.PAGE_LOADING });
      makePOSTRequest('/api/v1/multi_users', reqparams)
      .then(response => {
          if(response.data.status === "ok"){
            dispatch({
              type: userConstants.WORKSPACE_USER_NEW,
              payload: response
            });
            history.push(userBaseUrl);
            dispatch(alertActions.success(response.data.message));
          }else {
            dispatch({type: userConstants.WORKSPACE_USER_FAILURE, payload: response.error});

            dispatch(alertActions.error(response.data.message));
            dispatch(alertActions.error(response.data.error));
          }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function getAllUsrs(workspace_id = null, loading=true) {
  return dispatch => {
    try{
      if(loading){
        dispatch({ type: userConstants.PAGE_LOADING });
      }
      const params = workspace_id ? {workspace_id: workspace_id} : {}
      makeGETRequest('/api/v1/multi_users', params)
      .then(response => {
        if(response.data.status === 'ok'){
          dispatch({
            type: userConstants.WORKSPACE_USERS_LIST,
            payload: response
          })
        } else {
          history.push("/");
          dispatch({ type: userConstants.WORKSPACE_USER_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_USER_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function showUser(user_id, workspace_id = null, organization_id = null) {
  return dispatch => {
    try{
      dispatch({ type: userConstants.USER_SUMMARY_LOADING });
      makeGETRequest(`/api/v1/multi_users/${user_id}/get_summary?workspace_id=${workspace_id}&organization_id=${organization_id}`)
      .then(response => {
        dispatch({
          type: userConstants.USER_SUMMARY,
          payload: response
        })
      })
    }catch{

    }
  }
}

export function resetUser() {
  return dispatch => {
    try{
      dispatch({ type: userConstants.USER_SUMMARY_RESET });
    }catch{

    }
  }
}

export function editUserDetails(userId) {
  return function (dispatch) {
    try{
      dispatch({ type: userConstants.PAGE_LOADING });
      makeGETRequest(`/api/v1/multi_users/${userId}`)
      .then(response => {
        if(response.data.status === 'ok'){
          dispatch({
            type: userConstants.WORKSPACE_USERS_EDIT,
            payload: response
          })
        } else {
          history.push("/");
          dispatch({ type: userConstants.WORKSPACE_USER_FAILURE, payload: response.data.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function updateUser(reqparams, id ) {
  let organization_id = localStorage.getItem('org_id');
  let userBaseUrl = `/organizations/${organization_id}/admin/users`

  return function (dispatch) {
    try{
      //dispatch({ type: userConstants.PAGE_LOADING });
      makePUTRequest(`/api/v1/multi_users/${id}`, reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.WORKSPACE_USERS_UPDATE,
            payload: response
          })
          history.push(userBaseUrl);
        }else {
          dispatch({type: userConstants.WORKSPACE_USER_FAILURE, payload: response.error});
          dispatch(alertActions.error(response.data.message));
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}

export function destroyUser( id ) {
  let organization_id = localStorage.getItem('org_id');
  let userBaseUrl = `/organizations/${organization_id}/admin/users`

  return function (dispatch) {
    try{
      //dispatch({ type: userConstants.PAGE_LOADING });
      makeDELETERequest(`/api/v1/multi_users/${id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({
            type: userConstants.WORKSPACE_USER_DESTROY,
            payload: response
          })
          history.push(userBaseUrl);
        }else {
          dispatch({type: userConstants.WORKSPACE_USER_FAILURE, payload: response.error});
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch(e){
      dispatch( {
        type: userConstants.WORKSPACE_FAILURE,
        payload: console.log(e),
      })
    }
  }
}
