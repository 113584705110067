import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function organization(state = initialState, action) {
  switch (action.type) {
    case userConstants.ORGANIZATION_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case userConstants.GET_ORGANIZATIONS: {
      return {
        ...state,
        loading: false,
        organizations: action.payload.data.organizations
      };
    }

    case userConstants.EDIT_ORGANIZATION: {
      return {
        ...state,
        loading: false,
        editOrganization: action.payload.data.organization
      };
    }

    case userConstants.UPDATE_ORGANIZATION: {
      return {
        ...state,
        loading: false
      };
    }

    case userConstants.CREATE_ORGANIZATION: {
      return {
        ...state,
        loading: false
      };
    }

    case userConstants.DESTROY_ORGANIZTION: {
      return {
        ...state,
        loading: false
      };
    }

    case userConstants.ORGANIZATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload?.data?.error
      };
    }

    case userConstants.SET_CURRENT_ORGANIZATION: {
      return {
        ...state,
        loading: false,
        can_create_workspace: action.payload.data.can_create_workspace,
        can_create_organization: action.payload.data.can_create_organization,
        can_update_organization: action.payload.data.can_update_organization,
        can_destroy_organization: action.payload.data.can_destroy_organization,
        current_organization: action.payload.data.organization
      };
    }

    case userConstants.RESET_ORGANIZATION: {
      return {
        ...state,
        current_organization: null
      };
    }

    case userConstants.SET_ORGANIZATION_ALLOWED_USER_DETAILS: {
      return {
        ...state,
        remaining_users: action.payload.data.remaining_users,
        ignore_limit: action.payload.data.ignore_limit
      };
    }


  default:
    return state
  }
}
