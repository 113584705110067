export function reminderDisplayText(value, display_type){

  const start_text = display_type === 'option' ? '' : 'Remind in '

  switch(value) {
    case 0:
    case undefined:
      return 'No reminder'
    case 1:
      return `${start_text}${value} day before`
    default:
      return `${start_text}${value} days before`
  }
}
