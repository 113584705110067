import { useContext } from 'react';
import { Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import WorkspaceSearchResultItem from './WorkspaceSearchResultItem';
import { sortByDuplicate } from '../utils/sortByDuplicate';
import { filterArray } from '../utils/filterArray';
import { SearchContext } from '../contexts/SearchContext';
import { getHighlightedText } from '../utils/getHighlightedText';

function WorkspaceSearchResult(props) {
  const { workspace_id, loading, recommends, search_histories, setShowResult } = props
  const { searchBarRef } = useContext(SearchContext);
  const alreadySearched = (text) => {
    if (search_histories?.length > 0){
      return search_histories.map((sh) => sh.text).includes(text);
    } else {
      return false
    }
  }

  const filteredAttribute = 'text';
  const filteredHistory = filterArray(searchBarRef?.current?.value, search_histories, filteredAttribute)
  const unsearchedRecommendations = recommends?.filter((e)=> !alreadySearched(e.name))

  if (filteredHistory?.length === 0 && unsearchedRecommendations?.length === 0) return null;

  return (
    <ul className="workspace-search-result_container list-unstyled">
      { loading ?
          <li>
            <div className="clearfix">
              <div className="spinner-container">
                <Spinner animation="border" />
              </div>
            </div>
          </li>
        :
          <>
            {
               filteredHistory?.map((fh, index) =>(
                <WorkspaceSearchResultItem
                  html_text={getHighlightedText(fh['text'], searchBarRef.current.value)}
                  text={fh['text']}
                  key={'history_'+index}
                  id={fh['id']}
                  workspace_id={workspace_id}
                  alreadySearched={alreadySearched(fh['text'])}
                  setShowResult={setShowResult}
                />
              ))
            }
            {
              unsearchedRecommendations?.map((content_group, index) =>(
                <WorkspaceSearchResultItem
                  html_text={getHighlightedText(content_group.name, searchBarRef.current.value)}
                  text={content_group.name}
                  workspace_id={workspace_id}
                  key={'recommend_'+index}
                  alreadySearched={alreadySearched(content_group.name)}
                  setShowResult={setShowResult}
                />
              ))
            }
        </>
      }
    </ul>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.content_group.recommend_loading,
    recommends: state.content_group.recommends
  };
};

export default connect(mapStateToProps, { })(WorkspaceSearchResult);
