import React, {} from 'react';
import { adminBaseURL, buildFormDataFromObject } from '../utils/commonUtils';
import { workspaceSizeAlert, checkImagesSize } from '../utils';
import { useDispatch } from 'react-redux';
import { contentActions } from '../actions';
import { contentConstants } from '../constants';

function HiddenContentImageUploader(props) {
  const {
    fileInputRef,
    workspace_id,
    content_group_id,
    content_popup_notification,
    workspace
  } = props
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const { IMAGE_MAX_SIZE } = contentConstants;

  const addFile = e => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleUploadFiles(chosenFiles, e.target.files);

    e.target.value = '';
  }

  const handleUploadFiles = (arrayFiles, files)  => {
       const uploaded = [];
       let limitExceeded = checkImagesSize(arrayFiles, workspace?.personal);

       if (!limitExceeded && arrayFiles.length > 0) {
        workspaceSizeAlert(workspace)
        let formData = new FormData();
        buildFormDataFromObject(formData, { attachment: files}, "content");
        dispatch(contentActions.createContent(formData, workspace_id, content_group_id, content_popup_notification ));
       }
  }

  return (
    <input
      type="file"
      onChange={addFile}
      ref={fileInputRef}
      hidden
      multiple
      accept="image/jpg, image/jpeg, image/png, image/gif, image/webp"
    />
  );
}

export { HiddenContentImageUploader };
