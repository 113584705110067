function CheckIcon(props) {
  const { className, color } = props

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 14 10.39"
      fill={color}
      height="16"
      width="16"
    >
      <path class="st0" d="M4.69,7.81L12.18.32c.21-.21.46-.32.75-.32s.54.11.75.32c.21.21.32.46.32.75s-.1.53-.31.74L5.45,10.07c-.21.21-.46.32-.75.32s-.54-.11-.75-.32L.33,6.44C.11,6.23,0,5.98,0,5.7c0-.28.1-.53.32-.75.21-.21.47-.32.75-.32s.53.11.74.32l2.87,2.86Z"/>
    </svg>
  );
}

export { CheckIcon };
