import React, { useEffect, useState } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import { projectActions } from '../../../actions';
import { Button, Table } from 'react-bootstrap';
import { Popup, LoadSpinner, ProjectLabel } from '../../../components';
import ProjectNew  from '../ProjectPage/new';
import ProjectEdit  from '../ProjectPage/edit';

function ProjectList(props) {

  const dispatch = useDispatch();
  const { projects, editProject } = props

  useEffect(() => {
    dispatch(projectActions.getProject());
  }, []);

  const deleteProject = (id) => {
    console.log(id)
    dispatch(projectActions.destroyProject(id));
  }

  console.log(editProject && editProject);


  /*
    Popup Modal
  */
  const [popup, setPopUp] = useState({
    show: false,
    id: null,
  });;

  const handleClose = () => {
    setPopUp({show: false, id: null});
  }

  const handleShow = (id) => setPopUp({show: true, id});

  const handleDelete = () => {
    deleteProject(popup.id);
    handleClose();
  }

  /*
    Popup New Form Modal
  */
  const [popupNewForm, setPopUpNewForm] = useState({
    show: false
  });;

  const handleCloseNewForm = () => {
    setPopUpNewForm({show: false});
  }

  const handleShowNewForm = () => setPopUpNewForm({show: true});

  /*
    Popup Edit Form Modal

  */

  const [popupEditForm, setPopUpEditForm] = useState({
    show: false,
    id: null
  });;

  const handleCloseEditForm = () => {
    setPopUpEditForm({ show: false, id: null });
  }

  const handleShowEditForm = (id) => {
    dispatch(projectActions.editProject(id, setPopUpEditForm));
  }

  if (props.loading) {
    return <LoadSpinner />
  }

  return (
    <div  className="container-fluid">
      {props.project_loading && <LoadSpinner />}
      <div className="col-sm-12">
        <ol className="breadcrumb float-sm-right">
          <li className="breadcrumb-item">Organization</li>
          <li className="breadcrumb-item active">Labels</li>
        </ol>
      </div>
      <div className="col-lg-12">
        <h2 className="mb-2">Labels</h2>

        <div className="mb-3">
          <Button onClick={handleShowNewForm} variant="primary" size="sm">Add Label</Button>
        </div>
        <Table className="table table-bordered table-light" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th style={{width: '20%'}} className="text-center"></th>
            </tr>
          </thead>
          <tbody>
           {  projects && projects.length > 0
              ?
                projects.map((data, index) => (
                  <tr key={index}>
                    <td>  <ProjectLabel name={data.name} color_hex={data.color_hex} /></td>
                    <td>
                      <div className="table-actions">
                        <Button onClick={() => handleShowEditForm(data.id) } className="px-2" size="sm" variant="outline-secondary">Edit</Button>
                        <Button onClick={() => handleShow(data.id) } className="px-2" size="sm" variant="danger">Delete</Button>
                      </div>
                    </td>
                  </tr>
                ))
              :
               <tr>
                  <td colSpan="2">
                    <div className="d-flex justify-content-center py-4">
                      There are no projects yet.
                    </div>
                  </td>
               </tr>
            }
          </tbody>
        </Table>
      </div>
      {popupEditForm.show &&
          <ProjectEdit
            handleCloseEditForm={handleCloseEditForm}
            project_id={popupEditForm.id}
            editProject={editProject && editProject.data}
          />
      }

      {popupNewForm.show &&
        <ProjectNew
          handleCloseNewForm={handleCloseNewForm}
        />
      }

      {popup.show &&
        <Popup
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.project.loading,
    project_loading: state.project.project_loading,
    projects: state.project.projects,
    editProject: state.project.editProject
  };
};

export default connect(mapStateToProps, { projectActions })(ProjectList);
