const organizationValidation = (values) => {
  const errors = {};

  if(!values.name){
    errors.name = 'required'
  }

  return errors;
};

export default organizationValidation;
