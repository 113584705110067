import { userConstants } from '../constants';

const initialState = { loading: false, error: '' };

export function chat(state = initialState, action) {
  switch (action.type) {
  
    case userConstants.CREATE_CONVERSATION: {
      return {
        ...state,
        currentConversation: action.payload.data.conversation,
        getMessages: action.payload.data.data,
        messageCount: action.payload.data.message_count,
      };
    }

    case userConstants.MESSAGE_COUNT: {
      return {
        ...state,
        messageCount: action.payload
      }
    }

    case userConstants.SENDER_MESSAGE: {
      return {
        ...state,
        senderMessages: action.payload.data
      }
    }

    case userConstants.CURRENT_MESSAGE: {
      let currentMessage = action.payload.data
      let messages = []
      if(state.getMessages) {
        console.log("getMessages")
        messages = [ ...state.getMessages,  currentMessage]
      }
      return {
        ...state,
        getMessages: messages
      }
    }
  
  default:
    return state
  }
}
