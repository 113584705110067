import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FieldArray, reduxForm, change } from 'redux-form';
import { renderUsers, renderWorkspace, renderRoles } from '../MultiUserPage/shared';
import { Button } from 'react-bootstrap';
import { fieldArrayValidation } from '../../../shared/form-validations';
import { workspace_userActions, multi_userActions } from '../../../actions';
import { Link, useParams } from 'react-router-dom';

function Form(props) {
  const {
          handleSubmit,
          setInitialWorkspace,
          setCommonData,
          userId,
          user_id,
          initialize,
          editUser,
          loading,
          editForm,
          current_organization,
          remaining_users,
          ignore_limit
        } = props;

  const { organization_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if(editForm === undefined) {
      dispatch(change("userform", "invited_users", ['']));
      dispatch(change("userform", "invited_workspace_users", ['']));
      dispatch(change("userform", "user_roles", ['']));
    }
  }, []);

  if (loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <main>
      <form  onSubmit={handleSubmit}>
        <div>
          <h6 className="py-1"> {userId ? "Edit User" : "Add Multiple Users" }</h6>
          {
            current_organization && !current_organization.personal && !ignore_limit &&
            ` Seats Available: ${remaining_users || 0} of ${current_organization.allowed_user_count}`
          }

          <FieldArray
            name='invited_users'
            component={renderUsers}
            roles_details={setCommonData && setCommonData.organization_roles}
            change={props.change}
            editForm={editForm}
          />
        </div>
        {
          current_organization?.user_id !== user_id &&
          <>
            <div className="py-3">
              <h6 className="py-1">Organization-wide Access</h6>
              <FieldArray
                name="user_roles"
                component={renderRoles}
                roles_details={setCommonData && setCommonData.organization_roles}
                change={props.change}
                editForm={editForm}
              />
            </div>
            <div>
              <h6 className="py-1">Add Workspace</h6>
              <p className="text-muted font-15">Adding this user to the client will not grant this user access to all content groups for this client, but will add the user to the list of users when  new project list is created under this client </p>
              <FieldArray
                name='invited_workspace_users'
                component={renderWorkspace}
                workspace={setInitialWorkspace && setInitialWorkspace.workspace}
                roles={setCommonData && setCommonData.workspace_roles}
                editForm={editForm}
                change={props.change}
              />
            </div>
          </>
        }
        <div className="d-flex align-items-center justify-content-end">
          <Link to={`/organizations/${organization_id}/admin/users`}>
            <Button variant="default" style={{marginRight: 20}}>Cancel</Button>
          </Link>
          <Button variant="primary" type="submit">Save User</Button>
        </div>
      </form>
    </main>
  );
}

Form =  reduxForm({
  form: 'userform',
  validate: fieldArrayValidation,
  enableReinitialize: true
})(Form);

const mapStateToProps = (state) => {
  const initialData = state.multiUser?.editUserList?.invited_users
  const invited_workspace_users = initialData && initialData.invited_workspace_users
  const user_roles = initialData && initialData.user_roles
  return {
    initialValues: {
      user_roles,
      invited_users: [{
        first_name: initialData && initialData.first_name,
        last_name: initialData && initialData.last_name,
        email: initialData && initialData.email}
      ],
      invited_workspace_users: invited_workspace_users ? invited_workspace_users : ['']
    },
    user_id: initialData?.id,
    current_organization: state.organization.current_organization,
    remaining_users: state.organization.remaining_users,
    ignore_limit: state.organization.ignore_limit,
    usersList: state.multiUser.allUsers
  };
};

export default connect(mapStateToProps, {workspace_userActions})(Form);
