import { removeSearchHistoryDuplicate } from './removeSearchHistoryDuplicate';

export const addSearchHistory = (array, item) => {
  const limit_history = 50;

  array.unshift(item);
  if (array.length > limit_history) {
      array.length = limit_history;
  }

  let filteredArray = array.filter((item)=>{
    return item['text'] !== '';
  });

  return removeSearchHistoryDuplicate(filteredArray)
}
