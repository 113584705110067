import React, { useEffect } from 'react';
import {connect,  useDispatch, useSelector } from 'react-redux';
import ProjectForm from "../ProjectPage/form";
import { projectActions } from '../../../actions';
import { LoadSpinner } from '../../../components';

function EditProject(props) {
  const dispatch = useDispatch();
  const project_error = useSelector(state => state.project);
  const { editProject, handleCloseEditForm, project_id } = props


  useEffect(() => {
  }, []);

  const showResults = (values) => {
    dispatch(projectActions.updateProject(values, project_id, handleCloseEditForm));
  }

  if (props.loading) {
    return <LoadSpinner />
  }

  return (
    <div className="container-fluid">
      <ProjectForm onSubmit={showResults}
        project_error={project_error && project_error}
        handleCloseForm = {handleCloseEditForm}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.project.loading
  };
};

export default connect(mapStateToProps, { projectActions })(EditProject);
