import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import { validation } from '../../../shared/form-validations';
import { textInput, selectField } from '../../../shared/form-elements'
import { reduxForm, Field, reset, change } from "redux-form";
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { UserThumbnails } from '../../../components';
import moment from 'moment';
import CloseButton from 'react-bootstrap/CloseButton';

function ContentGroupForm(props) {
  const { handleSubmit, newForm , editContentGroup, goupId, projects, content_group_error, initialValues, handleCloseForm, stages, selected_stage_id, can_access_add_change_date} = props
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 991px)' })

  useEffect(() => {
    if(newForm === "newForm") {
      dispatch(change("content_groupform", "name", ''))
      dispatch(change("content_groupform", "workspace_id", goupId))
      dispatch(change("content_groupform", "due_date", ''))
      dispatch(change("content_groupform", "project_id", ''));
      dispatch(change("content_groupform", "stage_id", selected_stage_id));
      dispatch(change("content_groupform", "start_date", ''));
    }
  }, []);

  if (props.loading) {
    return <div className="page_loading">Loading..</div>
  }

  return (
    <div className={`container-fluid container-page_container ${isMobileScreen ? 'mobile_container_page' : "window"}`} style={{ height: "100%"}}>
      <CloseButton variant="white" onClick={handleCloseForm} title="Close" />

      <div className={`container-page_modal-holder ${isMobileScreen ? 'mobile_content_popup' : 'window_content_popup'}`}>
        <form onSubmit={handleSubmit}>
          <Row style={{ height: "100%", alignItems: "flex-start" }}>
            <Col lg={isMobileScreen ? 12 : 8} className="custom_col_8" style={{ display: 'block' }}>
              <div className="current_content_group-container" style={{ flex: "1 0" }}>
                <div className="current_content_group-container-grid">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row justify-content-start">
                      <Field name="name" type="text" component={textInput} placeholder="Add title" input_class_name="inline_edit_input" autoFocus />

                      <Field name="stage_id" input_class_name="current_content_group-container__stage-select" type="text" component={selectField} options={stages} />
                    </div>

                    <div>
                      <div className="ml-auto">
                        <div className="chatbtn-group d-flex align-items-center justify-content-end submitbtn-group">
                        </div>
                      </div>
                    </div>
                  </div>

                  <Row>
                    {can_access_add_change_date && (
                      <>
                      <Col sm={12} md={3}>
                        <label>Start Date</label>
                         <div className="detail">
                          <div className="d-flex align-items-center">
                            <Field name="start_date" type="datetime-local" component={textInput} input_class_name="inline_datepicker" />
                          </div>
                        </div>
                      </Col>

                      <Col sm={12} md={3}>
                        <label>Due Date</label>
                         <div className="detail">
                          <div className="d-flex align-items-center">
                            <Field name="due_date" type="datetime-local" component={textInput} input_class_name="inline_datepicker" />
                          </div>
                        </div>
                      </Col>
                      </>
                    )}

                    { projects && projects.length > 0 ?
                        <Col sm={12} md={2}>
                          <label>
                            Label
                          </label>
                          <div className="detail">
                            <div className="d-flex align-items-center select_inline_div">
                              <div className="full_width">
                                <Field name="project_id" type="text" component={selectField} options={projects} />
                              </div>
                            </div>
                          </div>
                        </Col>
                      : null
                    }
                    <Col>
                      <label>
                        Participants
                      </label>
                      <UserThumbnails users={[]} />
                    </Col>
                  </Row>

                  { content_group_error && content_group_error.error &&
                    <div style={{ textAlign: "center", paddingTop: "1rem", paddingBottom: "1rem" }}>

                      {content_group_error.error.map((data, index) => (
                        <div className="error_message" key={index}>{data}</div>
                      ))}
                    </div>
                  }

                  <div style={{ textAlign: "right", paddingTop: "1rem", paddingBottom: "2rem" }}>
                    <Button variant="primary" type="submit">
                      Save, and add cards
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          </form>
      </div>
    </div>
  );
}

ContentGroupForm =  reduxForm({
  form: 'content_groupform',
  validate: validation,
  enableReinitialize: true
})(ContentGroupForm);

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.content_group.loading,
    error: state.content_group.error,
    stages: state.content_group.stages,
    editContentGroup: state.content_group.editContentGroup,
    initialValues: {
      name: state.content_group.editContentGroup && state.content_group.editContentGroup.data && state.content_group.editContentGroup.data.name,
      project_id: state.content_group.editContentGroup && state.content_group.editContentGroup.data && state.content_group.editContentGroup.data.project_id,
      stage_id: state.content_group.editContentGroup && state.content_group.editContentGroup.data && state.content_group.editContentGroup.data.stage_id || ownProps && ownProps.selected_stage_id,
      start_date: state.content_group.editContentGroup && state.content_group.editContentGroup.data && moment(state.content_group.editContentGroup.data.start_date).format("YYYY-MM-DDTHH:mm:ss"),
      due_date: state.content_group.editContentGroup && state.content_group.editContentGroup.data && moment(state.content_group.editContentGroup.data.due_date).format("YYYY-MM-DDTHH:mm:ss")
    }
  };
};

export default connect(mapStateToProps,{})(ContentGroupForm);
